import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

const SmallModal = ({ title, body, show_modal, onHide }) => {
  const [internalShowModal, setInternalShowModal] = useState(false);

  useEffect(() => {
    // Actualizar el estado interno cuando la prop show_modal cambie
    setInternalShowModal(show_modal);
  }, [show_modal]);

  const handleHideModal = () => {
    setInternalShowModal(false);

    // Pasar la llamada a la función onHide si está definida
    onHide && onHide();
  };

  return (
    <>
      {/* Modal */}
      <Modal show={internalShowModal} onHide={handleHideModal} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHideModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SmallModal;
