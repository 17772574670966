import React from 'react';
import Statistics from './Statistics';
import CardsAdministradores from './CardsDashboardAdministrador';
import MyProfile from './MyProfile';

const Home = () => {
	return(
		<>
			<div className="row">
				<div className="col-xl-12 mt-4">
					<div className="">
						<div className="col-xl-12">
              <MyProfile />
            </div>
						<div className="col-xl-12">
              <Statistics />
            </div>
					</div>
				</div>
			</div>
		</>
	)
}
export default Home;
