// SearchInput.js
import React, { useState } from 'react';
import { BotonPrimary } from './BotonesCustom';

const SearchInput = ({ value, onSearchChange, placeholder }) => {

  const [searchText, setSearchText] = useState('');

  const delayDebounceFn = (searchText) => {
    return setTimeout(() => {
      onSearchChange(searchText);
    }, 900);
  };

  let timeoutId = null;

  const handleInputChange = (e) => {
    console.log(e.target.value);
    const searchText = e.target.value;
    setSearchText(searchText);
    // clearTimeout(timeoutId);
    // timeoutId = delayDebounceFn(searchText);
  };

  const onClearClick = () => {
    setSearchText('');
    value && onSearchChange('');
  };

  const handleSearchClick = () => {
    onSearchChange(searchText);
  };

  const onEnterPress = () => {
    onSearchChange(searchText);
  };

  return (
    <div className="row align-items-center">
      <div className="input-group input-group-sm position-relative">
        <input
          autoFocus
          type="text"
          autoComplete='on'
          value={searchText}
          className="form-control form-control-sm"
          placeholder={(placeholder && placeholder) || 'Buscar...'}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onEnterPress && onEnterPress();
            }
          }}
          onChange={handleInputChange}
        />
        <div className="input-group-append p-2">
          <BotonPrimary onClick={handleSearchClick} title="Buscar">
            <i className="fas fa-search" />
          </BotonPrimary>
          {searchText && (
            <BotonPrimary onClick={onClearClick} title="Limpiar">
              <i className="fas fa-times" />
            </BotonPrimary>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchInput;
