import React, { useMemo, useEffect, useState, useCallback } from 'react';
import PageTitle from "../../../layouts/PageTitle";
import CitoPlusSmallModal from '../components/small_modal';
import { useTable, useFilters, usePagination } from 'react-table';
import '../../table/FilteringTable/filtering.css';
import axiosInstance from '../../../../services/AxiosInstance';
import { getDefaultParams } from '../../../../services/DefaultParamets';
import Tables from '../components/tables';
import SearchInput from '../components/input_search';
import { Link } from 'react-router-dom';
import Collapse from 'react-bootstrap/Collapse';

import { CurrencyNormalComponent } from '../components/CurrencyComponnet';

import DatePickerRanger from '../components/DatePickerRanger';

import { formatDate } from '../utils';
import swal from 'sweetalert';
import { Button, ButtonGroup } from 'react-bootstrap';
import { BotonDangerText, BotonPrimaryText } from '../components/BotonesCustom';
import SearchTextWithDateRange from '../components/SearchWithDateRange';


export const VigilantesCajas = () => {

  const [smallModalTitle, setSmallModalTitle] = useState('');
  const [smallModalBody, setSmallModalBody] = useState('');
  const [smallShowModal, setSmallShowModal] = useState(false);

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');

  const [nextUrl, setNextUrl] = useState([]);
  const [previousUrl, setPreviousUrl] = useState([]);

  // Totales del recuado
  const [sumaTotalRecaudo, setSumaTotalRecaudo] = useState(0);
  const [sumaTotalGraciaRecaudo, setSumaTotalGraciaRecaudo] = useState(0);

  // Opern Collapse
  const [openFilter, setOpenFilter] = useState(false);

  // Dates and filters del recaudo
  const [dateRangeStart, setDateRangeStart] = useState([null, null]);
  const [startDateStart, endDateStart] = dateRangeStart;

  const [dateRangeEnd, setDateRangeEnd] = useState([null, null]);
  const [startDateEnd, endDateEnd] = dateRangeEnd;

  // Modal Facturacion
  const [showModalFacturacion, setShowModalFacturacion] = useState(false);
  const [showModalFacturacionSlug, setShowModalFacturacionSlug] = useState(null);

  const URL = '/api/v3/parking/cajas/';
  const URL_EXPORT = '/api/v3/parking/recaudo/export/';

  const [defaultParams, setDefaultParams] = useState(getDefaultParams);
  const [filterApplied, setFilterApplied] = useState(false);
  const [filterParams, setFilterParams] = useState({
    search: '',
    h_ingreso_after: '',
    h_ingreso_before: '',
    h_salida_after: '',
    h_salida_before: '',
  });

  const columns = useMemo(() => [
    {
      id: 'open_at',
      Header: 'Abierta ',
      disableFilters: true,
      disableSortable: true,
      accessor: 'open_at',
    },
    {
      id: 'close_at',
      Header: 'Cerrada ',
      disableFilters: true,
      disableSortable: true,
      accessor: 'close_at',
    },
    {
      id: 'vigilante_nombre',
      Header: 'Vigilante ',
      disableFilters: true,
      disableSortable: true,
      accessor: 'vigilante_nombre',
    },
    {
      id: "total",
      Header: 'Total ',
      disableFilters: true,
      disableSortable: true,
      accessor: "total",
      Cell: ({ value }) => (value ? <CurrencyNormalComponent amount={value} /> : 0),
    },
  ], []);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedfetchUrl = useCallback(
    debounce((pageIndex, search) => {
      fetchUrl(pageIndex, search);
    }, 500), // El valor 500 es el tiempo de espera en milisegundos
    []
  );

  useEffect(() => {
    fetchUrl(1);
  }, []);

  useEffect(() => {
    if (filterApplied) {
      fetchUrl(1);
      setFilterApplied(false);
    }
  }, [filterParams, filterApplied]);


  const fetchUrl = async (pageIndex = 1, filterParams = {}, ordering = []) => {
    defaultParams.page = pageIndex;

    setLoading(true);
    console.log(filterParams);
    try {
      if (pageIndex < 1 || pageIndex > totalPages) {
        setSmallModalTitle('Error de Página');
        setSmallModalBody('Error de Página');
        setSmallShowModal(true);
        return;
      }
      const response = await axiosInstance.get(
        URL,
        {
          params: {
            ...defaultParams,
            ...filterParams,
            page: pageIndex,
            ordering: ordering
          }
        }
      );
      setNextUrl(response.data.next);
      setPreviousUrl(response.data.previous);
      setTotalPages(response.data.total_pages);
      setTotalRecords(response.data.count);
      setCurrentPage(response.data.current_page);
      setData(response.data.results);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 404) {
        setSmallModalTitle('Título del Modal');
        setSmallModalBody('Contenido del Modal');
        setSmallShowModal(true);
        console.log("Página no encontrada");
      } else {
        console.error('Error fetching data:', error);
      };
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (filterParams) => {
    fetchUrl(1, filterParams);
  };

  const handleEnterPress = () => {
    // Lógica para manejar la tecla Enter, si es necesario
    console.log('Presionó Enter');
  };

  const handleClearClick = () => {
    handleSearchChange('');
  };

  const handleSortingChange = (column) => {
    const { id } = column;

    const newOrder = sortField === id ? (order === 'asc' ? 'desc' : 'asc') : 'asc';

    setSortField(id);
    setOrder(newOrder);
    setLoading(true);
    fetchUrl(1, searchValue, `${newOrder === 'desc' ? '-' : ''}${id}`);
  };

  const handleOpenModal = () => {
    setSmallShowModal(true);
  };

  const handleCloseModal = () => {
    setSmallShowModal(false);
  };

  const handleExport = () => {
    swal("Exportar", "Exportando...", "info");
    try {
      const response = axiosInstance.get(URL_EXPORT, {
        params: {
          ...filterParams
        }
      });
      console.log(response);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
    },
    useFilters,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page
  } = tableInstance;

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h3 className="mb-0 me-auto">Cierre de turno</h3>
      </div>

      <div className="card">
        <div className="card-header">
          <div className='card-tools'>
            <SearchTextWithDateRange
              onSearchChange={handleSearchChange}
              onEnterPress={handleEnterPress}
              onClearClick={handleClearClick}
              showDatePicker={true}
              placeholder={'Nombre'}
              placeholderText={'Fechas de apertura y cierre'}
            />
          </div>
        </div>
        <div className="card-body">
          {loading ? (
            <p>Cargando...</p>
          ) : data.length === 0 ? (
            <p>No hay datos disponibles.</p>
          ) : (
            <Tables
              columns={columns}
              data={data}
              loading={loading}
              totalPages={totalPages}
              totalRecords={totalRecords}
              currentPage={currentPage}
              fetchUrl={fetchUrl}
              handleSortingChange={handleSortingChange}
              nextUrl={nextUrl}
              previousUrl={previousUrl}
              sortField={sortField}
              order={order}
              page={page}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default VigilantesCajas;
