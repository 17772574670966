import React, { useEffect, useState } from "react";
import axiosInstance from "../../../../services/AxiosInstance";
import Loading from "../components/loading";
import { useParams } from "react-router-dom";
import Swal from 'sweetalert2'
import { Link } from "react-router-dom";
import { LinkPrimary } from "../components/CitoPlusLink";
import FormAgregarRercursosZonaSocial from './FormAgregarRercursosZonaSocial';


export const ZonaSocialDetalle = () => {

  const params = useParams();

  const URL = `/api/v3/zonas-sociales/zonas/${params.uuid}/`

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const fetchZonaSocial = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(URL);
      const data = await response.data;
      setData(data);
      return data;
    } catch (error) {
      if (error.response) {
        const errorMessages = Object.values(error.response.data).flat();
        const errorMessage = errorMessages.join("\n");
        Swal.fire('Error', errorMessage, "error");
      } else {
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchZonaSocial();
  }, []);

  // Agregar Editar Recursos
  const [showModalAddRecurso, setShowModalAddRecursos] = useState(false);
  const [selectedZonaSocialUUID, setSelectedZonaSocialUUID] = useState(null);
  const [selectedRecursoUUID, setSelectedRecursoUUID] = useState(null);
  const [editRecurso, setEditRecurso] = useState(false);

  const handleModalAgregarRecursos = (zonaSocialUUID, recursoUUID = null, editMode = false) => {
    setEditRecurso(editMode);
    setSelectedZonaSocialUUID(zonaSocialUUID);
    setSelectedRecursoUUID(recursoUUID);
    setShowModalAddRecursos(true);
  }

  // FIN Agregar Recursos

  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h3 className="mb-0 me-auto">Zona Social ({data.nombre})</h3>
      </div>
      <div className="row">
        <div className="col-xl-3 col-xxl-4">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h4 className="fs-20 mb-0">Resumen</h4>
                </div>
                <div className="card-body pt-4">
                  <div className="mb-3 d-flex">
                    <h5 className="mb-1 fs-14 custom-label">Nombre:</h5>
                    <span>{data.nombre}</span>
                  </div>
                  <div className="mb-3 d-flex">
                    <h5 className="mb-1 fs-14 custom-label">Responsable:</h5>
                    <span>{data.responsable}</span>
                  </div>
                  <div className="mb-3 d-flex">
                    <h5 className="mb-1 fs-14 custom-label">Creado:</h5>
                    <span>{data.created_at}</span>
                  </div>
                  <div className="mb-3 d-flex">
                    <h5 className="mb-1 fs-14 custom-label">Acutalizado:</h5>
                    <span>{data.updated_at}</span>
                  </div>
                  <div className="mb-3 d-flex">
                    <h5 className="mb-1 fs-14 custom-label">Horario:</h5>
                    <span>{data.horario_inicio} - {data.horario_fin}</span>
                  </div>
                  <div className="mb-3 d-flex">
                    <h5 className="mb-1 fs-14 custom-label">Cantidad:</h5>
                    <span>{data.capacidad_min} - {data.capacidad_max}</span>
                  </div>
                  <div className="mb-3 d-flex">
                    <h5 className="mb-1 fs-14 custom-label">Activo:</h5>
                    <span>
                      {(data.activo) ? "Si" : "No"}
                    </span>
                  </div>
                  <div className="mb-3 d-flex">
                    <h5 className="mb-1 fs-14 custom-label">Reservable:</h5>
                    <span>
                      {(data.reservable) ? "Si" : "No"}
                    </span>
                  </div>
                </div>
                <div className="card-footer border-0 pt-0 ">
                  <div className="d-flex justify-content-between flex-wrap">
                    <LinkPrimary
                      to={`/zona-social/editar/${data.uuid}`}
                    >
                      <i className="fas fa-pencil-alt me-2"></i>
                      Editar
                    </LinkPrimary>
                    <LinkPrimary
                      to={"#"}
                      onClick={() => {
                        handleModalAgregarRecursos(data.uuid);
                      }}
                    >
                      <i className="fas fa-plus me-2"></i>
                      Agregar Recurso
                    </LinkPrimary>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-xxl-8">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header d-block">
                  <h4 className="fs-20 d-block">
                    {data.nombre}
                  </h4>
                </div>
                <div className="card-body pb-0">
                  <h4 className="fs-20 mb-3">Descripcion</h4>
                  <div className="ms-2">
                    {data.descripcion}
                  </div>
                  <hr />
                  <h4 className="fs-20 mb-3">Recursos Detalles ({data.zona_recursos.length})</h4>
                  {data.zona_recursos.map((item, index) => (
                    <div className="row mb-3 border-bottom">
                      <div className="col-xl-6">
                        <div className="ms-2">
                          <p className="font-w500 mb-3"><span className="custom-label">Nombre :</span><span className="font-w400"> {item.nombre}</span></p>
                          <p className="font-w500 mb-3"><span className="custom-label">Creado:</span><span className="font-w400"> {item.created_at}</span></p>
                          <p className="font-w500 mb-3"><span className="custom-label">Actualizado:</span><span className="font-w400"> {item.updated_at}</span></p>
                          <p className="font-w500 mb-3"><span className="custom-label">Cantidad:</span><span className="font-w400"> {item.cantidad}</span></p>
                          <p className="font-w500 mb-3"><span className="custom-label">Activo:</span><span className="font-w400"> {(item.activo) ? "Si" : "No"}</span></p>
                          <p className="font-w500 mb-3"><span className="custom-label">En mantenimiento:</span><span className="font-w400"> {(item.en_mantenimiento) ? "Si" : "No"}</span></p>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="ms-2">
                          <p className="font-w500 mb-3"><span className="custom-label">Nombre :</span><span className="font-w400"> {item.nombre}</span></p>
                          <p className="font-w500 mb-3"><span className="custom-label">Descripcion:</span><span className="font-w400"> {item.descripcion}</span></p>
                          <p className="font-w500 mb-3">
                            <LinkPrimary
                              to={"#"}
                              onClick={() => {
                                handleModalAgregarRecursos(null, item.uuid, true);
                              }}
                              onClose={() => {
                                setEditRecurso(false);
                                setSelectedZonaSocialUUID(null);
                                setSelectedRecursoUUID(null);
                              }}
                            >
                              <i className="fas fa-pencil-alt me-2"></i>
                              Editar
                            </LinkPrimary>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="d-flex justify-content-between py-4 border-bottom border-top flex-wrap">
                    <span>Publicado el <strong>{data.created_at}</strong></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >

      {/* MODAL AGREGAR RECURSO ZONA SOCIAL */}
      <FormAgregarRercursosZonaSocial
        showModal={showModalAddRecurso}
        onClose={() => {
          setShowModalAddRecursos(false);
          setSelectedZonaSocialUUID(null);
          setEditRecurso(false);
          setSelectedRecursoUUID(null);
        }}
        updateList={fetchZonaSocial}
        selectedZonaSocialUUID={selectedZonaSocialUUID}
        selectedRecursoUUID={selectedRecursoUUID}
        editMode={editRecurso}
      />
      {/* */}
    </>
  );
}

export default ZonaSocialDetalle
