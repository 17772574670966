import React from "react";
import Select from "react-select";

const CustomClearText = () => "borrar todo";

const ClearIndicator = (props) => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};

const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: "pointer",
  color: state.isFocused ? "blue" : "black",
});



export default function CustomSelect({
  options,
  onChange,
  defaultsValues,
  ...props
}) {
  const validOptions = Array.isArray(options) ? options : [];

  // Mapea las opciones para incluir explícitamente las propiedades value y label
  const mappedOptions = validOptions.map((option) => ({
    value: option.slug ? option.slug : option.uuid,
    label: option.nombre,
  }));

  // Buscar el valor predeterminado en el arreglo de opciones
  let defaultValue = null;
  defaultValue = mappedOptions.find(
    (option) => option.value === defaultsValues
  );

  // Si defaultValue no se encuentra, buscar comparacion con el nombre del mappedOptions
  if (defaultValue === null || defaultValue === undefined) {
    defaultValue = mappedOptions.find(
      (option) => option.label === defaultsValues
    );
  }

  return (
    <Select
      id={props.id ? props.id : ""}
      components={{ ClearIndicator }}
      styles={{ clearIndicator: ClearIndicatorStyles }}
      defaultValue={defaultValue}
      value={defaultValue}
      onChange={onChange}
      options={mappedOptions}
      isMulti={props.isMulti}
      closeMenuOnSelect={!props.isMulti}
      placeholder={props.placeholder ? props.placeholder : "Seleccione opciones..."}
      {...props }
    />
  );
}

export function CustomSelectParqueaderos({
  options,
  onChange,
  defaultsValues,
  ...props
}) {
  const validOptions = Array.isArray(options) ? options : [];

  // Mapea las opciones para incluir explícitamente las propiedades value, label, e isDisabled
  const mappedOptions = validOptions.map((option) => ({
    value: option.slug ? option.slug : option.uuid,
    label: option.nombre,
    isDisabled: option.no_disponible,
  }));

  // Buscar el valor predeterminado en el arreglo de opciones
  let defaultValue = null;
  defaultValue = mappedOptions.find(
    (option) => option.value === defaultsValues
  );

  // Si defaultValue no se encuentra, buscar comparación con el nombre del mappedOptions
  if (defaultValue === null || defaultValue === undefined) {
    defaultValue = mappedOptions.find(
      (option) => option.label === defaultsValues
    );
  }

  return (
    <Select
      id={props.id ? props.id : ""}
      components={{ ClearIndicator }}
      styles={{ clearIndicator: ClearIndicatorStyles }}
      defaultValue={defaultValue}
      value={defaultValue}
      onChange={onChange}
      options={mappedOptions}
      isMulti={props.isMulti}
      closeMenuOnSelect={!props.isMulti}
      placeholder={props.placeholder ? props.placeholder : "Seleccione opciones..."}
      {...props}
    />
  );
}
