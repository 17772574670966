import React, { useState } from 'react';
import axiosInstance from '../../../../services/AxiosInstance';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';

const AutorizacionesDropdownEstados = ({ estadoInicial, indice, url, className }) => {
  const [estado, setEstado] = useState(estadoInicial);
  const [loading, setLoading] = useState(false);

  const handleDropdownChange = async (id_esatdo, nuevoEstado) => {
    setLoading(true);
    try {
      // Realizar una solicitud PATCH al endpoint correspondiente para actualizar el estado
      await axiosInstance.patch(url, {
        estado: id_esatdo,
      });

      // Si la solicitud PATCH es exitosa, actualizar el estado del Dropdown en la interfaz de usuario
      setEstado(nuevoEstado);
    } catch (error) {
      console.error('Error al actualizar el estado del elemento:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DropdownButton as={ButtonGroup} title={estado} id='dropdown-basic' variant='citoplus-info' size="xs">
        <h5 className="dropdown-header" style={{ fontWeight: 'bold' }}>Cambio de Estado</h5>
        <Dropdown.Item onClick={() => handleDropdownChange(0, 'APROBADO')}>APROBADO</Dropdown.Item>
        <Dropdown.Item onClick={() => handleDropdownChange(1, 'RECHAZADO')}>RECHAZADO</Dropdown.Item>
        <Dropdown.Item onClick={() => handleDropdownChange(2, 'EN PROCESO')}>EN PROCESO</Dropdown.Item>
        <Dropdown.Item onClick={() => handleDropdownChange(5, 'DESHABILITADO')}>DESHABILITADO</Dropdown.Item>
      </DropdownButton>
    </>
  );
};

export default AutorizacionesDropdownEstados;
