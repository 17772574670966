import React from 'react';
import { Popover, OverlayTrigger, Tooltip } from 'react-bootstrap';

const TruncateText = ({ text, maxLength, title, className = "" }) => {
  // Verificar si el texto es nulo o vacío
  if (!text || text.trim() === '') {
    return null; // Puedes retornar null o algún otro valor predeterminado
  }

  // Truncar el texto si supera la longitud máxima
  const truncatedText = text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

  // Crear el popover con el texto completo
  const popover = (
    <Tooltip className='toltip-popover'
      id={`popover-positioned-top`}
    >
      <h3 className='popover-header'>{title}</h3>
      <strong>
        <br />
        {text}
      </strong>
    </Tooltip>
  );

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="top"
      overlay={popover}
      delay={{ show: 250, hide: 400 }}
      responsive={true}
    >
      <span className={className}>{truncatedText}</span>
    </OverlayTrigger>
  );
};

export default TruncateText;
