export const MenuList = [
  // Inicio
  {
    title: 'Inicio',
    iconStyle: <i className="flaticon-025-dashboard"></i>,
    to: 'dashboard',
  },
  {
    title: 'Citófonos',
    classChange: 'mm-collapse',
    iconStyle: <i className="fas fa-phone"></i>,

    content: [
      {
        title: 'Inmuebles',
        to: 'inmuebles',
      },
      {
        title: 'Grupos',
        to: 'grupo_copropiedad',
      },
      {
        title: 'Grabacion de llamadas',
        to: 'grabacion_llamadas',
      }
    ]
  },
  {
    title: 'Mensajería',
    classChange: 'mm-collapse',
    iconStyle: <i className="fas fa-inbox"></i>,
    content: [
      {
        title: 'Email',
        to: 'envio_masivo_email'
      },
      {
        title: 'SMS',
        to: 'envio_masivo_sms'
      },
      {
        title: 'Cartelera',
        to: 'envio_masivo_cartelera',
      }
    ]
  },
  {
    title: 'Gestión ADM',
    classChange: 'mm-collapse',
    iconStyle: <i className="fas fa-sliders"></i>,
    content: [
      {
        title: 'PQR',
        to: 'pqr'
      },
      {
        title: 'Autorizaciones',
        to: 'autorizaciones'
      },
      {
        title: 'Documentos',
        to: 'documentos',
      }
    ]
  },
  {
    title: 'Recaudo',
    classChange: 'mm-collapse',
    iconStyle: <i className="fas fa-receipt"></i>,
    content: [
      {
        title: 'Recaudo',
        to: 'facturacion-vehicular'
      },
      {
        title: 'Cierre de turno',
        to: 'vigilantes-cajas'
      },
      /*{
        title: 'Reservas',
        // to: 'zonas-sociales',
        hasMenu: true,
        content: [
          {
            title: 'Zonas Sociales',
            to: 'zonas-sociales',
          },
          {
            title: 'Recursos',
            to: 'zonas-sociales/recursos/',
          },
        ],
      }*/
    ]
  },
  {
    title: 'Parqueaderos',
    classChange: 'mm-collapse',
    iconStyle: <i className="fas fa-square-parking"></i>,
    content: [
      {
        title: 'Parqueaderos',
        to: 'parqueaderos'
      },
      {
        title: 'Vehiculos',
        to: 'vehiculos'
      }
    ]
  },
  {
    title: 'Seguridad',
    classChange: 'mm-collapse',
    iconStyle: <i className="fas fa-shield"></i>,
    content: [
      {
        title: 'Vigilantes',
        to: 'Vigilantes'
      },
      {
        title: 'Minutas',
        to: 'minutas'
      },
      {
        title: 'Correspondencia',
        to: 'correspondencia',
      },
      {
        title: 'Registros',
        to: 'registros_visitantes'
      },
    ]
  },
  {
    title: 'Configuración',
    classChange: 'mm-collapse',
    iconStyle: <i className="fas fa-gear"></i>,
    content: [
      {
        title: 'Perfil',
        to: 'editar_perfil'
      },
      {
        title: 'Cambiar Contraseña',
        to: 'cambio_clave'
      }
    ]
  },
  /*{
    title: 'Soporte',
    classChange: 'mm-collapse',
    iconStyle: <i className="fas fa-circle-question"></i>,
    content: [
      {
        title: 'Tutoriales',
        to: 'tutoriales'
      }
    ]
  },*/
]
