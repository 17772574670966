export const isAuthenticated = (state) => {
    if (state.auth.auth.access) return true;
    return false;
};

export const getTipoUsuario = (state) => {
    return state.auth.auth.user.tipo
}

export const getAuth = (state) => {
    return state.auth.auth
}

export const getUserId = (state) => {
  return state.auth.auth.user.pk
}
