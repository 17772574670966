import { notifyError } from "../components/CitoPlusToastr";

export const handleErrors = (error, setError = null) => {
  const parseErrors = (data) => {
    if (data.type === 'validation_error' && Array.isArray(data.errors)) {
      data.errors.forEach(errorItem => {
        const { code, detail, attr } = errorItem;
        if (code === 'unique') {
          notifyError(`Ya existe un ${attr} con este ${attr}.`);
          if (setError && attr && detail) {
            setError(attr, { type: 'unique', message: `Ya existe un ${attr} con este ${attr}.` });
          }
        } else {
          notifyError(`${detail}`);
          if (setError && attr && detail) {
            setError(attr, { type: 'validation', message: detail });
          }
        }
      });
    } else if (data.type === 'client_error' && Array.isArray(data.errors)) {
      data.errors.forEach(errorItem => {
        const { code, detail, attr } = errorItem;
        notifyError(`${detail}`);
        if (setError && attr && detail) {
          setError(attr, { type: 'not_found', message: detail });
        }
      });
    } else if (error.response.status === 404) {
      notifyError(`No se encuentra el recurso solicitado.`);
    } else if (error.response) {
      notifyError("Error de validación desconocido");
    } else {
      notifyError(`Se produjo un error al procesar la solicitud. ${error}`);
    }
  };

  if (error.response && error.response.data) {
    const responseData = error.response.data;
    parseErrors(responseData);
  } else {
    notifyError(`Se produjo un error al procesar la solicitud. ${error}`);
  }
};
