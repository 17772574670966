import React, { useEffect, useState } from "react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../../../services/AxiosInstance";
import CustomSelect from "../components/Select";
import { Button, Modal } from "react-bootstrap";
import Loading from "../components/loading";
import { FieldBasic, SelectVivienda } from "../components/FieldBasic";
import { notifyError, notifySuccess } from "../components/CitoPlusToastr";
import DatePicker from 'react-datepicker';
import format from 'date-fns/format'
import { useParams } from "react-router-dom";
import Swal from 'sweetalert2'


const formVehiculoSchema = Yup.object().shape({
  parqueadero: Yup.string()
    .required("Por favor, selecciona un parqueadero.")
    .nullable(true),
  vivienda: Yup.string()
    .required("Por favor, selecciona una vivienda.")
    .nullable(true),
  tipo_vehiculo: Yup.string()
    .required("Por favor, selecciona un tipo de vehiculo.")
    .nullable(true),
  placa: Yup.string()
    .required("Por favor, ingresa una placa.")
    .nullable(true),
})

const ModalVehiculoForm = (props) => {

  const URL = '/api/v3/parking/vehiculos/';
  // URL DROPDOWN parqueaderos
  const URL_PARQUEADEROS = '/api/v3/parking/parqueaderos/dropdown/';

  const initialValuesEmpty = {
    parqueadero: null,
    tipo_vehiculo: null,
    placa: null,
    marca: null,
    color: "",
    modelo: "",
    vivienda: null,
    estado: true,
    foto: [],
  }

  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [initialValues, setInitialValues] = useState(initialValuesEmpty);
  const [uploadFotos, setUploadFotos] = useState(false);

  // Loading Parqueaderos
  const [parqueaderosOptions, setParqueaderosOptions] = useState([]);

  useEffect(() => {
    const fetchParqueaderosOptions = async () => {
      try {
        const response = await axiosInstance.get(URL_PARQUEADEROS);
        const data = await response.data;
        setParqueaderosOptions(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        //setLoading(false);
      }
    }

    fetchParqueaderosOptions();
  }, [])

  // Fin Loading Parqueaderos

  // Loading editMode
  useEffect(() => {
    const fetchRecursoEdicion = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(`${URL}${props.selectedVehiculoUUID}/`);
        const data = await response.data;
        setInitialValues(data);
        setUploadFotos(false);
      } catch (error) {
        if (error.response) {
          const errorMessages = Object.values(error.response.data).flat();
          const errorMessage = errorMessages.join("\n");
          Swal.fire('Error', errorMessage, "error");
        } else {
          Swal.fire('Error', error.message, "error");
        }
      } finally {
        setLoading(false);
      }
    }

    if (props.editMode) {
      fetchRecursoEdicion();
    }

  }, [props.editMode, props.selectedVehiculoUUID])
  // Fin Loading editMode

  const handleSubmit = async (values, { setErrors }) => {
    setLoadingBtn(true);
    try {
      let formData = {};
      let headers = {};

      if (values.foto.length > 0 ) {
        formData = new FormData();
        if ( uploadFotos ) {
          formData.append('foto', values.foto[0]);
        }
        formData.append('parqueadero', values.parqueadero);
        formData.append('tipo_vehiculo', values.tipo_vehiculo);
        formData.append('placa', values.placa);
        formData.append('marca', values.marca);
        formData.append('color', values.color);
        formData.append('modelo', values.modelo);
        formData.append('soat', values.soat || '');
        formData.append('tecnomecanica', values.tecnomecanica || '');
        formData.append('vivienda', values.vivienda);
        formData.append('estado', values.estado);

        headers = {
          'Content-Type': 'multipart/form-data'
        }
      } else {
        formData = {
          ...values,
          foto: null,
        }
        headers = {
          'Content-Type': 'application/json'
        }
      }

      console.log(uploadFotos);
      console.log(formData);

      if (props.editMode) {
        const finalUrl = `${URL}${props.selectedVehiculoUUID}/`;
        const response = await axiosInstance.put(finalUrl, formData, { headers });
        const data = await response.data;
        props.updateList();
        props.onClose();
        notifySuccess("Vehículo editado");
      } else {
        const response = await axiosInstance.post(URL, formData, { headers });
        const data = await response.data;
        props.updateList();
        props.onClose();
        notifySuccess("Vehículo creado");
      }
    } catch (error) {
      handleErrors(error, setErrors);
    } finally {
      setLoadingBtn(false);
    }
  }

  const handleCloseModalForm = () => {
    props.onClose();
    setInitialValues(initialValuesEmpty);
    setLoadingBtn(false);
    setLoading(false);
  }

  const handleErrors = (error, setErrors) => {
    if (error.response) {
      const errorData = error.response.data;
      if (errorData.type === 'validation_error' && Array.isArray(errorData.errors)) {
        const errorObject = errorData.errors.reduce((acc, err) => {
          acc[err.attr] = err.detail;
          return acc;
        }, {});
        setErrors(errorObject);
      } else {
        const errorMessage = Object.values(errorData).flat().join("\n");
        notifyError(`Error: ${errorMessage}`);
      }
    } else {
      notifyError(`Error: ${error.message}`);
    }
  };

  if (loading) {
    return (
      <Modal show={props.showModal}>
        <Modal.Body>
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <>
      <Modal show={props.showModal}>
        <Modal.Header closeButton onHide={handleCloseModalForm}>
          <Modal.Title>{props.editMode ? 'Editar Vehículo' : 'Registrar Vehículo'}</Modal.Title>
        </Modal.Header>
        <Formik
          eneableReinitialize
          initialValues={initialValues}
          validationSchema={formVehiculoSchema}
          onSubmit={handleSubmit}
        >

          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            isSubmitting,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <FieldBasic
                  label="Parqueadero"
                  name="parqueadero"
                  required
                  errors={errors.parqueadero}
                >
                  <CustomSelect
                    id="parqueadero"
                    name="parqueadero"
                    options={parqueaderosOptions}
                    onChange={(selectedOptions) => {
                      setFieldValue('parqueadero', selectedOptions ? selectedOptions.value : null);
                    }}
                    defaultsValues={values.parqueadero}
                    placeholder="Seleccione una parqueadero..."
                  />
                </FieldBasic>

                <FieldBasic
                  label="Tipo de vehículo"
                  name="tipo_vehiculo"
                  required
                  errors={errors.tipo_vehiculo}
                >
                  <CustomSelect
                    id="tipo_vehiculo"
                    name="tipo_vehiculo"
                    options={[
                      { slug: "AUTOMOVIL", nombre: "AUTOMOVIL" },
                      { slug: "SUV", nombre: "SUV" },
                      { slug: "CAMIONETA", nombre: "CAMIONETA" },
                      { slug: "CAMION", nombre: "CAMION" },
                      { slug: "MOTO", nombre: "MOTO" },
                      { slug: "BICICLETA", nombre: "BICICLETA" },
                    ]}
                    onChange={(selectedOptions) => {
                      setFieldValue('tipo_vehiculo', selectedOptions ? selectedOptions.value : null);
                    }}
                    defaultsValues={values.tipo_vehiculo}
                    placeholder="Seleccione un tipo de vehículo..."
                  />
                </FieldBasic>

                <FieldBasic
                  label="Placa"
                  name="placa"
                  required
                  errors={errors.placa}
                  >
                  <Field
                    type="text"
                    name="placa"
                    id="placa"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Placa"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.placa}
                  />
                </FieldBasic>

                <FieldBasic
                  label="Marca"
                  name="marca"
                  errors={errors.marca}
                >
                  <Field
                    type="text"
                    name="marca"
                    id="marca"
                    className="form-control"
                    placeholder="Marca"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.marca}
                  />
                </FieldBasic>

                <FieldBasic
                  label="Color"
                  name="color"
                  errors={errors.color}
                >
                  <Field
                    type="text"
                    name="color"
                    id="color"
                    className="form-control"
                    placeholder="Color"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.color}
                  />
                </FieldBasic>

                <FieldBasic
                  label="Modelo"
                  name="modelo"
                  errors={errors.modelo}
                >
                  <Field
                    type="text"
                    name="modelo"
                    id="modelo"
                    className="form-control"
                    placeholder="Modelo"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.modelo}
                  />
                </FieldBasic>

                <FieldBasic
                  label="Inmueble"
                  name="vivienda"
                  required
                  errors={errors.vivienda}
                >
                  <SelectVivienda
                    id="vivienda"
                    name="vivienda"
                    errors={errors.vivienda}
                    defaultsValues={values.vivienda}
                    onChange={(selectedOptions) => {
                      setFieldValue('vivienda', selectedOptions ? selectedOptions.value : null);
                    }}
                  />
                </FieldBasic>

                <FieldBasic
                  label="Activo"
                  name="estado"
                  errors={errors.estado}
                >
                  <Field
                    type="checkbox"
                    name="estado"
                    id="estado"
                    className="form-check-input"
                    placeholder="estado"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.estado}
                  />

                </FieldBasic>

                <FieldBasic
                  label="Foto"
                  name="foto"
                  errors={errors.foto}
                >
                  <input
                    type="file"
                    className="form-control"
                    name="foto"
                    multiple
                    accept=".jpg, .png"
                    onChange={(event) => {
                      setUploadFotos(true);
                      const files = event.target.files;
                      const selectedFiles = Array.from(files);
                      setFieldValue('foto', selectedFiles);
                    }}
                  />
                  {values.foto && (
                    <img src={values.foto} alt="Previsualización" style={{ marginTop: '10px', maxWidth: '100%' }} />
                  )}
                  <small className="form-text text-muted">
                    <div>Formatos aceptados: .jpg, .png</div>
                  </small>
                </FieldBasic>

              </Modal.Body>
              <Modal.Footer>

                <Button
                  type="button"
                  className="btn-citoplus-secondary"
                  onClick={handleCloseModalForm}
                >
                  Cerrar
                </Button>
                <Button
                  type="submit"
                  className="btn-citoplus-primary"
                >
                  {loadingBtn ? 'Guardando...' : 'Guardar'}
                </Button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  )

}

export default ModalVehiculoForm
