import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import {Dropdown} from 'react-bootstrap';

import VigilantesResumen from './VigilantesResumen'
import VigilanteCierreCaja from './VigilanteCierreCaja'

const VigilanteDashboard = () =>{	const [control3, setControl3] = useState('Choose Location');
const [selectblog, setSelectblog] = useState('Salary Range');
return(
  <>
    <div className="row">
      <div className="col-xl-9">
        <VigilantesResumen />
      </div>
      <div className="col-xl-3 mt-4">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h4>Cajas</h4>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <VigilanteCierreCaja />
          </div>
        </div>
      </div>
    </div>
  </>
)
}
export default VigilanteDashboard;
