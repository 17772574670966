import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Field, setIn } from "formik";
import * as Yup from "yup";
import ReactWebcam from "react-webcam";
import Loading from "../components/loading";
import { BotonDangerText, BotonPrimaryText, BotonInfoText } from "../components/BotonesCustom";
import { FieldBasic } from "../components/FieldBasic";
import CustomSelect from "../components/Select";
import axiosInstance from "../../../../services/AxiosInstance";
import { toast } from "react-toastify";

const formSchema = Yup.object().shape({
  texto: Yup.string().required("Por favor, ingresa el texto de la factura."),
});

const ModalCambiarTextoFactura = ({ showModal, onClose }) => {
  const initialValuesEmpty = {
    texto: ""
  };

  const URL = "/api/v3/parking/recaudo/texto_factura/";

  const [ initialValues, setInitialValues ] = useState(initialValuesEmpty);
  const [ loadingForm, setLoadingForm ] = useState(false);
  const [ loadingSubmit, setLoadingSubmit ] = useState(false);

  useEffect(() => {
    if (showModal) {
      fetchTextoFactura();
    }
  }, [showModal]);

  const fetchTextoFactura = async () => {
    try {
      setLoadingForm(true);
      const response = await axiosInstance.get(URL);
      setInitialValues(response.data);
    } catch (error) {
      handleErrorResponse(error);
    } finally {
      setLoadingForm(false);
    }
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const postData = {
      ...values
    }

    try {
      setLoadingSubmit(false);
      const response = await axiosInstance.post(URL, postData);
      setInitialValues(initialValuesEmpty);
      onClose();
      toast.success("✔️ El ingreso fue exitoso !", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      onClose();
      resetForm();
    } catch (error) {
      handleErrorResponse(error);
    } finally {
      setSubmitting(false);
      setLoadingSubmit(false);
    }
  };

  function handleErrorResponse(error) {
    let errorMessages = [];

    // Verificar si el error tiene una respuesta y datos
    if (error.response && error.response.data) {
      const errorData = error.response.data;

      if (errorData.type === "validation_error" && Array.isArray(errorData.errors)) {
        errorData.errors.forEach(err => {
          // Combina el atributo y el mensaje de detalle
          const fieldMessage = `${err.attr}: ${err.detail}`;
          errorMessages.push(fieldMessage);
        });
      } else if (errorData.message) {
        errorMessages.push(errorData.message);
      } else {
        errorMessages.push("An unexpected error occurred.");
      }
    } else {
      errorMessages.push(error.message || "An unexpected error occurred.");
    }

    const errorMessage = errorMessages.join("\n");

    // Mostrar el mensaje de error con react-toastify
    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <Modal show={showModal} size="lg">
      {loadingForm ? (
        <Loading />
      ) : (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            resetForm,
            setSubmitting
          }) => (
            <div role="document">
              <form onSubmit={handleSubmit}>
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Texto Factura Vehicular
                  </h5>
                </div>

                <div className="modal-body">
                  <div className="row justify-content-center">
                    <div className="col-md">
                      <div className="p-2">
                          <div className="form-group pb-2">
                          <FieldBasic
                            name="Texto Factura"
                            required
                            errors={errors.texto}
                          >
                            <Field
                              type="text"
                              name="texto"
                              id="texto"
                              autoComplete="off"
                              className="form-control"
                              placeholder="Texto factura"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.texto}
                            />
                          </FieldBasic>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  {loadingSubmit ? (
                    <div className="text-center">
                      <Loading />
                    </div>
                  ) : (
                    <>
                      <BotonDangerText
                        type="button"
                        onClick={onClose}
                      >
                        Cancelar
                      </BotonDangerText>
                      <BotonPrimaryText
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Guardar
                      </BotonPrimaryText>
                    </>
                  )}
                </div>
              </form>
            </div>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default ModalCambiarTextoFactura;
