import React, { useMemo, useEffect, useState, useCallback } from 'react';
import PageTitle from "../../../layouts/PageTitle";
import CitoPlusSmallModal from '../components/small_modal';
import { useTable, useFilters, usePagination } from 'react-table';
import '../../table/FilteringTable/filtering.css';
import axiosInstance from '../../../../services/AxiosInstance';
import { getDefaultParams } from '../../../../services/DefaultParamets';
import Tables from '../components/tables';
import SearchInput from '../components/input_search';
import SearchWithDateRange from '../components/SearchWithDateRange';
import SearchTextWithDateSingle from '../components/SearchWithDateSingle';

import { Link } from "react-router-dom";
import { Badge } from 'react-bootstrap';
import { CitoPlusLinkIconDanger, CitoPlusLinkIconPrimary, LinkPrimary } from '../components/CitoPlusLink';
import VigilantesFormModal from './VigilantesFormModal';
import Swal from 'sweetalert2';
import { notifySuccess, notifyError } from '../components/CitoPlusToastr';

import noImagen from '../../../../images/no-image.jpg';

export const Vigilantes = () => {

  const [smallModalTitle, setSmallModalTitle] = useState('');
  const [smallModalBody, setSmallModalBody] = useState('');
  const [smallShowModal, setSmallShowModal] = useState(false);

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');

  const [nextUrl, setNextUrl] = useState([]);
  const [previousUrl, setPreviousUrl] = useState([]);

  const URL = '/api/v3/vigilancia/vigilantes/';

  const [defaultParams, setDefaultParams] = useState(getDefaultParams);

  // Modal Form
  const [showModalForm, setShowModalForm] = useState(false);
  const [vigilanteUUID, setVigilanteUUID] = useState('');
  const [modalFormEdit, setModalFormEdit] = useState(false);

  const handleModalForm = (vigilanteUUID, editMode) => {
    console.log('handleModalForm');
    setShowModalForm(true);
    setVigilanteUUID(vigilanteUUID);
    setModalFormEdit(editMode);
  }

  const handleCloseModalForm = () => {
    setShowModalForm(false);
    setVigilanteUUID(null);
    setModalFormEdit(false);
  }
  // Fin Modal Form

  // Eliminar Vigilante
  const handleEliminarVigilante = async (vigilanteUUID) => {
    Swal.fire({
      title: '¿Deseas eliminar este vigilante?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .delete(`${URL}${vigilanteUUID}/`)
          .then((response) => {
            if (response.status === 204) {
              notifySuccess('Vigilante eliminado correctamente');
              fetchUrl(1);
            }
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
            if (error.response && error.response.status === 404) {
              notifyError('Página no encontrada');
            } else {
              notifyError('Error al eliminar vigilante');
            };
          });
      }
    })
  }

  const columns = useMemo(() => [
    {
      id: 'foto',
      Header: 'foto',
      accessor: 'foto',
      disableFilters: true,
      disableSortable: true,
      Header: () => (
        <div className="left-align-header">Foto</div>
      ),
      Cell: ({ value }) => (
        value === null ? (
          <img
            src={noImagen}
            alt=""
            className="me-3 rounded"
            width={75}
          />
        ) : (
          <div className='image-container me-3 rounded'>
            <a href={value} data-lightbox="image">
              <img
                src={value}
                alt=""
                className="me-3 rounded"
                width={75}
                onError={e => {
                  e.target.onerror = null;
                  e.target.src = noImagen;
                }}
              />
            </a>
          </div>
        )
      ),
    },
    {
      id: 'Info del vigilante',
      accessor: 'detalles',
      disableFilters: true,
      disableSortable: true,
      Header: () => (
        <div className="left-align-header">Detalles</div>
      ),
      accessor: row => {
        return (
          <div className="pt-4 left-align-cell">
            <div className="mb-1 d-flex">
              <h5 className="mb-1 fs-14 custom-label">Nombre:</h5>
              <p className='paragraph'>{row.user.first_name} {row.user.last_name}</p>
            </div>
            <div className="mb-1 d-flex">
              <h5 className="mb-1 fs-14 custom-label">Celular:</h5>
              <p className='paragraph'>{row.celular}</p>
            </div>
            <div className="mb-1 d-flex">
              <h5 className="mb-1 fs-14 custom-label">Identificación:</h5>
              <p className='paragraph'>{row.cc}</p>
            </div>
            <div className="mb-1 d-flex">
              <h5 className="mb-1 fs-14 custom-label">Dirección:</h5>
              <p className='paragraph'>{row.direccion}</p>
            </div>
          </div>
        );
      },
    },
    {
      id: 'Detalles',
      Header: 'Detalles',
      disableFilters: true,
      disableSortable: true,
      accessor: (row) => (
        <div className="pt-4 left-align-cell">
          <div className="mb-1 d-flex">
            <h5 className="mb-1 fs-14 custom-label">Usuario vigilante:</h5>
            <p className='paragraph'>{row.user.username}</p>
          </div>
          <div className="mb-1 d-flex">
            <h5 className="mb-1 fs-14 custom-label">Ultimo Ingreso:</h5>
            <p className='paragraph'>{row.user.last_login}</p>
          </div>
          <div className="mb-1 d-flex">
            <h5 className="mb-1 fs-14 custom-label">Supervisor:</h5>
            {row.es_supervisor ? <i className='fas fa-check'></i> : <i className='fas fa-times'></i>}
          </div>
          <div className="mb-1 d-flex">
            <h5 className="mb-1 fs-14 custom-label">Activo:</h5>
            {row.activo ? <i className='fas fa-check'></i> : <i className='fas fa-times'></i>}
          </div>
        </div>
      ),
    },
    {
      id: 'acciones',
      Header: 'Acciones',
      accessor: (row) => (
        <div className="">
          <CitoPlusLinkIconPrimary
            href="#"
            title="Editar"
            onClick={() => {
              handleModalForm(row.slug, true)
            }}
          >
            <i className="fas fa-pencil-alt"></i>
          </CitoPlusLinkIconPrimary>
          <CitoPlusLinkIconDanger
            href="#"
            title="Eliminar"
            onClick={() => handleEliminarVigilante(row.slug)}
          >
            <i className="fas fa-trash-alt"></i>
          </CitoPlusLinkIconDanger>
        </div>
      ),
      disableFilters: true,
      disableSortable: true,
    },
  ], []);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedfetchUrl = useCallback(
    debounce((pageIndex, search) => {
      fetchUrl(pageIndex, search);
    }, 500), // El valor 500 es el tiempo de espera en milisegundos
    []
  );

  useEffect(() => {
    fetchUrl(1);
  }, []);


  const fetchUrl = async (pageIndex = 1, search = {}, ordering = []) => {
    defaultParams.page = pageIndex;
    setLoading(true);
    try {
      if (pageIndex < 1 || pageIndex > totalPages) {
        setSmallModalTitle('Error de Página');
        setSmallModalBody('Error de Página');
        setSmallShowModal(true);
        return;
      }
      const response = await axiosInstance.get(
        URL,
        {
          params: {
            ...defaultParams,
            ...search,
            page: pageIndex,
            ordering: ordering
          }
        }
      );
      setNextUrl(response.data.next);
      setPreviousUrl(response.data.previous);
      setTotalPages(response.data.total_pages);
      setCurrentPage(response.data.current_page);
      setData(response.data.results);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 404) {
        setSmallModalTitle('Título del Modal');
        setSmallModalBody('Contenido del Modal');
        setSmallShowModal(true);
        console.log("Página no encontrada");
      } else {
        console.error('Error fetching data:', error);
      };
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
    setLoading(true);
    debouncedfetchUrl(1, value);
  };

  const handleEnterPress = () => {
    // Lógica para manejar la tecla Enter, si es necesario
    console.log('Presionó Enter');
  };

  const handleClearClick = () => {
    handleSearchChange('');
  };

  const handleSortingChange = (column) => {
    const { id } = column;

    const newOrder = sortField === id ? (order === 'asc' ? 'desc' : 'asc') : 'asc';

    setSortField(id);
    setOrder(newOrder);
    setLoading(true);
    fetchUrl(1, searchValue, `${newOrder === 'desc' ? '-' : ''}${id}`);
  };

  const handleOpenModal = () => {
    setSmallShowModal(true);
  };

  const handleCloseModal = () => {
    setSmallShowModal(false);
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
    },
    useFilters,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page
  } = tableInstance;

  return (
    <>

      <div className='d-flex aling-items-center mb-4 flex-wrap'>
        <h4 className="fs-20 font-w600 me-auto">Vigilantes</h4>
        <LinkPrimary
          to={'#'}
          onClick={handleModalForm}
        >
          <i className="fas fa-plus me-2"></i>
          Agregar Vigilante
        </LinkPrimary>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="card-tools">
            <SearchTextWithDateSingle
              onSearchChange={handleSearchChange}
              onEnterPress={handleEnterPress}
              onClearClick={handleClearClick}
              placeholderText={"Fecha de Ingreso..."}
            />
          </div>
        </div>
        <div className="card-body">
          {loading ? (
            <p>Cargando...</p>
          ) : data.length === 0 ? (
            <p>No hay datos disponibles.</p>
          ) : (
            <Tables
              columns={columns}
              data={data}
              loading={loading}
              totalPages={totalPages}
              currentPage={currentPage}
              fetchUrl={fetchUrl}
              handleSortingChange={handleSortingChange}
              nextUrl={nextUrl}
              previousUrl={previousUrl}
              sortField={sortField}
              order={order}
              page={page}
            />
          )}
        </div>
      </div>

      <VigilantesFormModal
        showModal={showModalForm}
        onHide={handleCloseModalForm}
        updateList={fetchUrl}
        selectedVigilanteUUID={vigilanteUUID}
        editMode={modalFormEdit}
      />



    </>
  );
};

export default Vigilantes;
