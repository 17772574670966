import React, { useState, useEffect, useContext } from "react";
import axiosInstance from "../../../services/AxiosInstance";

import { Link } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
import profile from "../../../images/profile/pic1.jpg";
import avatar from "../../../images/avatar/1.jpg";
import { Dropdown, Modal } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";


const inputDate = [
  { title: 'Salary Form', subtitle: '$' },
  { title: 'Salary To', subtitle: '$' },
  { title: 'Enter City', subtitle: '$' },
  { title: 'Enter State', subtitle: 'State' },
  { title: 'Enter Counter', subtitle: 'State' },
  { title: 'Enter Education Level', subtitle: 'Education Level' },
];

function Basic({ title, subtitle }) {
  return (
    <div className="col-xl-6  col-md-6 mb-4">
      <label className="form-label font-w600">{title}<span className="text-danger ms-1">*</span></label>
      <input type="text" className="form-control solid" placeholder={subtitle} aria-label="name" />
    </div>

  )
}

const Header = ({ title }) => {
  const { background, changeBackground } = useContext(ThemeContext);
  const handleThemeMode = () => {
    if (background.value === 'dark') {
      changeBackground({ value: "light", label: "Light" });
    } else {
      changeBackground({ value: "dark", label: "Dark" });
    }
  }

  // const [searchBut, setSearchBut] = useState(false);
  // Datos de notificaciones:
  const [totalNotificacionesNoLeidas, setTotalNotificacionesNoLeidas] = useState(0);
  const [notificacionesNoLeidas, setNotificacionesNoLeidas] = useState([]);

  const fetchNotificacionesNoLeidas = async () => {
    try {
      const response = await axiosInstance.get('/api/v3/notificaciones/notificaciones/unread/');
      setNotificacionesNoLeidas(response.data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  useEffect(() => {
    // Notificaciones
    // const fetchNotificationsTotalNoLeidas = async () => {
    //   try {
    //     const response = await axiosInstance.get('/api/v3/notificaciones/notificaciones/total_unread/');
    //     setTotalNotificacionesNoLeidas(response.data["total_unread"]);
    //   } catch (error) {
    //     console.error('Error fetching notifications:', error);
    //   }
    // };
    // fetchNotificationsTotalNoLeidas();
    // const intervalId = setInterval(fetchNotificationsTotalNoLeidas, 30000);
    // return () => clearInterval(intervalId);
  }, []);

  const clickNotificacionesNoLeidas = () => {
    fetchNotificacionesNoLeidas();
  }

  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;

  // const [jobModal, setJobModal] = useState(false);
  return (
    <>
      <div className="header">
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left">
              </div>
              <ul className="navbar-nav header-right main-notification">
                <li className="nav-item dropdown notification_dropdown">
                  <Link to={"#"}
                    className={`nav-link bell dz-theme-mode p-0 ${background.value === "dark" ? "active" : ""}`}
                    onClick={() => handleThemeMode()}
                  >
                    <i id="icon-light" className="fas fa-sun" ></i>
                    <i id="icon-dark" className="fas fa-moon"></i>
                  </Link>
                </li>
                <Dropdown
                  as="li"
                  className="nav-item dropdown notification_dropdown "
                  onClick={clickNotificacionesNoLeidas}
                >
                  <Dropdown.Toggle className="nav-link i-false c-pointer" variant="" as="a"
                    data-toggle="dropdown" aria-expanded="false"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <g data-name="Layer 2" transform="translate(-2 -2)">
                        <path id="Path_20" data-name="Path 20" d="M22.571,15.8V13.066a8.5,8.5,0,0,0-7.714-8.455V2.857a.857.857,0,0,0-1.714,0V4.611a8.5,8.5,0,0,0-7.714,8.455V15.8A4.293,4.293,0,0,0,2,20a2.574,2.574,0,0,0,2.571,2.571H9.8a4.286,4.286,0,0,0,8.4,0h5.23A2.574,2.574,0,0,0,26,20,4.293,4.293,0,0,0,22.571,15.8ZM7.143,13.066a6.789,6.789,0,0,1,6.78-6.78h.154a6.789,6.789,0,0,1,6.78,6.78v2.649H7.143ZM14,24.286a2.567,2.567,0,0,1-2.413-1.714h4.827A2.567,2.567,0,0,1,14,24.286Zm9.429-3.429H4.571A.858.858,0,0,1,3.714,20a2.574,2.574,0,0,1,2.571-2.571H21.714A2.574,2.574,0,0,1,24.286,20a.858.858,0,0,1-.857.857Z" />
                      </g>
                    </svg>
                    <span className="badge light text-white bg-primary rounded-circle">{totalNotificacionesNoLeidas}</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="right" className="mt-2 dropdown-menu dropdown-menu-end">
                    <PerfectScrollbar className="widget-media dlab-scroll p-3 height380">
                      <ul className="timeline">
                        {notificacionesNoLeidas.map(notification => (
                          <li key={notification.id}>
                            <div className="timeline-panel">
                              {/*<div className="media me-2">
                                <img alt="images" width={50} src={avatar} />
                              </div>*/}
                              <div className="media-body">
                                <h6 className="mb-1">{notification.verb}</h6>
                                <small className="d-block">{notification.published}</small>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                      <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
                        <div className="ps__thumb-x" tabIndex={0} style={{ left: 0, width: 0 }} />
                      </div>
                      <div className="ps__rail-y" style={{ top: 0, right: 0 }}>
                        <div className="ps__thumb-y" tabIndex={0} style={{ top: 0, height: 0 }} />
                      </div>
                    </PerfectScrollbar>
                    {/* <Link className="all-notification" to="#">
                      Ver todas las notificaciones <i className="ti-arrow-right" />
                    </Link> */}
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
