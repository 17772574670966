import axios from 'axios';
import swal from "sweetalert";
import {
  loginConfirmedAction,
  Logout,
} from '../store/actions/AuthActions';

const apiKey = process.env.REACT_APP_APIKEY;
const apiUrl = process.env.REACT_APP_API__URL;
const backendUrl = process.env.REACT_APP_BACKEND;


export function signUp(email, password) {
  //axios call
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData,
  );
}

export function login(username, password) {
  const postData = {
    username,
    password
  };
  return axios.post(
    `${backendUrl}/rest-auth/login/`,
    postData,
  );
}

export function logout() {
  let token = '';
  let refresh = '';
  const tokenDetailsString = localStorage.getItem('userDetails');
  if (tokenDetailsString) {
    const tokenDetails = JSON.parse(tokenDetailsString);
    token = tokenDetails.access;
    refresh = tokenDetails.refresh;
  }
  return axios.post(
    `${backendUrl}/rest-auth/logout/`,
    {
      refresh: refresh
    },
    {
      headers: { 'Authorization': `Bearer ${token}` }
    }
  );
}

export function forgotPassword(email) {
  const postData = email;
  return axios.post(
    `${backendUrl}/rest-auth/password/reset/`,
    postData
  )
}

export function formatError(errorResponse) {
  // Verifica si el errorResponse tiene un campo "email" con errores
  if (errorResponse.email && Array.isArray(errorResponse.email)) {
    // Muestra el primer mensaje de error para la dirección de correo electrónico
    swal("Oops", errorResponse.email[0], "error");
  } else if (errorResponse.non_field_errors && Array.isArray(errorResponse.non_field_errors)) {
    // Muestra el primer mensaje de error general
    swal("Oops", errorResponse.non_field_errors[0], "error");
  } else {
    // Maneja otros casos según sea necesario
    swal("Oops", "Ha ocurrido un error inesperado.", "error");
    // console.log("Error desconocido:", errorResponse);
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  const twelveHoursInMilliseconds = 12 * 60 * 60 * 1000;
  tokenDetails.expireDate = new Date().getTime() + twelveHoursInMilliseconds;
  const newTokenDetails = {
    'auth': {
      "access": tokenDetails.access,
      "refresh": tokenDetails.refresh,
      "user": tokenDetails.user
    }
  };
  localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
  return tokenDetails
}

export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    //dispatch(Logout(history));
    dispatch(Logout(navigate));
  }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
  const tokenDetailsString = localStorage.getItem('userDetails');
  let tokenDetails = '';
  if (!tokenDetailsString) {
    dispatch(Logout(navigate));
    return;
  }

  tokenDetails = JSON.parse(tokenDetailsString);
  let expireDate = new Date(tokenDetails.expireDate);
  let todaysDate = new Date();

  if (todaysDate > expireDate) {
    dispatch(Logout(navigate));
    return;
  }

  dispatch(loginConfirmedAction(tokenDetails));

  const timer = expireDate.getTime() - todaysDate.getTime();
  runLogoutTimer(dispatch, timer, navigate);
}
