import React, { useMemo, useEffect, useState, useCallback } from 'react';
import PageTitle from "../../../layouts/PageTitle";
import CitoPlusSmallModal from '../components/small_modal';
import { useTable, useFilters, usePagination } from 'react-table';
import '../../table/FilteringTable/filtering.css';
import axiosInstance from '../../../../services/AxiosInstance';
import { getDefaultParams } from '../../../../services/DefaultParamets';
import Tables from '../components/tables';
import SearchInput from '../components/input_search';
import TruncateText from '../components/truncateText';
import { Link } from "react-router-dom";
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';

import 'lightbox2/dist/css/lightbox.min.css';
import noImagen from '../../../../images/no-image.jpg';
import Comentarios from '../components/sidebar_comments';

import { LinkPrimary, CitoPlusLinkIconPrimary } from '../components/CitoPlusLink';

import AutorizacionesDropdownEstados from '../components/AutorizacionesDropdownEstados';


export const Autorizaciones = () => {

  const URL = '/api/v3/usuarios/autorizaciones/';
  const URL_NOTAS = '/api/v3/usuarios/autorizaciones-notas/';

  const [smallModalTitle, setSmallModalTitle] = useState('');
  const [smallModalBody, setSmallModalBody] = useState('');
  const [smallShowModal, setSmallShowModal] = useState(false);

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');

  const [nextUrl, setNextUrl] = useState([]);
  const [previousUrl, setPreviousUrl] = useState([]);


  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  const [defaultParams, setDefaultParams] = useState(getDefaultParams);

  // TABLA
  const columns = useMemo(() => [
    {
      id: 'imagen',
      Header: 'Imagen',
      accessor: 'foto',
      disableFilters: true,
      disableSortable: true,
      Header: () => (
        <div className="left-align-header">Imagen</div>
      ),
      Cell: ({ value }) => (
        value === null ? (
          <img
            src={noImagen}
            alt=""
            className="me-3 rounded"
            width={75}
          />
        ) : (
          <a href={value} data-lightbox="image">
            <img
              src={value}
              alt=""
              className="me-3 rounded"
              width={75}
              onError={e => {
                e.target.onerror = null;
                e.target.src = noImagen;
              }}
            />
          </a>
        )
      ),
    },
    {
      id: 'detalles',
      accessor: 'detalles',
      disableFilters: true,
      disableSortable: true,
      Header: () => (
        <div className="left-align-header">Detalles</div>
      ),
      accessor: row => {
        return (
          <div className="media-body left-align-cell">
            <h5 className="m-b-5">{row.asunto}</h5>
            <TruncateText text={row.nota} maxLength={100} className='mb-0'/>
            {row.vivienda ? (
              <p>Vivienda: {row.vivienda}</p>
            ): null}
          </div>
        );
      },
    },
    {
      id: 'fechas',
      accessor: 'Fechas',
      disableFilters: true,
      disableSortable: true,
      Header: () => (
        <div className="left-align-header">Fechas</div>
      ),
      accessor: row => {
        return (
          <div className="media-body left-align-cell">
            <p className="mb-0">Creado: {row.created_at}</p>
            {row.fecha_limite ? (
              <p className="mb-0">Vencimiento: {row.fecha_limite}</p>
            ) : null}
          </div>
        );
      },
    },
    {
      id: 'actions',
      Header: '',
      disableFilters: true,
      disableSortable: true,
      accessor: (row, index) => {
        return (
          <React.Fragment>
            <ButtonGroup>
              <AutorizacionesDropdownEstados
                estadoInicial={row.estado_display}
                indice={index}
                url={URL + row.slug + '/'}
              />
              <CitoPlusLinkIconPrimary
                href="#"
                onClick={() => {
                  handleComentariosClick(row.comentarios, row.slug)
                }}
              >
                <i className="fas fa-comments"></i>
                { row.count_notes > 0 &&
                  <span
                    className="badge bg-light rounded-circle position-absolute top-0 start-100 translate-middle"
                    style={{ zIndex: 1, color: 'black', fontSize: '12px' }}
                  >
                    {row.count_notes}
                  </span> }
              </CitoPlusLinkIconPrimary>
            </ButtonGroup>
          </React.Fragment>
        )
      }
    }
  ], []);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedfetchUrl = useCallback(
    debounce((pageIndex, search) => {
      fetchUrl(pageIndex, search);
    }, 500), // El valor 500 es el tiempo de espera en milisegundos
    []
  );

  useEffect(() => {
    fetchUrl(1);
  }, []);


  const fetchUrl = async (pageIndex = 1, search = '', ordering = []) => {
    defaultParams.page = pageIndex;

    if (searchValue !== '') {
      defaultParams.search = searchValue;
      search = searchValue;
    } else {
      defaultParams.search = search;
    }

    setLoading(true);
    try {
      if (pageIndex < 1 || pageIndex > totalPages) {
        setSmallModalTitle('Error de Página');
        setSmallModalBody('Error de Página');
        setSmallShowModal(true);
        return;
      }
      const response = await axiosInstance.get(
        URL,
        {
          params: {
            ...defaultParams,
            page: pageIndex,
            search: search,
            ordering: ordering
          }
        }
      );
      setNextUrl(response.data.next);
      setPreviousUrl(response.data.previous);
      setTotalPages(response.data.total_pages);
      setCurrentPage(response.data.current_page);
      setData(response.data.results);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 404) {
        setSmallModalTitle('Título del Modal');
        setSmallModalBody('Contenido del Modal');
        setSmallShowModal(true);
        console.log("Página no encontrada");
      } else {
        console.error('Error fetching data:', error);
      };
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
    setLoading(true);
    debouncedfetchUrl(1, value);
  };

  const handleEnterPress = () => {
    // Lógica para manejar la tecla Enter, si es necesario
    console.log('Presionó Enter');
  };

  const handleClearClick = () => {
    handleSearchChange('');
  };

  const handleSortingChange = (column) => {
    const { id } = column;

    const newOrder = sortField === id ? (order === 'asc' ? 'desc' : 'asc') : 'asc';

    setSortField(id);
    setOrder(newOrder);
    setLoading(true);
    fetchUrl(1, searchValue, `${newOrder === 'desc' ? '-' : ''}${id}`);
  };

  const handleOpenModal = () => {
    setSmallShowModal(true);
  };

  const handleCloseModal = () => {
    setSmallShowModal(false);
  };

  // Comentarios
  const [selectedComentarios, setSelectedComentarios] = useState([]);
  const [showSidebarComments, setShowSidebarComments] = useState(false);
  const [urlComentarios, setUrlComentarios] = useState(URL_NOTAS);
  const [urlComentariosParams, setUrlComentariosParams] = useState({});
  const [selectedPrePostData, setSelectedPrePostData] = useState({});

  const handleComentariosClick = (value, slug) => {
    setSelectedPrePostData({
      autorizacion: slug,
      nota: ''
    });

    setUrlComentariosParams({
      autorizacion__slug: slug,
      page_size: 100
    })
    setSelectedComentarios(value);
    setShowSidebarComments(true);
  }

  const handleNewComment = (e) => {
    fetchUrl(1, searchValue, '');
  }
  // FIN COMENTARIOS

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
    },
    useFilters,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page
  } = tableInstance;

  return (
    <>
      <div className='d-flex aling-items-center mb-4 flex-wrap'>
        <h4 className="fs-20 font-w600 me-auto">Autorizaciones</h4>
        <LinkPrimary
          to={'/form-agregar-autorizacion/'}
        >
          <i className="fas fa-plus me-2"></i>
          Agregar Autorización
        </LinkPrimary>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="card-tools">
            <SearchInput
              value={searchValue}
              onSearchChange={handleSearchChange}
              onEnterPress={handleEnterPress}
              onClearClick={handleClearClick}
            />
          </div>
        </div>
        <div className="card-body">
          {loading ? (
            <p>Cargando...</p>
          ) : data.length === 0 ? (
            <p>No hay datos disponibles.</p>
          ) : (
            <Tables
              columns={columns}
              data={data}
              loading={loading}
              totalPages={totalPages}
              currentPage={currentPage}
              fetchUrl={fetchUrl}
              handleSortingChange={handleSortingChange}
              nextUrl={nextUrl}
              previousUrl={previousUrl}
              sortField={sortField}
              order={order}
              page={page}
            />
          )}
        </div>
      </div>

      <div>
      <Comentarios
          showSidebarComments={showSidebarComments}
          setShowSidebarComments={setShowSidebarComments}
          // comentarios={selectedComentarios}
          urlComentarios={urlComentarios}
          urlComentariosParams={urlComentariosParams}
          urlPost={urlComentarios}
          selectedPrePostData={selectedPrePostData}
          handleNewComment={handleNewComment}
        />

        {smallShowModal && (
          <CitoPlusSmallModal
            title={smallModalTitle}
            body={smallModalBody}
            show_modal={smallShowModal}
            onHide={handleCloseModal}
          // onClose={() => setSmallShowModal(false)}
          />
        )}
      </div>

    </>
  );
};

export default Autorizaciones;
