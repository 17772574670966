import React, { useState } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { es } from 'date-fns/locale/es';

registerLocale('es', es);

export default function DatePickerRanger({ startDate, endDate, onChange, ...props }) {
  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStart(start);
    setEnd(end);
    onChange(dates);
  };

  return (
    <div>
      <DatePicker
        locale="es"
        className='form-control'
        selectsRange={true}
        startDate={start}
        endDate={end}
        dateFormat={"dd/MM/yyyy"}
        onChange={handleDateChange}
        withPortal
        showMonthDropdown
        showYearDropdown
        {...props}
      />
    </div>
  );
}
