import React, { useState } from 'react';
import { Accordion, Card, Col, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const DirectoriosView = ({ directorios }) => {
  const [expandedDirectorio, setExpandedDirectorio] = useState(null);

  const handleDirectorioClick = (slug) => {
    setExpandedDirectorio((prev) => (prev === slug ? null : slug));
  };

  return (
    <Col xl="6">
      <Card>
        <Card.Header className="d-block">
          <Card.Title>Directorios</Card.Title>
        </Card.Header>
        <Card.Body>
          <Accordion
            className="accordion accordion-header-shadow accordion-bordered accordion-danger-solid"
            defaultActiveKey="0"
          >
            {directorios.map((directorio) => (
              <Accordion.Item
                key={directorio.slug}
                eventKey={directorio.slug}
                className="accordion-item"
              >
                <Accordion.Header
                  className="accordion-header"
                  style={{ "z-index": -9999 }}
                  onClick={() => handleDirectorioClick(directorio.slug)}
                >
                  <h5 className="accordion-title">{directorio.name}</h5>
                </Accordion.Header>
                <Accordion.Collapse
                  eventKey={directorio.slug}
                  className="accordion-body accordion-shadow"
                >
                  <div>
                    {directorio.documents.map((documento) => (
                      <div key={documento.slug}>
                        <ListGroup>
                          <ListGroup.Item
                            action
                            className='flex-column align-items-start'
                          >
                            <div className='d-flex w-100 justify-content-between'>
                              <h5 className='mb-3'>{documento.titulo}</h5>
                              <small>Privado: {documento.is_private ? 'Sí' : 'No'}</small>

                            </div>
                            <p className='mb-1'>
                              Grupos: {documento.grupo.map((grupo) => grupo.nombre).join(', ')}
                            </p>
                            <p className='mb-1'>
                              <a href={documento.file} target='_blank'>Descargar</a>
                            </p>
                            <p className='mb-1'>
                              Visible en porteria: {documento.visible_porteria ? 'Sí' : 'No'}
                            </p>
                            <small>Creado: {documento.created_at}</small>
                          </ListGroup.Item>
                        </ListGroup>
                      </div>
                    ))}
                  </div>
                </Accordion.Collapse>
              </Accordion.Item>
            ))}
          </Accordion>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default DirectoriosView;
