import React, { useEffect, useState } from "react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../../../services/AxiosInstance";
import CustomSelect from "../components/Select";
import { BotonPrimaryText, BotonDangerText } from "../components/BotonesCustom";
import Loading from "../components/loading";
import Swal from "sweetalert2";

import { ButtonGroup, Modal } from "react-bootstrap";
import { notifyError, notifySuccess } from "../components/CitoPlusToastr";

const formParqueaderosSchema = Yup.object().shape({

  tipo_parqueadero: Yup.string()
    .required("Por favor, selecciona un tipo de parqueadero.")
    .nullable(true),
  no_parqueadero: Yup.string()
    .required("Por favor, ingresa un numero de parqueadero.")
    .nullable(true),
})

const ModalFormParqueadero = ({ showModal, onClose, updateList, selectedParqueaderoUUID, editMode }) => {
  const initialValuesEmpty = {
    tipo_parqueadero: null,
    no_parqueadero: "",
    entra_en_rotacion: false,
    estatus: true
  }

  const URL = '/api/v3/parking/parqueaderos/';

  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [initialValues, setInitialValues] = useState(initialValuesEmpty);

    // Modo de Edicion
    useEffect(() => {
      const fetchRecursoDetalle = async () => {
        setLoading(true);
        try {
          const response = await axiosInstance.get(`${URL}${selectedParqueaderoUUID}/`);
          const data = await response.data;
          setInitialValues({
            tipo_parqueadero: data.tipo_parqueadero,
            no_parqueadero: data.no_parqueadero,
            entra_en_rotacion: data.entra_en_rotacion
          });
        } catch (error) {
          if (error.response) {
            const errorMessages = Object.values(error.response.data).flat();
            const errorMessage = errorMessages.join("\n");
            Swal.fire('Error', errorMessage, "error");
          } else {
            Swal.fire('Error', error.message, "error");
          }
        } finally {
          setLoading(false);
        }
      }

      if (editMode) {
        fetchRecursoDetalle();
      } else {
        setInitialValues(initialValuesEmpty);
      }
    }, [editMode, selectedParqueaderoUUID])
    // FIN Modo de Edicion

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const postData = {
      ...values
    };
    try {
      let response = null;
      if (editMode) {
        const finalUrl = `${URL}${selectedParqueaderoUUID}/`;
        response = await axiosInstance.put(finalUrl, postData);
      } else {
        response = await axiosInstance.post(URL, postData);
      }
      const data = await response.data;
      resetForm(initialValuesEmpty);
      setInitialValues(initialValuesEmpty);
      onClose();
      updateList();
      notifySuccess("Parqueadero agregado");
    } catch (error) {
      if (error.response) {
        const errorData = error.response.data;

        // Verificar si es un error de validación
        if (errorData.type === 'validation_error' && Array.isArray(errorData.errors)) {
          const errorMessages = errorData.errors.map(err => err.detail).join("\n");
          notifyError(`Error: ${errorMessages}`);
        } else {
          // Manejar otros tipos de errores de la respuesta
          notifyError(`Error: ${error.response.statusText}`);
        }
      } else {
        // Manejar errores que no provienen de la respuesta del servidor
        notifyError(`Error: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Modal show={showModal}>
        <Modal.Body>
          <Loading />
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal show={showModal}>
      <Modal.Header closeButton onHide={onClose}>
        <Modal.Title>Agregar Parqueadero</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={formParqueaderosSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="form-group mb-3 row">
                <div className="col-lg-4 col-form-label" htmlFor="activo">
                  Tipo de Parqueadero
                  <span
                    className="text-danger"
                  >
                    *
                  </span>
                </div>
                <div className="col-lg-7">
                  <CustomSelect
                    id="tipo_parqueadero"
                    name="tipo_parqueadero"
                    defaultsValues={values.tipo_parqueadero}
                    options={[
                      { slug: "COMUN", nombre: "COMUN" },
                      { slug: "DE_VISITANTES", nombre: "DE_VISITANTES" },
                      { slug: "PRIVADA", nombre: "PRIVADA" },
                      { slug: "DISCAPACITADOS", nombre: "DISCAPACITADOS" },
                      { slug: "BICICLETERO", nombre: "BICICLETERO" },
                    ]}
                    onChange={(selectedOptions) => {
                      setFieldValue('tipo_parqueadero', selectedOptions ? selectedOptions.value : null);
                    }}
                  />
                  <div
                    id="val-tipo_parqueadero-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{ display: "block" }}
                  >
                    {errors.tipo_parqueadero}
                  </div>
                </div>
              </div>

              <div className="form-group mb-3 row">
                <div className="col-lg-4 col-form-label" htmlFor="no_parqueadero">
                  No. Parqueadero
                  <span className="text-danger">*</span>
                </div>
                <div className="col-lg-7">
                  <Field
                    id="no_parqueadero"
                    name="no_parqueadero"
                    type="text"
                    className="form-control"
                    value={values.no_parqueadero}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="No. Parqueadero"
                  />
                  <div
                    id="val-no_parqueadero-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{ display: "block" }}
                  >
                    {errors.no_parqueadero}
                  </div>
                </div>
              </div>

              <div className="form-group mb-3 row">
                <div className="col-lg-4 col-form-label" htmlFor="entra_en_rotacion">
                  Entra en Rotación
                </div>
                <div className="col-lg-7">
                  <Field
                    id="entra_en_rotacion"
                    name="entra_en_rotacion"
                    type="checkbox"
                    className="form-check-input"
                    value={values.entra_en_rotacion}
                    checked={values.entra_en_rotacion}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <div
                    id="val-entra_en_rotacion-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{ display: "block" }}
                  >
                    {errors.entra_en_rotacion}
                  </div>
                </div>
              </div>


              <div className="form-group mb-3 row">
                <div className="col-lg-4 col-form-label" htmlFor="estatus">
                  Estado
                </div>
                <div className="col-lg-7">
                  <Field
                    id="estatus"
                    name="estatus"
                    type="checkbox"
                    className="form-check-input"
                    value={values.estatus}
                    checked={values.estatus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <div
                    id="val-estatus-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{ display: "block" }}
                  >
                    {errors.estatus}
                  </div>
                </div>
              </div>

            </Modal.Body>
            <Modal.Footer>
              {(loadingBtn) ? <Loading /> : (
                <ButtonGroup aria-label="Botones de acciones">
                  <BotonDangerText
                    type="button"
                    onClick={onClose}
                  >
                    Cerrar
                  </BotonDangerText>
                  <BotonPrimaryText
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Guardar
                  </BotonPrimaryText>
                </ButtonGroup>
              )}
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  )
}


export default ModalFormParqueadero
