import React, { useMemo, useEffect, useState, useCallback } from 'react';

import PageTitle from "../../../layouts/PageTitle";
import SmallModal from '../components/small_modal';
import { useTable, useFilters, usePagination, useSortBy } from 'react-table';
import '../../table/FilteringTable/filtering.css';
import axiosInstance from '../../../../services/AxiosInstance';
import { getDefaultParams } from '../../../../services/DefaultParamets';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import Tables from '../components/tables';
import SearchInput from '../components/input_search';
import Swal from "sweetalert2";

import { notifySuccess, notifyError } from '../components/CitoPlusToastr';
import FormAgregarRercursosZonaSocial from './FormAgregarRercursosZonaSocial';
import { CitoPlusLink, CitoPlusLinkIconPrimary } from '../components/CitoPlusLink';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

export const ZonaSocialRecursos = () => {

  const URL = "/api/v3/zonas-sociales/recursos/";

  const [smallModalTitle, setSmallModalTitle] = useState('');
  const [smallModalBody, setSmallModalBody] = useState('');
  const [smallShowModal, setSmallShowModal] = useState(false);

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');

  const [nextUrl, setNextUrl] = useState([]);
  const [previousUrl, setPreviousUrl] = useState([]);

  const [defaultParams, setDefaultParams] = useState(getDefaultParams);

  // Agregar Recursos
  const [showModalAddRecurso, setShowModalAddRecursos] = useState(false);
  const [selectedZonaSocialUUID, setSelectedZonaSocialUUID] = useState(null);
  const [selectedRecursoUUID, setSelectedRecursoUUID] = useState(null);
  const [editRecurso, setEditRecurso] = useState(false);

  const handleModalAgregarRecursos = (zonaSocialUUID, recursoUUID = null, editMode = false) => {
    setEditRecurso(editMode);
    setSelectedZonaSocialUUID(zonaSocialUUID);
    setSelectedRecursoUUID(recursoUUID);
    setShowModalAddRecursos(true);
  }
  // FIN Agregar Recursos

  const columns = useMemo(() => [
    {
      id: 'nombre',
      Header: 'Nombre',
      disableFilters: true,
      disableSortable: false,
      accessor: (row) => {
        return (
          <>
            <ul>
              <li>{row.nombre}</li>
              <li>{row.descripcion}</li>
            </ul>
          </>
        );
      },
    },
    {
      id: 'zona_nombre',
      Header: 'Zona',
      disableFilters: true,
      disableSortable: false,
      accessor: (row) => {
        return (
          <>
            <ul>
              <li>{row.zona_nombre}</li>
            </ul>
          </>
        );
      },
    },
    {
      id: 'cantidad',
      Header: 'Cantidad',
      accessor: 'cantidad',
      disableFilters: true,
      disableSortable: false
    },
    {
      id: 'en_mantenimiento',
      Header: 'Mantenimiento',
      disableFilters: true,
      disableSortable: true,
      accessor: (row) => {
        return (
          <>
            <ul>
              <li>{row.en_mantenimiento ? 'Si' : 'No'}</li>
            </ul>
          </>
        );
      },
    },
    // activo
    {
      id: 'activo',
      Header: 'Activo',
      disableFilters: true,
      disableSortable: true,
      accessor: (row) => {
        return (
          <>
            <ul>
              <li>{row.activo ? 'Si' : 'No'}</li>
            </ul>
          </>
        );
      },
    },
    {
      id: 'uuid',
      Header: 'Acciones',
      disableFilters: true,
      disableSortable: true,
      accessor: (row) => {
        return (
          <>
          <ButtonGroup aria-label="Botones de acciones visitantes">
            <CitoPlusLinkIconPrimary
              onClick={() => {
                handleModalAgregarRecursos(null, row.uuid, true);
              }}
              onClose={() => {
                setEditRecurso(false);
                setSelectedZonaSocialUUID(null);
                setSelectedRecursoUUID(null);
              }}
              title="Editar"
            >
              <i className="fas fa-pencil-alt"></i>
            </CitoPlusLinkIconPrimary>
            <CitoPlusLinkIconPrimary
              onClick={() => {

              }}
              onClose={() => {
              }}
              title="Mantenimiento"
            >
              <i className="fas fa-wrench"></i>
            </CitoPlusLinkIconPrimary>
          </ButtonGroup>
          </>
        )
      },
    }
  ], []);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedFetchUnidades = useCallback(
    debounce((pageIndex, search) => {
      fetchUrl(pageIndex, search);
    }, 500), // El valor 500 es el tiempo de espera en milisegundos
    []
  );

  useEffect(() => {
    fetchUrl(1);
  }, []);

  const fetchUrl = async (pageIndex = 1, search = '', ordering = []) => {
    defaultParams.page = pageIndex;
    defaultParams.search = search;
    setLoading(true);
    try {
      if (pageIndex < 1 || pageIndex > totalPages) {
        notifyError('Página no encontrada');
        return;
      }
      const response = await axiosInstance.get(
        URL,
        {
          params: {
            ...defaultParams,
            page: pageIndex,
            search: search,
            order_by: ordering
          }
        }
      );
      setNextUrl(response.data.next);
      setPreviousUrl(response.data.previous);
      setTotalPages(response.data.total_pages);
      setCurrentPage(response.data.current_page);
      setData(response.data.results);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 404) {
        setSmallModalTitle('Título del Modal');
        setSmallModalBody('Contenido del Modal');
        setSmallShowModal(true);
        console.log("Página no encontrada");
      } else {
        console.error('Error fetching data:', error);
      };
    } finally {
      setLoading(false);
    }
  };

  const updateInmueblesList = async () => {
    await fetchUrl(currentPage, searchValue, `${order === 'desc' ? '-' : ''}${sortField}`);
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
    setLoading(true);
    debouncedFetchUnidades(1, value);
  };

  const handleEnterPress = () => {
    // Lógica para manejar la tecla Enter, si es necesario
    console.log('Presionó Enter');
  };

  const handleClearClick = () => {
    handleSearchChange('');
  };

  const handleSortingChange = (column) => {
    const { id } = column;

    const newOrder = sortField === id ? (order === 'asc' ? 'desc' : 'asc') : 'asc';

    setSortField(id);
    setOrder(newOrder);
    setLoading(true);
    fetchUrl(1, searchValue, `${newOrder === 'desc' ? '-' : ''}${id}`);
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    toggleAllRowsSelected
  } = tableInstance;

  return (
    <>
      <div className='d-flex aling-items-center mb-4 flex-wrap'>
        <h4 className="fs-20 font-w600 me-auto">Recursos</h4>
        <CitoPlusLink
          to={'#'}
          className="btn btn-citoplus-primary"
          onClick={() => {
            handleModalAgregarRecursos(null);
          }}>
          Agregar Recurso
        </CitoPlusLink>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="card-tools">
            <SearchInput
              onSearchChange={handleSearchChange}
              onEnterPress={handleEnterPress}
              onClearClick={handleClearClick}
            />
          </div>
        </div>
        <div className="card-body">
          {loading ? (
            <p>Cargando...</p>
          ) : data.length === 0 ? (
            <p>No hay datos disponibles.</p>
          ) : (
            <Tables
              columns={columns}
              data={data}
              loading={loading}
              totalPages={totalPages}
              currentPage={currentPage}
              fetchUrl={fetchUrl}
              handleSortingChange={handleSortingChange}
              nextUrl={nextUrl}
              previousUrl={previousUrl}
              sortField={sortField}
              order={order}
              page={page}
            />
          )}
        </div>
      </div>

      <div>
        {/* Resto del contenido del componente */}

        {smallShowModal && (
          <SmallModal
            title={smallModalTitle}
            body={smallModalBody}
            show_modal={smallShowModal}
            onClose={() => setSmallShowModal(false)}
          />
        )}
      </div>

      {/* MODAL AGREGAR RECURSO ZONA SOCIAL */}
      <FormAgregarRercursosZonaSocial
        showModal={showModalAddRecurso}
        onClose={() => {
          setShowModalAddRecursos(false);
          setSelectedZonaSocialUUID(null);
          setEditRecurso(false);
          setSelectedRecursoUUID(null);
        }}
        updateList={fetchUrl}
        selectedZonaSocialUUID={selectedZonaSocialUUID}
        selectedRecursoUUID={selectedRecursoUUID}
        editMode={editRecurso}
      />
      {/* */}

    </>
  );
};

export default ZonaSocialRecursos;
