import React, { useState, useEffect } from "react";
import { Formik, ErrorMessage, Field } from "formik";
import Loading from "../components/loading";
import * as Yup from "yup";
import axiosInstance from "../../../../services/AxiosInstance";
import swal from "sweetalert";
import DatePicker from 'react-datepicker'; // Asegurate de importar correctamente
import { useNavigate } from 'react-router-dom';
import { BotonPrimaryText, BotonInfoText } from "../components/BotonesCustom";
import { FieldBasic } from "../components/FieldBasic";


import "react-datepicker/dist/react-datepicker.css";


const formZonaSocialSchema = Yup.object().shape({
  nombre: Yup.string()
    .required("Por favor, ingresa un nombre.")
    .nullable(true),

  responsable: Yup.string()
    .required("Por favor, ingresa un responsable.")
    .nullable(true),

  horario_inicio: Yup.date()
    .required("Por favor, ingresa un horario de inicio.")
    .nullable(true),

  horario_fin: Yup.date()
    .required("Por favor, ingresa un horario de fin.")
    .nullable(true),

  capacidad_min: Yup.number()
    .required("Por favor, ingresa un capacidad minima.")
    .min(1, "Debe ser mayor o igual a 1.")
    .max(100, "Debe ser menor o igual a 100.")
    .nullable(true),

  capacidad_max: Yup.number()
    .required("Por favor, ingresa un capacidad maxima.")
    .min(1, "Debe ser mayor o igual a 1.")
    .max(100, "Debe ser menor o igual a 100.")
    .nullable(true),

})


export const FormZonaSocial = () => {

  const URL = "/api/v3/zonas-sociales/zonas/"

  const initialValuesEmpty = {
    nombre: null,
    responsable: null,
    valor: 0,
    reservable: false,
    horario_inicio: null,
    horario_fin: null,
    capacidad_min: 0,
    capacidad_max: 0,
    zona_recursos: [],
  }

  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState(initialValuesEmpty);

  const handleSubmit = async (values, { setSubmitting, resetForm, setFieldError }) => {
    try {
      setLoadingBtn(true);
      const formattedStartDate = values.horario_inicio ? values.horario_inicio.toLocaleTimeString('es-CO', { hour12: false }) : null;
      const formattedEndDate = values.horario_fin ? values.horario_fin.toLocaleTimeString('es-CO', { hour12: false }) : null;

      const formatteValues = {
        ...values,
        horario_inicio: formattedStartDate,
        horario_fin: formattedEndDate,
      }

      const response = await axiosInstance.post(URL, formatteValues);
      resetForm(initialValuesEmpty);
      navigate('/zonas-sociales');
    } catch (error) {
      if (error.response && error.response.data) {
        const apiErrors = error.response.data;
        Object.keys(apiErrors).forEach((fieldName) => {
          let fieldConvert = fieldName;
          const apiErrors = error.response.data;

          if (fieldName === 'non_field_errors') {
            let error_mensaje = '';
            const formattedErrors = apiErrors[fieldName].map((error, index) => (
              error_mensaje = `${error_mensaje} ${error}`
            ));

            swal('Error Creando Correo', error_mensaje, "error");
          }
          const formattedErrors = apiErrors[fieldName].map((error, index) => (
            <React.Fragment key={index}>
              {error}
              {index < apiErrors[fieldName].length - 1 && <br />} {/* Agrega <br /> entre errores */}
            </React.Fragment>
          ));

          setFieldError(fieldConvert, formattedErrors);
        });
      }
      console.error('Error fetching data:', error);
      swal("Error", "Ha ocurrido un error al enviar el formulario. Por favor, intenta nuevamente.", "error");
    } finally {
      setLoadingBtn(false);
    }
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="d-flex alin-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600  me-auto">Crear Zona Social</h4>
      </div>
      <div className="card">
        <div className="card-body">
          <Formik
            initialValues={initialValues}
            validationSchema={formZonaSocialSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue
            }) => {
              return (
                <form onSubmit={handleSubmit}>

                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="nombre">
                      <label htmlFor="descripcion">Nombre </label>
                      <span className="text-danger"> * </span>
                    </div>
                    <div className="col-lg-7">
                      <Field
                        name="nombre"
                        id="nombre"
                        values={values}
                        className="form-control"
                      />
                      <div
                        id="val-nombre-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.nombre}
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="responsable">
                      <label htmlFor="responsable">Responsable </label>
                      <span className="text-danger"> * </span>
                    </div>
                    <div className="col-lg-7">
                      <Field
                        name="responsable"
                        id="responsable"
                        values={values}
                        className="form-control"
                      />
                      <div
                        id="val-responsable-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.responsable}
                      </div>
                    </div>
                  </div>

                  <FieldBasic
                      label="Valor"
                      id="valor"
                      name="valor"
                      required
                      errors={errors.valor}
                    >
                      <Field
                        name="valor"
                        id="valor"
                        values={values}
                        className="form-control"
                      />
                      <div
                        id="val-valor-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.valor}
                      </div>
                    </FieldBasic>

                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="reservable">
                      <label htmlFor="reservable">Reservable </label>
                    </div>
                    <div className="col-lg-7">
                      <Field
                        type="checkbox"
                        name="reservable"
                        id="reservable"
                        checked={values.reservable}
                        values={values}
                      />
                      <div
                        id="val-reservable-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.reservable}
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="horario_inicio">
                      <label htmlFor="horario_inicio">Horario Inicio y Fin </label>
                      <span className="text-danger"> * </span>
                    </div>
                    <div className="col-lg-7">
                      <div className="d-flex justify-content-between">
                        <div>
                          <DatePicker
                            selected={values.horario_inicio}
                            name="horario_inicio"
                            id="horario_inicio"
                            values={values}
                            className="form-control"
                            showTimeSelect
                            showTimeSelectOnly
                            dateFormat={"HH:mm aa"}
                            onChange={(date) => {
                              setFieldValue("horario_inicio", date);
                            }}
                          />
                          <div
                            id="val-horario_inicio-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.horario_inicio}
                          </div>
                        </div>
                        <div className="pl-2 pr-2">Hasta</div>
                        <div>
                          <DatePicker
                            selected={values.horario_fin}
                            name="horario_fin"
                            id="horario_fin"
                            values={values}
                            className="form-control"
                            showTimeSelect
                            showTimeSelectOnly
                            dateFormat={"HH:mm aa"}
                            onChange={(date) => {
                              setFieldValue("horario_fin", date);
                            }}
                          />
                          <div
                            id="val-horario_inicio-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.horario_fin}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="capacidad_min">
                      <label htmlFor="capacidad_max">Capacidad Max </label>
                    </div>
                    <div className="col-lg-7">
                      <div className="d-flex justify-content-between">
                        <div>
                          <Field
                            name="capacidad_min"
                            id="capacidad_min"
                            values={values}
                            className="form-control"
                          />
                          <div
                            id="val-capacidad_max-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.capacidad_min}
                          </div>
                        </div>
                        <div>
                          <span className="pl-2 pr-2">Hasta</span>
                        </div>
                        <div>
                          <Field
                            name="capacidad_max"
                            id="capacidad_max"
                            values={values}
                            className="form-control"
                          />
                          <div
                            id="val-capacidad_max-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.capacidad_min}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* zona_recursos */}
                  <div className="form-inline justify-content-between">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label>Nombre</label>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group mb-2">
                          <label>Cantidad</label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label>Descripción</label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mb-3">
                          <label>Eliminar</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {values.zona_recursos.map((recurso, index) => (
                    <div className="form-inline justify-content-between" key={index}>
                      <small>{recurso.descripcion}</small>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <Field
                              name={`zona_recursos[${index}].nombre`}
                              id={`recurso_nombre_${index}`}
                              className="form-control"
                              placeholder="Nombre"
                            />
                            <ErrorMessage name={`zona_recursos[${index}].recurso_nombre`} component="div" className="invalid-feedback" />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group mb-2">
                            <Field
                              name={`zona_recursos[${index}].cantidad`}
                              id={`recurso_cantidad_${index}`}
                              className="form-control"
                              placeholder="Cantidad"
                            />
                            <ErrorMessage name={`zona_recursos[${index}].recurso_url`} component="div" className="invalid-feedback" />
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <Field
                              name={`zona_recursos[${index}].descripcion`}
                              id={`recurso_url_${index}`}
                              className="form-control"
                              placeholder="Descripcion"
                              as="textarea"
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group mb-3">
                            <button
                              type="button"
                              className="btn btn-citoplus-danger"
                              onClick={() => {
                                setFieldValue("zona_recursos", values.zona_recursos.filter((_, i) => i !== index));
                              }}
                            >
                              <i className="fas fa-trash"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {loadingBtn ? (
                    <Loading />
                  ) : (
                    <div className="row">
                      <div className="col-12">
                        <BotonInfoText
                          type="button"
                          onClick={() => {
                            setFieldValue("zona_recursos", [...values.zona_recursos, ""]);
                          }}
                        >
                          <i className="fas fa-plus me-2"></i>
                          Agregar Recurso
                        </BotonInfoText>
                        <BotonPrimaryText
                          type="submit"
                          disabled={isSubmitting}
                        >
                          <i className="fas fa-save me-2"></i>
                          Crear Zona Social
                        </BotonPrimaryText>
                      </div>
                    </div>

                  )}
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );

}

export default FormZonaSocial
