import React, { useState, useEffect } from 'react';
import DatePickerRanger from '../../components/DatePickerRanger';
import { BotonPrimary } from '../../components/BotonesCustom';
import { formatDate } from '../../utils';

const FiltroGrabacionLlamadas = ({ onSearchChange, placeholder, ...propsDatePicker }) => {
  const [showButtonClear, setShowButtonClear] = useState(false);

  // Dates and filters del recaudo
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const defaultFilterParams = {
    search: '',
    src: '',
    dst: '',
    date_after: '',
    date_before: '',
  };

  const [filterParams, setFilterParams] = useState(defaultFilterParams);

  let timeoutId = null;

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const delayDebounceFn = (newFilterParams) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      onSearchChange(newFilterParams);
    }, 900);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShowButtonClear(true);
    const newFilterParams = {
      ...filterParams,
      [name]: value,
    };
    setFilterParams(newFilterParams);
    delayDebounceFn(newFilterParams);
  };

  const handleSearchClick = () => {
    onSearchChange(filterParams);
  };

  const handleClearClick = () => {
    setShowButtonClear(false);
    setDateRange([null, null]);

    const newFilterParams = {
      ...defaultFilterParams,
    };
    setFilterParams(newFilterParams);
    onSearchChange(newFilterParams);
  };

  return (
    <div className="row align-items-center">
      <div className="input-group input-group-sm position-relative">
        <input
          type="text"
          name="search"
          autoComplete="off"
          value={filterParams.search}
          className="form-control form-control-xs"
          placeholder={placeholder || 'Buscar...'}
          onChange={handleInputChange}
        />

        <input
          type="text"
          name="src"
          autoComplete="off"
          value={filterParams.src}
          className="form-control form-control-xs"
          placeholder="Fuente..."
          onChange={handleInputChange}
        />

        <input
          type="text"
          name="dst"
          autoComplete="off"
          value={filterParams.dst}
          className="form-control form-control-xs"
          placeholder="Destino..."
          onChange={handleInputChange}
        />

        {propsDatePicker.showDatePicker && (
          <DatePickerRanger
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setShowButtonClear(true);
              const newFilterParams = {
                ...filterParams,
                date_after: update[0] ? formatDate(update[0]) : '',
                date_before: update[1] ? formatDate(update[1]) : '',
              };
              setDateRange(update);
              setFilterParams(newFilterParams);
              delayDebounceFn(newFilterParams);
            }}
            withPortal
            placeholderText="Fecha..."
            {...propsDatePicker}
          />
        )}

        <div className="input-group-append p-2">
          <BotonPrimary onClick={handleSearchClick}>
            <i className="fas fa-search" />
          </BotonPrimary>
          {showButtonClear && (
            <BotonPrimary onClick={handleClearClick}>
              <i className="fas fa-times" />
            </BotonPrimary>
          )}
        </div>
      </div>
    </div>
  );
};

export default FiltroGrabacionLlamadas;
