import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store/store';
import ThemeContext  from "./context/ThemeContext";
import { VigilanteReloadProvider } from "./context/VisitantesVigilantesContext";

const root = ReactDOM.createRoot(document.getElementById('root'));

const isDevelopment = process.env.NODE_ENV === 'development';
const strictModeEnabled = isDevelopment || process.env.REACT_APP_ENABLE_STRICT_MODE === 'true';

root.render(
  <>
    {strictModeEnabled ? (
      <React.StrictMode>
        <Provider store={store}>
          <BrowserRouter basename='/'>
            <VigilanteReloadProvider>
              <ThemeContext>
                <App />
              </ThemeContext>
            </VigilanteReloadProvider>
          </BrowserRouter>
        </Provider>
      </React.StrictMode>
    ) : (
      <Provider store={store}>
        <BrowserRouter basename='/'>
          <VigilanteReloadProvider>
            <ThemeContext>
              <App />
            </ThemeContext>
          </VigilanteReloadProvider>
        </BrowserRouter>
      </Provider>
    )}
  </>,
);
reportWebVitals();
