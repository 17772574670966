import React, { useState, useEffect } from "react";
import PageTitle from "../../../layouts/PageTitle";
import { Formik, ErrorMessage, Field, FieldArray } from "formik";
import Loading from "../components/loading";
import * as Yup from "yup";
import CustomClearIndicator from "../components/MultiSelect";
import axiosInstance from "../../../../services/AxiosInstance";
import QuillEditor from "../components/field_quill";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import DatePicker from 'react-datepicker'; // Asegúrate de importar correctamente
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { format } from 'date-fns'
import CustomSelect from "../components/Select";
import { Navigate } from "react-router-dom";
import { BotonPrimaryText, BotonDangerText } from "../components/BotonesCustom";
import { LinkPrimary, LinkDanger } from "../components/CitoPlusLink";

const formCarteleraSchema = Yup.object().shape({
  msg: Yup.string()
    .required("Por favor, ingresa un mensaje.")
    .nullable(true),

})

export const FormCartelera = () => {

  const URL = "/api/v3/home/carteleras/";
  const initialValuesEmpty = {
    msg: "",
    fecha_limite: null,
    dirigido_a: []
  }

  const optionsDirigidoA = [
    { slug: "0", nombre: 'TODOS' },
    { slug: "1", nombre: 'VIGILANTES' },
    { slug: "2", nombre: 'RESIDENTES' },
  ];

  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [initialValues, setInitialValues] = useState(initialValuesEmpty);

  const slug = useParams();

  const navigate = useNavigate();

  const formatProgramacionEnvio = (date) => {

    const parseDate = new Date(date);
    let formattedDate;

    if (isNaN(parseDate.getTime())) {
      formattedDate = format(date, "yyyy-MM-dd");
    } else {
      formattedDate = format(parseDate, "yyyy-MM-dd");
    }
    return formattedDate;
  };

  useEffect(() => {
    if (slug && slug.slug !== undefined) {

      const fetchInitialValues = async () => {
        try {
          const response = await axiosInstance.get(`${URL}${slug.slug}/`);
          const data = await response.data;
          setInitialValues(data);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }
      fetchInitialValues();

    } else {
      setLoading(false);
    }

  }, [slug])

  if (loading) {
    return (
      <>
        <PageTitle activeMenu="Mensajeria" motherMenu="Crear Cartelera" />
        <Loading />
      </>
    )
  }

  const handleSubmit = async (values) => {
    setLoadingBtn(true);
    try {
      const postData = {
        ...values,
        fecha_limite: formatProgramacionEnvio(values.fecha_limite),
      }
      if (slug && slug.slug !== undefined) {
        const response = await axiosInstance.patch(`${URL}${slug.slug}/`, postData);
        const data = response.data;
        swal("Cartelera", "Cartelera Editada", "success");
      } else {
        const response = await axiosInstance.post(URL, postData);
        const data = response.data;
        swal("Cartelera", "Cartelera Creada", "success");
      }
      navigate('/envio_masivo_cartelera');
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoadingBtn(false);
    }
  }

  return (
    <>
      <div className="d-flex alin-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600  me-auto">Crear Cartelera</h4>
      </div>
      <div className="card">
        <div className="card-body">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={formCarteleraSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting
            }) => (
              <form onSubmit={handleSubmit}>

                <div className="form-group mb-3 row">
                  <div className="col-lg-4 col-form-label" htmlFor="email">
                    <label htmlFor="descripcion">Mensaje </label>
                    <span className="text-danger"> * </span>
                  </div>
                  <Field
                    component={QuillEditor}
                    name="msg"
                    id="msg"
                    placeholder="Escribe algo..."
                    onEditorChange={(value) => setFieldValue('msg', value)}
                  />
                </div>

                <div className="form-group mb-3 row">
                  <div className="col-lg-4 col-form-label">
                    <label className="form-label">Fecha Limite</label>
                  </div>
                  <div className="col-lg-7">
                    <DatePicker
                      id="progarmacion_envio"
                      name="progarmacion_envio"
                      selected={values.fecha_limite ? new Date(values.fecha_limite) : null}
                      onChange={(date) => setFieldValue('fecha_limite', date)}
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Seleccionar fecha..."
                    />
                    <div id="val-progarmacion_envio-error" className="invalid-feedback animated fadeInUp" style={{ display: "block" }}>
                      {errors.progarmacion_envio}
                    </div>
                  </div>
                </div>

                <div className="form-group mb-3 row">
                  <div className="col-lg-4 col-form-label" htmlFor="dirigido_a">
                    <label htmlFor="dirigido_a">Dirigido A</label>
                  </div>
                  <div className="col-lg-7">
                    <CustomSelect
                      id="dirigido_a"
                      name="dirigido_a"
                      options={optionsDirigidoA}
                      defaultsValues={values.dirigido_a.toString()}
                      onChange={(selectedOptions) => {
                        setFieldValue('dirigido_a', selectedOptions ? selectedOptions.value : null);
                      }}
                    />
                  </div>
                </div>

                {loadingBtn ? (
                    <Loading />
                  ) : (
                    <div className="modal-footer">
                      <LinkDanger
                        to='/envio_masivo_email'
                      >
                        Cancelar
                      </LinkDanger>
                      <BotonPrimaryText
                        type="submit"
                        disabled={isSubmitting}>
                        Agregar
                      </BotonPrimaryText>
                    </div>
                  )}

              </form>
            )}
          </Formik>

        </div>
      </div>
    </>
  )

}

export default FormCartelera;
