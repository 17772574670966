import React from "react";
import Select from "react-select";

const CustomClearText = () => "borrar todo";

const ClearIndicator = (props) => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};

const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: "pointer",
  color: state.isFocused ? "blue" : "black",
});



export default function CustomClearIndicator({
  options,
  onChange,
  defaultsValues,
  isMulti = true,
}) {
  const validOptions = Array.isArray(options) ? options : [];

  // Asegúrate de que defaultsValues sea un objeto con la propiedad 'value'
  const mappedDefaultsValues = Array.isArray(defaultsValues)
    ? defaultsValues.map((defaultValue) => ({
      value: defaultValue.slug,
      label: defaultValue.nombre,
      }))
    : [];

  // Mapea las opciones para incluir explícitamente las propiedades value y label
  const mappedOptions = validOptions.map((option) => ({
    value: option.slug,
    label: option.nombre,
  }));

  const defaultValue = isMulti ? mappedDefaultsValues : mappedDefaultsValues[0];

  return (
    <Select
      closeMenuOnSelect={!isMulti}
      components={{ ClearIndicator }}
      styles={{ clearIndicator: ClearIndicatorStyles }}
      defaultValue={defaultValue}
      onChange={onChange}
      isMulti={isMulti}
      options={mappedOptions}
      placeholder="Seleccione .."
    />
  );
}
