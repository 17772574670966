/// Menu
//import Metismenu from "metismenujs";
import { useSelector } from 'react-redux';
import React, { useReducer, useContext, useEffect, useState, useRef } from "react";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import { Collapse, Dropdown } from 'react-bootstrap';
/// Link
import { Link } from "react-router-dom";



import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import LogoutPage from './Logout';


import { MenuList } from './Menu';
import { MenuListVigilante } from './MenuVigilante';

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
}

export function NavMenuToggle() {
  // setTimeout(() => {
  //   let mainwrapper = document.querySelector("#main-wrapper");
  //   if (mainwrapper.classList.contains('menu-toggle')) {
  //     mainwrapper.classList.remove("menu-toggle");
  //   }
  // }, 200);
}

const SideBar = () => {
  const sidebarRef = useRef(null); // Ref for sidebar
  let d = new Date();

  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    ChangeIconSidebar,
  } = useContext(ThemeContext);

  const [state, setState] = useReducer(reducer, initialState);


  let handleheartBlast = document.querySelector('.heart');
  function heartBlast() {
    return handleheartBlast.classList.toggle("heart-blast");
  }

  const [hideOnScroll, setHideOnScroll] = useState(true)
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll]
  )


  const handleMenuActive = status => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  }
  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status })
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" })
    }
  }

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  const auth = JSON.parse(localStorage.getItem('userDetails'));

  // Cargar MENU
  const tipoUsuario = useSelector(state => state.auth.auth.user.tipo);
  const [ConditionalMenuList, setConditionalMenuList] = useState([]);

  useEffect(() => {
    if (tipoUsuario === 'VIGILANTE') {
      // Dynamically import the conditional menu list
      setConditionalMenuList(() => MenuListVigilante);
    } else {
      // Use the default menu list
      setConditionalMenuList(() => MenuList);
    }
  }, [tipoUsuario]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    NavMenuToggle()
  };

  return (
    <div
      onMouseEnter={() => ChangeIconSidebar(true)}
      onMouseLeave={() => ChangeIconSidebar(false)}
      onClick={() => ChangeIconSidebar(false)}
      className={`dlabnav ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? hideOnScroll > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <div className="header-profile2 i-false c-pointer">
          <div className="header-info2 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center sidebar-info">
              <div>
                {auth.user.first_name && auth.user.last_name ? (
                  <span className="font-w400 d-block">{auth.user.first_name} {auth.user.last_name}</span>
                ) : (
                  <span className="font-w400 d-block">{auth.user.email}</span>
                )}
                <small className="text-end font-w400">{auth.user.username}</small>
              </div>
            </div>
            <div className="ml-auto">
              <LogoutPage />
            </div>
          </div>
        </div>
        <ul className="metismenu" id="menu">
          {ConditionalMenuList.map((data, index) => {
            let menuClass = data.classsChange;
            if (menuClass === "menu-title") {
              return (
                <li className={menuClass} key={index} >{data.title}</li>
              )
            } else {
              return (
                <li className={` ${state.active === data.title ? 'mm-active' : ''}`}
                  key={index}
                >

                  {data.content && data.content.length > 0 ?
                    <>
                      <Link to={"#"}
                        className="has-arrow"
                        onClick={() => { handleMenuActive(data.title) }}
                      >
                        {data.iconStyle}
                        <span className="nav-text">{data.title}</span>
                        <span className="badge badge-xs style-1 badge-danger">{data.update}</span>
                      </Link>
                      <Collapse in={state.active === data.title ? true : false}>
                        <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                          {data.content && data.content.map((data, index) => {
                            return (
                              <li key={index}
                                className={`${state.activeSubmenu === data.title ? "mm-active" : ""}`}
                              >
                                {data.content && data.content.length > 0 ?
                                  <>
                                    <Link to={data.to} className={data.hasMenu ? 'has-arrow' : ''}
                                      onClick={() => { handleSubmenuActive(data.title) }}
                                    >
                                      {data.title}
                                    </Link>
                                    <Collapse in={state.activeSubmenu === data.title ? true : false}>
                                      <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                                        {data.content && data.content.map((data, index) => {
                                          return (
                                            <>
                                              <li key={index}>
                                                <Link className={`${path === data.to ? "mm-active" : ""}`} to={data.to}>{data.title}</Link>
                                              </li>
                                            </>
                                          )
                                        })}
                                      </ul>
                                    </Collapse>
                                  </>
                                  :
                                  <Link to={data.to}>
                                    {data.title}
                                  </Link>
                                }

                              </li>

                            )
                          })}
                        </ul>
                      </Collapse>
                    </>
                    :
                    <Link to={data.to} >
                      {data.iconStyle}
                      <span className="nav-text">{data.title}</span>
                    </Link>
                  }

                </li>
              )
            }
          })}
        </ul>

        {/*
        <div className="plus-box">
          <p className="fs-14 font-w600 mb-2">Let Jobick Managed<br />Your Resume Easily<br /></p>
          <p className="plus-box-p">Lorem ipsum dolor sit amet</p>
        </div>
        */}
        <div className="copyright">
          <p><strong>CitoPlus</strong> © {d.getFullYear()} All Rights Reserved</p>
          <p className="fs-12">Made with <span className="heart" onClick={() => heartBlast()}></span> by CitoPlus</p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
