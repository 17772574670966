import {
    LOADING_TOGGLE_ACTION,
    LOGIN_CONFIRMED_ACTION,
    LOGIN_FAILED_ACTION,
    LOGOUT_ACTION,
    SIGNUP_CONFIRMED_ACTION,
    SIGNUP_FAILED_ACTION,
} from '../actions/AuthActions';

const initialState = {
    auth: {
        access: '',
        expiresIn: '',
        refresh: '',
        user: {
          pk: "",
          username: "",
          email: "",
          first_name: "",
          last_name: "",
          nit_cc: "",
          tipo: "",
          perfil_extra: {
            citofono_importar: false,
            citofono_exportar: false,
            citofono_activar: false
          }
      }
    },
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export function AuthReducer(state = initialState, action) {
    if (action.type === SIGNUP_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: 'Signup Successfully Completed',
            showLoading: false,
        };
    }
    if (action.type === LOGIN_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: 'Login Successfully Completed',
            showLoading: false,
        };
    }

    if (action.type === LOGOUT_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: '',
            auth: {
              access: '',
              expiresIn: '',
              refresh: '',
              user: {
                pk: "",
                username: "",
                email: "",
                first_name: "",
                last_name: "",
                nit_cc: "",
                tipo: "",
                perfil_extra: {
                  citofono_importar: false,
                  citofono_exportar: false,
                  citofono_activar: false
                }
            }
          },
        };
    }

    if (
        action.type === SIGNUP_FAILED_ACTION ||
        action.type === LOGIN_FAILED_ACTION
    ) {
        return {
            ...state,
            errorMessage: action.payload,
            successMessage: '',
            showLoading: false,
        };
    }

    if (action.type === LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}


