export const MenuListVigilante = [
    // Inicio
    {
      title: 'Inicio',
      iconStyle: <i className="flaticon-025-dashboard"></i>,
      to: 'dashboard',
    },
    {
      title: "Cierre de cajas",
      iconStyle: <i className="fas fa-money-bill"></i>,
      to: 'cajas',
    },
    {
      title: "Visitantes vehicular",
      iconStyle: <i className="fas fa-parking"></i>,
      to: 'visitantes-vehiculares',
    },
    {
      title: "Visitantes peatonal",
      iconStyle: <i className="fas fa-person-walking"></i>,
      to: 'visitantes-peatonales',
    },
    {
      title: "Correspondencia",
      iconStyle: <i className="fas fa-truck-fast fas-1x"></i>,
      to: 'correspondencia',
    },
    {
      title: "Autorizaciones",
      iconStyle: <i className="fas fa-user-check"></i>,
      to: 'autorizaciones',
    },
    /*{
      title: "Reservas",
      iconStyle: <i className="fas fa-champagne-glasses"></i>,
      to: 'cierre-cajas',
    },
    {
      title: "Parqueadero",
      iconStyle: <i className="fas fa-parking"></i>,
      to: 'cierre-cajas',
    },*/
    {
      title: "Minuta",
      iconStyle: <i className="fas fa-file-lines"></i>,
      to: 'minuta',
    },
  ]
