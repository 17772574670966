import React from "react";
import { Link } from "react-router-dom";

export const CitoPlusLink = ({ to, children, ...props }) => {
  return (
    <Link
      to={to}
      className={`btn btn-sm ${props.className}`}
      title={props.title}
      onClick={props.onClick}
    >
      {children}
    </Link>
  );
};

// Link sin botones

export const LinkPrimary = ({ to, children, ...props }) => {
  return (
    <Link
      to={to}
      className={`btn btn-sm btn-citoplus-primary shadow me-1 ${props.className}`}
      title={props.title}
      onClick={props.onClick}
    >
      {children}
    </Link>
  );
};

export const LinkSecondary = ({ to, children, ...props }) => {
  return (
    <Link
      to={to}
      className={`btn btn-sm btn-citoplus-secondary shadow me-1 ${props.className}`}
      title={props.title}
      onClick={props.onClick}
    >
      {children}
    </Link>
  );
}

export const LinkDanger = ({ to, children, ...props }) => {
  return (
    <Link
      to={to}
      className={`btn btn-sm btn-citoplus-danger shadow me-1 ${props.className}`}
      title={props.title}
      onClick={props.onClick}
    >
      {children}
    </Link>
  );
}


// Link con botones

export const CitoPlusLinkIconPrimary = ({ to, children, ...props }) => {
  return (
    <Link
      to={to}
      className={`btn btn-sm btn-citoplus-primary shadow content-icon me-1 ${props.className}`}
      title={props.title}
      onClick={props.onClick}
    >
      {children}
    </Link>
  );
};

export const CitoPlusLinkIconDanger = ({ to, children, ...props }) => {
  return (
    <Link
      to={to}
      className={`btn btn-sm btn-citoplus-danger shadow content-icon me-1 ${props.className}`}
      title={props.title}
      onClick={props.onClick}
    >
      {children}
    </Link>
  );
}

export const CitoPlusLinkIconSecondary = ({ to, children, ...props }) => {
  return (
    <Link
      to={to}
      className={`btn btn-sm btn-citoplus-secondary shadow content-icon me-1 ${props.className}`}
      title={props.title}
      onClick={props.onClick}
    >
      {children}
    </Link>
  );
}
