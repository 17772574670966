import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import { Formik, resetForm } from "formik";
import axiosInstance from '../../../../services/AxiosInstance';
import swal from "sweetalert";
import * as Yup from "yup";
import { BotonPrimary, BotonPrimaryText } from "../components/BotonesCustom";

const changePasswordSchema = Yup.object().shape({
  old_password: Yup.string()
    .min(8, "La contraseña debe tener al menos 8 caracteres.")
    .required("Por favor, ingresa tu contraseña actual."),
  new_password1: Yup.string()
    .min(8, "La nueva contraseña debe tener al menos 8 caracteres.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&\[\]().^+=_\-\\\/|{}:;,<>~`#?])[A-Za-z\d@$!%*?&\[\]().^+=_\-\\\/|{}:;,<>~`#?]{8,}$/,
      "La contraseña debe contener al menos una mayúscula, una minúscula, un número y un carácter especial."
    )
    .required("Por favor, ingresa una nueva contraseña."),
  new_password2: Yup.string()
    .oneOf([Yup.ref("new_password1"), null], "Las contraseñas deben coincidir.")
    .required("Por favor, confirma tu nueva contraseña."),
});


const CambioClave = () => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword1, setShowNewPassword1] = useState(false);
  const [showNewPassword2, setShowNewPassword2] = useState(false);

  const URL = '/rest-auth/password/change/';
  const titleCard = "Cambio Clave";
  const activeMenu = "Configuracion";
  const motherMenu = "Cambio Clave";

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await axiosInstance.post('/rest-auth/password/change/', values);
      swal('Cambio Clave', response.data.detail, "success");


      resetForm({
          old_password: '',
          new_password1: '',
          new_password2: '',
      });

      setShowOldPassword(false);
      setShowNewPassword1(false);
      setShowNewPassword2(false);

    } catch (error) {
      if (error.response) {
        const errorMessages = Object.values(error.response.data).flat();
        const errorMessage = errorMessages.join("\n");
        swal('Cambio Clave', errorMessage, "error");
      } else {
        swal('Cambio Clave', error.message, "error");
      }
    } finally {

    }
  };

  return (
    <Fragment>
      <PageTitle
        activeMenu={activeMenu}
        motherMenu={motherMenu}
        pageContent="Validation"
      />

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Cambio Clave</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <Formik
                  initialValues={{ old_password: "", new_password1: "", new_password2: "" }}
                  validationSchema={changePasswordSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div
                        className="form-group mb-3">
                        <label className="text-label">Clave Actual *</label>
                        <div className="input-group transparent-append mb-2">

                          <span className="input-group-text">
                            {" "}
                            <i className="fa fa-lock" />{" "}
                          </span>

                          <input
                            type={`${showOldPassword ? "text" : "password"}`}
                            className="form-control"
                            id="val-old_password"
                            name="old_password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.old_password}
                            placeholder="Clave Actual ..."
                          />

                          <div
                            className="input-group-text show-validate"
                            onClick={() => setShowOldPassword(!showOldPassword)}
                          >

                            {" "}
                            {showOldPassword === false ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}

                          </div>
                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.old_password && errors.old_password}
                          </div>
                        </div>
                      </div>


                      <div
                        className={`form-group mb-3 ${values.new_password1
                            ? errors.new_password1
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                          }`}
                      >
                        <label className="text-label">Clave Nueva *</label>
                        <div className="input-group transparent-append mb-2">

                          <span className="input-group-text">
                            {" "}
                            <i className="fa fa-lock" />{" "}
                          </span>

                          <input
                            type={`${showNewPassword1 ? "text" : "password"}`}
                            className="form-control"
                            id="val-new_password1"
                            name="new_password1"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.new_password1}
                            placeholder="Clave Nueva ..."
                          />

                          <div
                            className="input-group-text show-validate"
                            onClick={() => setShowNewPassword1(!showNewPassword1)}
                          >
                            {" "}
                            {showNewPassword1 === false ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}

                          </div>
                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.new_password1 && errors.new_password1}
                          </div>
                        </div>
                        <small className="text-muted">
                            <div> Debe tener al menos 8 caracteres, una mayúscula, una minúscula, un número y un carácter especial. No debe ser la misma que el nombre de usuario.</div>
                            <div>No debe ser la misma que el nombre de usuario.</div>
                          </small>
                      </div>
                      <div
                        className={`form-group mb-3 ${values.new_password2
                            ? errors.new_password2
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                          }`}
                      >
                        <label className="text-label">Clave Nueva Repetir *</label>
                        <div className="input-group transparent-append mb-2">

                          <span className="input-group-text">
                            {" "}
                            <i className="fa fa-lock" />{" "}
                          </span>

                          <input
                            type={`${showNewPassword2 ? "text" : "password"}`}
                            className="form-control"
                            id="val-new_password2"
                            name="new_password2"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.new_password2}
                            placeholder="Clave Nueva Repetir ..."
                          />

                          <div
                            className="input-group-text show-validate"
                            onClick={() => setShowNewPassword2(!showNewPassword2)}
                          >

                            {" "}
                            {showNewPassword2 === false ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}

                          </div>
                          <div
                            id="val-username1-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.new_password2 && errors.new_password2}
                          </div>
                        </div>
                        <small className="text-muted">
                            <div> Debe tener al menos 8 caracteres, una mayúscula, una minúscula, un número y un carácter especial. No debe ser la misma que el nombre de usuario.</div>
                            <div>No debe ser la misma que el nombre de usuario.</div>
                          </small>
                      </div>

                      <div className="modal-footer">
                        <BotonPrimaryText
                          type="submit"
                          disabled={isSubmitting}
                        >
                          <i className="fa fa-save me-2"></i>
                          Cambiar
                        </BotonPrimaryText>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CambioClave;
