import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import Loading from "../components/loading";
import axiosInstance from "../../../../services/AxiosInstance";

export const CarteleraDetail = () => {

  const [loading, setLoading] = useState(true);
  const [carteleraData, setCarteleraData] = useState({});

  const URL = "/api/v3/home/carteleras/";

  const { slug } = useParams();

    useEffect(() => {
      setLoading(true);
      const fetchInitialValues = async () => {
        try {
          const response = await axiosInstance.get(`${URL}${slug}/`);
          const data = await response.data;
          setCarteleraData(data);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }

      fetchInitialValues();
    }, [slug]);

    if (loading) {
      return (
        <>
          <PageTitle activeMenu={"Cartelera"} motherMenu={"Mensajeria"} />
          <div className="d-flex aling-items-center mb-4 flex-wrap">
            <h4 className="f-20 font-w600 me-auto">Detalle Correo</h4>
          </div>
          <div className="card">
            <div className="card-body">
              <Loading />
            </div>
          </div>

        </>
      );
    }

    return (
      <>
        <PageTitle activeMenu={"Cartelera"} motherMenu={"Mensajeria"} />
        <div className="d-flex aling-items-center mb-4 flex-wrap">
          <h4 className="f-20 font-w600 me-auto">Detalle Correo</h4>
        </div>
        <div className="card">
          <div className="card-body">
            <p className="paragraph">Creado: {carteleraData.created_at}</p>
            <p className="paragraph">Dirigido A: {carteleraData.dirigido_a_display}</p>
            <p className="paragraph">Fecha Limite: {carteleraData.fecha_limite}</p>
            <div
              dangerouslySetInnerHTML={{ __html: carteleraData.msg }}
              style={{ border: "2px solid black", padding: "10px" }}
            />
          </div>
        </div>

      </>
    )

}

export  default CarteleraDetail;
