import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, ErrorMessage } from 'formik';
import Loading from "../components/loading";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { notifySuccess } from "../components/CitoPlusToastr";
import axiosInstance from "../../../../services/AxiosInstance";
import CustomSelect from "../components/Select";


const formRecursosZonaSocialSchema = Yup.object().shape({
  nombre: Yup.string()
    .required("Por favor, ingresa un nombre.")
    .nullable(true),
  descripcion: Yup.string()
    .required("Por favor, ingresa una descripción.")
    .nullable(true),
  cantidad: Yup.string()
    .required("Por favor, ingresa una cantidad.")
    .matches(/^[0-9]+$/, 'La cantidad debe ser un número')
    .nullable(true),
});

const FormAgregarRercursosZonaSocial = ({ showModal, onClose, updateList, selectedZonaSocialUUID, selectedRecursoUUID, editMode }) => {

  const initialValuesEmpty = {
    nombre: null,
    descripcion: null,
    cantidad: null,
    activo: true,
    zona: "",
  }
  const URL = "/api/v3/zonas-sociales/recursos/";

  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(initialValuesEmpty);

  // Options Zonas Sociales
  const URL_ZONAS = "/api/v3/zonas-sociales/zonas/dropdown/";
  const [zonaSocialDropDown, setZonaSocialDropDown] = useState([]);

  useEffect(() => {
    const fetchDataZonasSociales = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(URL_ZONAS);
        const data = await response.data;
        setZonaSocialDropDown(data);
      } catch (error) {
        if (error.response) {
          const errorMessages = Object.values(error.response.data).flat();
          const errorMessage = errorMessages.join("\n");
          Swal.fire('Error', errorMessage, "error");
        } else {
          Swal.fire('Error', error.message, "error");
        }
      } finally {
        setLoading(false);
      }
    };

    if (selectedZonaSocialUUID === null) {
      fetchDataZonasSociales();
    }
  }, []);
  // FIN Options Zonas Sociales

  // Modo de Edicion
  useEffect(() => {
    const fetchRecursoDetalle = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(`${URL}${selectedRecursoUUID}/`);
        const data = await response.data;
        setInitialValues({
          nombre: data.nombre,
          descripcion: data.descripcion,
          cantidad: data.cantidad,
          activo: data.activo
        });
      } catch (error) {
        if (error.response) {
          const errorMessages = Object.values(error.response.data).flat();
          const errorMessage = errorMessages.join("\n");
          Swal.fire('Error', errorMessage, "error");
        } else {
          Swal.fire('Error', error.message, "error");
        }
      } finally {
        setLoading(false);
      }
    }

    if (editMode) {
      fetchRecursoDetalle();
    } else {
      setInitialValues(initialValuesEmpty);
    }
  }, [editMode, selectedRecursoUUID])
  // FIN Modo de Edicion

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const postData = {
      ...values,
      zona: selectedZonaSocialUUID ? selectedZonaSocialUUID : values.zona
    }
    try {
      let response = null;
      if (editMode) {
        const finalUrl = `${URL}${selectedRecursoUUID}/`;
        response = axiosInstance.patch(finalUrl, postData);
      } else {
        response = axiosInstance.post(URL, postData);
      }
      const data = await response.data;
      resetForm(initialValuesEmpty);
      setInitialValues(initialValuesEmpty);
      onClose();
      updateList();
      // notifySuccess("Recurso agregado");
    } catch (error) {
      if (error.response) {
        const errorMessages = Object.values(error.response.data).flat();
        const errorMessage = errorMessages.join("\n");
        Swal.fire('Error', errorMessage, "error");
      } else {
        Swal.fire('Error', error.message, "error");
      }
    } finally {
      // notifySuccess("Recurso agregado");
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Modal show={showModal} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar Recurso</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={formRecursosZonaSocialSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Modal.Body>

                {(selectedZonaSocialUUID === null && selectedRecursoUUID === null) && (
                  <div className="form-group mb-3 row">
                    <div className='col-lg-4 col-form-label' htmlFor="zona">
                      <label className="form-label" htmlFor="zona">Zona Social </label>
                      <span className="text-danger">*</span>
                    </div>
                    <div className="col-lg-7">
                      <CustomSelect
                        id="zona"
                        name="zona"
                        options={zonaSocialDropDown}
                        onChange={(selectedOptions) => {
                          setFieldValue('zona', selectedOptions ? selectedOptions.value : null);
                        }}
                        defaultsValues={values.zona}
                      />
                    </div>
                    {errors.zona && touched.zona ? (
                      <div
                        id="val-zona-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.zona}
                      </div>
                    ) : null}

                  </div>
                )}

                <div className="form-group mb-3 row">
                  <div className="col-lg-4 col-form-label" htmlFor="activo">
                    Activo
                    <span className="text-danger">*</span>
                  </div>
                  <div className="col-lg-7">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="activo"
                      name="activo"
                      checked={values.activo}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div
                      id="val-activo-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.activo}
                    </div>
                  </div>
                </div>

                <div className="form-group mb-3 row">
                  <div className='col-lg-4 col-form-label' htmlFor="nombre">
                    Nombre
                    <span className="text-danger">*</span>
                  </div>
                  <div className="col-lg-7">
                    <input
                      type="text"
                      className="form-control"
                      id="nombre"
                      name="nombre"
                      value={values.nombre || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div
                      id="val-nombre-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.nombre}
                    </div>
                  </div>
                </div>

                <div className="form-group mb-3 row">
                  <div className='col-lg-4 col-form-label' htmlFor="cantidad">
                    Cantidad
                    <span className="text-danger">*</span>
                  </div>
                  <div className="col-lg-7">
                    <input
                      type="text"
                      className="form-control"
                      id="cantidad"
                      name="cantidad"
                      value={values.cantidad || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div
                      id="val-cantidad-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.cantidad}
                    </div>
                  </div>
                </div>


                <div className="form-group mb-3 row">
                  <div className='col-lg-4 col-form-label' htmlFor="descripcion">
                    Descripcion
                    <span className="text-danger">*</span>
                  </div>
                  <div className="col-lg-7">
                    <input
                      type="text"
                      className="form-control"
                      id="descripcion"
                      name="descripcion"
                      value={values.descripcion || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div
                      id="val-descreption-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.descripcion}
                    </div>
                  </div>
                </div>

              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-sm btn-citoplus-danger"
                  onClick={onClose}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="btn btn-sm btn-citoplus-primary"
                  disabled={isSubmitting}
                >
                  Agregar
                </button>
              </Modal.Footer>
            </form>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default FormAgregarRercursosZonaSocial
