// QuillEditor.js
import React from 'react';
import Quill from 'quill';
import ReactQuill from 'react-quill';
import ResizeModule from '@botom/quill-resize-module';
import 'react-quill/dist/quill.snow.css';

const QuillEditor = ({ field, form, ...props }) => {
  // Registrando el módulo de redimensionamiento si no está registrado
  if (!Quill.imports['modules/resize']) {
    Quill.register('modules/resize', ResizeModule);
  }

  const { name } = field;
  const { touched, errors, setFieldValue } = form;

  // Manejar cambios en el editor
  const handleChange = (content, delta, source, editor) => {
    setFieldValue(name, content);
  };

  // Manejar el evento de desenfoque
  const handleBlur = () => {
    form.handleBlur({ target: { name } });
  };

  return (
    <div className="quill-editor-container">
      <ReactQuill
        theme="snow"
        value={field.value || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        modules={{
          toolbar: {
            container: [
              [{ header: '1' }, { header: '2' }, { font: [] }],
              [{ size: [] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
              ],
              ['link', 'image', 'video'],
              [{ align: [] }],
              ['clean'],
            ],
          },
          resize: {
            locale: {
              floatLeft: 'Izquierda',
              floatRight: 'Derecha',
              center: 'Centro',
              justify: 'Justificado',
              restore: 'Restaurar',
            },
          },
        }}
        formats={[
          'header',
          'font',
          'size',
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'list',
          'bullet',
          'indent',
          'link',
          'image',
          'video',
          'align',
        ]}
        style={{ height: '90%' }}
        {...props}
      />
      {touched[name] && errors[name] && (
        <div style={{ color: 'red' }}>{errors[name]}</div>
      )}
    </div>
  );
};

export default QuillEditor;
