import React from 'react';

const Loading = () => {
  return (
    <div style={{ textAlign: 'center', paddingTop: '20px' }}>
      <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default Loading;
