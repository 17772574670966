import React, { useEffect, useRef, useState } from 'react';
import { EmailEditor, EmailEditorProps } from 'react-email-editor';
import axiosInstance from '../../../../services/AxiosInstance';

const EmailTemplateCreate = () => {

  const emailEditorRef = useRef(null);

  useEffect(() => {
  }, []);

  const saveDesign = ((design) => {
    console.log(design);
  })

  const exportHtml = () => {
    const unlayer = emailEditorRef.current?.editor;

    unlayer?.exportHtml((data) => {
      const { design, html } = data;
      console.log('exportHtml', html);
      console.log('exportDesign', design);

      // Save the HTML string in your database
      const URL = "/api/v3/mensajeria/email_templates/";
      const postData = {
        'email_html': html,
        'email_json': design
      };
      try {
        axiosInstance.post(URL, postData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {

      }


      alert('Output HTML has been logged in your developer console.');
    });
  };

  const onDesignLoad = (data) => {
    console.log('onDesignLoad', data);
  };

  const onLoad: EmailEditorProps['onLoad'] = (unlayer) => {
    console.log('onLoad', unlayer);
    unlayer.addEventListener('design:loaded', onDesignLoad);
//     unlayer.loadDesign(sample);
  };

  const onReady: EmailEditorProps['onReady'] = (unlayer) => {
    console.log('onReady', unlayer);
  };

  return (
    <div>
      <button className='btn btn-primary' onClick={saveDesign}>Save Design</button>
      <button className='btn btn-primary' onClick={exportHtml}>Export HTML</button>
      <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady} />
    </div>
  );
}

export default EmailTemplateCreate;
