import React, { useState, useEffect } from 'react';
import DatePickerRanger from '../components/DatePickerRanger';
import { BotonPrimary } from './BotonesCustom';
import { formatDate } from '../utils';

const SearchTextWithDateRange = ({ value, onSearchChange, onEnterPress, onClearClick, placeholder, ...propsDatePicker }) => {

  const [showButonClear, setShowButonClear] = useState(false);

  // Dates and filters del recaudo
  const [dateRangeStart, setDateRangeStart] = useState([null, null]);
  const [startDateStart, endDateStart] = dateRangeStart;

  const defaultFilterParams = {
    search: '',
    date_after: '',
    date_before: '',
  }

  const [filterParams, setFilterParams] = useState(defaultFilterParams);

  const showDatePicker = useState(propsDatePicker && propsDatePicker.showDatePicker || false);

  let timeoutId = null;

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const delayDebounceFn = (newFilterParams) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      onSearchChange(newFilterParams);
    }, 900);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setShowButonClear(true);
    const newFilterParams = {
      ...filterParams,
      search: value
    };
    setFilterParams(newFilterParams);
    delayDebounceFn(newFilterParams);
  };

  const handleSearchClick = () => {
    onSearchChange(filterParams);
  };

  const handleClearClick = () => {
    setShowButonClear(false);
    setDateRangeStart([null, null]);

    const newFilterParams = {
      ...defaultFilterParams,
      search: ''
    };
    setFilterParams(newFilterParams);
    onSearchChange(newFilterParams);
  };

  return (
    <div className="row align-items-center">
      <div className="input-group input-group-sm position-relative">
        <input
          autoFocus
          type="text"
          autoComplete='off'
          value={filterParams.search}
          className="form-control form-control-xs"
          placeholder={placeholder || 'Buscar...'}
          onChange={handleInputChange}
        />

        {showDatePicker && (
            <DatePickerRanger
              startDate={startDateStart}
              endDate={endDateStart}
              onChange={(update) => {
                setShowButonClear(true);
                const newFilterParams = {
                  ...filterParams,
                  date_after: update[0] ? formatDate(update[0]) : '',
                  date_before: update[1] ? formatDate(update[1]) : '',
                };
                setDateRangeStart(update);
                setFilterParams(newFilterParams);
                delayDebounceFn(newFilterParams);
              }}
              withPortal
              {...propsDatePicker}
            />
        )}
        <div className="input-group-append p-2">
          <BotonPrimary onClick={handleSearchClick}>
            <i className="fas fa-search" />
          </BotonPrimary>
          {showButonClear && (
            <BotonPrimary onClick={handleClearClick}>
              <i className="fas fa-times" />
            </BotonPrimary>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchTextWithDateRange;
