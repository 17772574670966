import React, { useState, useEffect } from "react";
import axiosInstance from "../../../../services/AxiosInstance";
import { Modal } from "react-bootstrap";
import { BotonDangerText, BotonPrimaryText } from "./BotonesCustom";

const ModalEliminar = ({ isOpen, onClose, url, slug, message }) => {

  const handleConfirmDelete = async () => {
    try {
      await axiosInstance.delete(`${url}${slug}/`);
      onClose();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  }

  return (
    <>
      <Modal show={isOpen} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{message}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Deseas eliminar este elemento?
        </Modal.Body>
        <Modal.Footer>
          <BotonDangerText onClick={onClose}>Cancelar</BotonDangerText>
          <BotonPrimaryText onClick={handleConfirmDelete}>Eliminar</BotonPrimaryText>
        </Modal.Footer>
      </Modal>
    </>
  );

}


export default ModalEliminar
