import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePickerSingle = ({ selectedDate, onChange, placeholderText }) => {
  return (
    <DatePicker
      locale={'es'}
      className="form-control"
      selected={selectedDate}
      onChange={(date) => onChange(date)}
      placeholderText={placeholderText}
      dateFormat="dd/MM/yyyy"
      onKeyDown={(e) => e.preventDefault()}
      isClearable
    />
  );
};

export default DatePickerSingle;
