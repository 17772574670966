import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';  // Importa DatePicker en lugar de DatePickerRanger
import 'react-datepicker/dist/react-datepicker.css';
import { BotonPrimary } from './BotonesCustom';
import { formatDate } from '../utils';

const SearchTextWithDateSingle = ({ value, onSearchChange, onEnterPress, onClearClick, placeholder, ...propsDatePicker }) => {
  const [showButonClear, setShowButonClear] = useState(false);
  const [date, setDate] = useState(null); // Cambia a una sola fecha
  const defaultFilterParams = {
    search: '',
    date: '', // Cambia a un solo campo de fecha
  };
  const [filterParams, setFilterParams] = useState(defaultFilterParams);
  const showDatePicker = useState(propsDatePicker && propsDatePicker.showDatePicker || false);

  let timeoutId = null;

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const delayDebounceFn = (newFilterParams) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      onSearchChange(newFilterParams);
    }, 900);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setShowButonClear(true);
    const newFilterParams = {
      ...filterParams,
      search: value
    };
    setFilterParams(newFilterParams);
    delayDebounceFn(newFilterParams);
  };

  const handleSearchClick = () => {
    onSearchChange(filterParams);
  };

  const handleClearClick = () => {
    setShowButonClear(false);
    setDate(null); // Limpia la fecha seleccionada

    setFilterParams(defaultFilterParams);
    onSearchChange(defaultFilterParams);
  };

  return (
    <div className="row align-items-center">
      <div className="input-group input-group-sm position-relative">
        <input
          autoFocus
          type="text"
          autoComplete="off"
          value={filterParams.search}
          className="form-control form-control-xs"
          placeholder={placeholder || 'Buscar...'}
          onChange={handleInputChange}
        />
        {showDatePicker && (
          <DatePicker
            selected={date}
            className='form-control form-control-xs'
            onChange={(date) => {
              setShowButonClear(true);
              const newFilterParams = {
                ...filterParams,
                date: date ? formatDate(date) : '',
              };
              setDate(date);
              setFilterParams(newFilterParams);
              delayDebounceFn(newFilterParams);
            }}
            dateFormat="dd/MM/yyyy"
            withPortal
            {...propsDatePicker}
          />
        )}
        <div className="input-group-append p-2">
          <BotonPrimary onClick={handleSearchClick}>
            <i className="fas fa-search" />
          </BotonPrimary>
          {showButonClear && (
            <BotonPrimary onClick={handleClearClick}>
              <i className="fas fa-times" />
            </BotonPrimary>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchTextWithDateSingle;
