import React, { useState } from 'react';
import { FieldBasic } from '../components/FieldBasic';
import { Field } from 'formik';

const InputFileBase64 = ({ onChange, value, errors, ...props }) => {
  const [photoPreview, setPhotoPreview] = useState(value || '');

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;
      setPhotoPreview(base64String); // Actualizamos la vista previa
      onChange(base64String); // Llamamos a la función onChange para pasar el base64 al componente padre
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <FieldBasic {...props}>
        <input
          type="file"
          className="form-control"
          onChange={handleFileChange}
          {...props}
        />
        {photoPreview && (
          <img
            src={photoPreview}
            alt="Preview"
            style={{ maxWidth: '100%', maxHeight: '200px', marginTop: '10px' }}
          />
        )}
      </FieldBasic>
    </div>
  );
};

export default InputFileBase64;
