import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import Loading from "../components/loading";
import axiosInstance from "../../../../services/AxiosInstance";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import CustomSelect from "../components/Select";
import DatePicker from 'react-datepicker'; // Asegúrate de importar correctamente
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns'
import { LinkDanger } from "../components/CitoPlusLink";
import { BotonPrimaryText } from "../components/BotonesCustom";
import { notifySuccess } from "../components/CitoPlusToastr";


const formAutorizacionSchema = Yup.object().shape({
  asunto: Yup.string()
    .required("Por favor, ingresa un asunto.")
    .nullable(true),
  nota: Yup.string()
    .required("Por favor, ingresa una nota.")
    .nullable(true),
})


export const FormAutorizaciones = () => {

  const URL = '/api/v3/usuarios/autorizaciones/';
  const URL_VIVIENDAS = '/api/v3/usuarios/citofonos/dropdown/';

  const initialValuesEmpty = {
    vivienda: "",
    asunto: "",
    archivo: null,
    nota: "",
    fecha_limite: null
  }

  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [autorizaciones, setAutorizaciones] = useState([]);

  const [initialValues, setInitialValues] = useState(initialValuesEmpty);

  const params = useParams();

  const navigate = useNavigate();

  const formatProgramacionEnvio = (date) => {

    const parseDate = new Date(date);
    let formattedDate;

    if (isNaN(parseDate.getTime())) {
      formattedDate = format(date, "yyyy-MM-dd HH:mm:ss");
    } else {
      formattedDate = format(parseDate, "yyyy-MM-dd HH:mm:ss");
    }
    return formattedDate;
  };


  // Loading viviendas
  const [viviendasOptions, setViviendasOptions] = useState([]);

  useEffect(() => {
    const fetchViviendaOptions = async () => {
      try {
        const response = await axiosInstance.get(URL_VIVIENDAS);
        const data = await response.data;
        setViviendasOptions(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        //setLoading(false);
      }
    }

    fetchViviendaOptions();
  }, [])

  // Submit
  const handleSubmit = async (values) => {
    setLoadingBtn(true);
    try {
      let formData = {};
      let headers = {};
      if (values.archivo instanceof File) {
        formData = new FormData();
        formData.append('foto', values.archivo);
        formData.append('asunto', values.asunto);
        formData.append('apt', values.vivienda);
        formData.append('nota', values.nota);
        if (values.fecha_limite) {
          formData.append('fecha_limite', formatProgramacionEnvio(values.fecha_limite));
        }
        headers = {
          'Content-Type': 'multipart/form-data'
        }

        headers = {
          'Content-Type': 'multipart/form-data'
        }
      } else {
        formData = {
          asunto: values.asunto,
          apt: values.vivienda,
          nota: values.nota,
          fecha_limite: values.fecha_limite
        }
        headers = {
          'Content-Type': 'application/json'
        }
      }
      const response = await axiosInstance.post(URL, formData, { headers });
      const data = response.data;
      if (data) {
        notifySuccess("Autorización Creada");
        setInitialValues(initialValuesEmpty);
      }
      navigate('/autorizaciones');
    } catch (error) {
      if (error.response) {
        swal("Error!", error.response.data.message, "error");
      } else {
        console.error("Error fetching data:", error);
      }
    } finally {
      setLoadingBtn(false);
    }
  }

  // REMOVER ARCHIVO
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log(selectedFile);
    setFile(selectedFile);
    // Aquí puedes hacer algo con el archivo seleccionado, como enviarlo al servidor
  };

  const handleRemoveFile = (setFieldValue) => {
    setFile(null);
    // eliminar el archivo tambien en el form
    setFieldValue('archivo', null);
  };

  if (loading) {
    return (
      <>
        <div className="d-flex aling-items-center mb-4 flex-wrap">
          <h4 className="f-20 font-w600 me-auto">Agregar Autorización</h4>
        </div>
        <div className="card">
          <div className="card-body">
            <Loading />
          </div>
        </div>

      </>
    );
  }

  return (
    <>
      <div className="d-flex aling-items-center mb-4 flex-wrap">
        <h4 className="f-20 font-w600 me-auto">Agregar Autorización</h4>
      </div>
      <div className="card">
        <div className="card-body">
          <Formik
            initialValues={initialValues}
            validationSchema={formAutorizacionSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="template_id">
                      <label htmlFor="template_id">Vivienda</label>
                    </div>
                    <div className="col-lg-7">
                      <CustomSelect
                        id="vivienda"
                        name="vivienda"
                        options={viviendasOptions}
                        onChange={(selectedOptions) => {
                          setFieldValue('vivienda', selectedOptions ? selectedOptions.value : null);
                        }}
                        defaultsValues={values.vivienda}
                      />
                      {errors.autorizacion && touched.autorizacion ? (
                        <div className="text-danger">{errors.autorizacion}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="asunto">
                      <label>Asunto </label>
                      <span className="text-danger"> * </span>
                    </div>
                    <div className="col-lg-7">
                      <input
                        type="text"
                        className="form-control"
                        name="asunto"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.asunto}
                      />
                      {errors.asunto && touched.asunto ? (
                        <div className="text-danger">{errors.asunto}</div>
                      ) : null}
                    </div>
                  </div>


                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="archivo">
                      <label>Archivo </label>
                    </div>
                    <div className="col-lg-7">
                      {file && (
                        <div>
                          <span>{file.name}</span>
                          <button
                            onClick={(event) => {
                              event.preventDefault();
                              setFile(null);
                              setFieldValue('archivo', null);
                              // Limpiar el valor del input de tipo file
                              const inputFile = document.getElementById('archivo-input');
                              if (inputFile) {
                                inputFile.value = '';
                              }
                            }}
                            className="btn"
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        </div>
                      )}
                      <input
                        id='archivo-input'
                        name="archivo"
                        type="file"
                        className="form-control"
                        onChange={(event) => {
                          const selectedFile = event.target.files[0];
                          setFile(selectedFile);
                          setFieldValue('archivo', selectedFile);
                        }}
                        onBlur={handleBlur}
                      />
                      <div
                        id="val-archivo-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.archivo}
                      </div>
                    </div>
                  </div>


                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="fecha_limite">
                      <label>Fecha de vencimiento </label>
                    </div>
                    <div className="col-lg-7">
                      <DatePicker
                        id="fecha_limite"
                        name="fecha_limite"
                        selected={values.fecha_limite ? new Date(values.fecha_limite) : null}
                        onChange={(date) => setFieldValue('fecha_limite', date)}
                        timeIntervals={15}
                        timeCaption="Hora"
                        className="form-control"
                        dateFormat="dd/MM/yyyy HH:mm"
                        minDate={new Date()}
                        maxDate={new Date(2100, 0, 1)}
                        showTimeSelect
                      />
                      {errors.fecha_limite && touched.fecha_limite ? (
                        <div className="text-danger">{errors.fecha_limite}</div>
                      ) : null}
                    </div>
                  </div>


                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="nota">
                      <label>Nota </label>
                      <span className="text-danger"> * </span>
                    </div>
                    <div className="col-lg-7">
                      <textarea
                        className="form-control"
                        name="nota"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nota}
                      />
                      {errors.nota && touched.nota ? (
                        <div className="text-danger">{errors.nota}</div>
                      ) : null}
                    </div>
                  </div>

                </div>

                {loadingBtn ? (
                  <Loading />
                ) : (
                  <div className="card-footer modal-footer">
                    <LinkDanger
                      to='/envio_masivo_email'
                    >
                      <i className="fas fa-times me-2"></i>
                      Cancelar
                    </LinkDanger>
                    <BotonPrimaryText
                      type="submit"
                      disabled={isSubmitting}>
                      <i className="fas fa-save me-2"></i>
                      Agregar
                    </BotonPrimaryText>
                  </div>
                )}
              </form>
            )}
          </Formik>

        </div>
      </div>

    </>
  );

}

export default FormAutorizaciones;
