import React, { useMemo, useEffect, useState, useCallback } from 'react';
import PageTitle from "../../../layouts/PageTitle";
import CitoPlusSmallModal from '../components/small_modal';
import { useTable, useFilters, usePagination } from 'react-table';
import '../../table/FilteringTable/filtering.css';
import axiosInstance from '../../../../services/AxiosInstance';
import { getDefaultParams } from '../../../../services/DefaultParamets';
import Tables from '../components/tables';
import SearchInput from '../components/input_search';
import { Link } from "react-router-dom";
import ModalEliminar from '../components/ModalEliminar';
import { notifyError } from '../components/CitoPlusToastr';
import { CitoPlusLink, LinkPrimary, CitoPlusLinkIconPrimary, CitoPlusLinkIconDanger } from '../components/CitoPlusLink';

import { ButtonGroup } from 'react-bootstrap';

export const Carteleras = () => {

  const [smallModalTitle, setSmallModalTitle] = useState('');
  const [smallModalBody, setSmallModalBody] = useState('');
  const [smallShowModal, setSmallShowModal] = useState(false);

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');

  const [nextUrl, setNextUrl] = useState([]);
  const [previousUrl, setPreviousUrl] = useState([]);

  const URL = '/api/v3/home/carteleras/';
  const titleCard = "Carteleras"

  const [defaultParams, setDefaultParams] = useState(getDefaultParams);

  const columns = useMemo(() => [
    {
      id: 'created_at',
      Header: 'Creado ',
      disableSortable: false,
      disableFilters: true,
      accessor: (row) => {
        return (
          <div>
            <p>Creado: {row.created_at}</p>
            {row.fecha_limite && <p>Fecha Limite: <strong>{row.fecha_limite}</strong></p>}
          </div>
        );
      },
    },
    {
      id: 'dirigido_a_display',
      Header: 'Publico',
      accessor: 'dirigido_a_display',
      disableFilters: true,
      disableSortable: false,
    },
    {
      id: 'acciones',
      Header: 'Acciones',
      accessor: (row) => (
        <div className="form-group">
          <ButtonGroup aria-label="Botones de acciones cartelera">
            <CitoPlusLinkIconPrimary
              to={`/form-cartelera/${row.slug}`}
            >
              <i className="fas fa-pencil-alt"></i>
            </CitoPlusLinkIconPrimary>
            <CitoPlusLinkIconPrimary
              to={`/cartelera-detalles/${row.slug}`}
            >
              <i className="fas fa-eye"></i>
            </CitoPlusLinkIconPrimary>
            <CitoPlusLinkIconDanger
              href="#"
              onClick={() => handleOpenModalEliminar(row.slug)}
            >
              <i className="fas fa-trash-alt"></i>
            </CitoPlusLinkIconDanger>
          </ButtonGroup>
        </div>
      ),
      disableFilters: true,
      disableSortable: true,
    },
  ], []);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedfetchUrl = useCallback(
    debounce((pageIndex, search) => {
      fetchUrl(pageIndex, search);
    }, 500), // El valor 500 es el tiempo de espera en milisegundos
    []
  );

  useEffect(() => {
    fetchUrl(1);
  }, []);


  const fetchUrl = async (pageIndex = 1, search = '', ordering = []) => {
    defaultParams.page = pageIndex;
    defaultParams.search = search;
    setLoading(true);
    try {
      if (pageIndex < 1 || pageIndex > totalPages) {
        notifyError('Página no encontrada');
        return;
      }
      const response = await axiosInstance.get(
        URL,
        {
          params: {
            ...defaultParams,
            page: pageIndex,
            search: search,
            ordering: ordering
          }
        }
      );
      setNextUrl(response.data.next);
      setPreviousUrl(response.data.previous);
      setTotalPages(response.data.total_pages);
      setCurrentPage(response.data.current_page);
      setData(response.data.results);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 404) {
        setSmallModalTitle('Título del Modal');
        setSmallModalBody('Contenido del Modal');
        setSmallShowModal(true);
        console.log("Página no encontrada");
      } else {
        console.error('Error fetching data:', error);
      };
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
    setLoading(true);
    debouncedfetchUrl(1, value);
  };

  const handleEnterPress = () => {
    // Lógica para manejar la tecla Enter, si es necesario
    console.log('Presionó Enter');
  };

  const handleClearClick = () => {
    handleSearchChange('');
  };

  const handleSortingChange = (column) => {
    const { id } = column;

    const newOrder = sortField === id ? (order === 'asc' ? 'desc' : 'asc') : 'asc';

    setSortField(id);
    setOrder(newOrder);
    setLoading(true);
    fetchUrl(1, searchValue, `${newOrder === 'desc' ? '-' : ''}${id}`);
  };

  const handleOpenModal = () => {
    setSmallShowModal(true);
  };

  const handleCloseModal = () => {
    setSmallShowModal(false);
  };

  // Modal Eliminacion
  const [modalEliminarOpen, setModalEliminarOpen] = useState(false);
  const [slugEliminar, setSlugToEliminar] = useState(null);

  const handleOpenModalEliminar = (slug) => {
    setSlugToEliminar(slug);
    setModalEliminarOpen(true);
  };

  const handleCloseModalEliminar = () => {
    fetchUrl(1, searchValue, `${order === 'desc' ? '-' : ''}${sortField}`);
    setModalEliminarOpen(false);
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
    },
    useFilters,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page
  } = tableInstance;

  return (
    <>
      <div className='d-flex align-items-center mb-4 flex-wrap'>
        <h4 className="f-20 font-w600 me-auto">Carteleras</h4>
        <LinkPrimary
          to={'/form-cartelera/'}
        >
          <i className='fas fa-plus me-2'></i>
          Crear Cartelera
        </LinkPrimary>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="card-tools">
            <SearchInput
              value={searchValue}
              onSearchChange={handleSearchChange}
              onEnterPress={handleEnterPress}
              onClearClick={handleClearClick}
            />
          </div>
        </div>
        <div className="card-body">
          {loading ? (
            <p>Cargando...</p>
          ) : data.length === 0 ? (
            <p>No hay datos disponibles.</p>
          ) : (
            <Tables
              columns={columns}
              data={data}
              loading={loading}
              totalPages={totalPages}
              currentPage={currentPage}
              fetchUrl={fetchUrl}
              handleSortingChange={handleSortingChange}
              nextUrl={nextUrl}
              previousUrl={previousUrl}
              sortField={sortField}
              order={order}
              page={page}
            />
          )}
        </div>
      </div>

      <div>
        {/* Resto del contenido del componente */}

        {smallShowModal && (
          <CitoPlusSmallModal
            title={smallModalTitle}
            body={smallModalBody}
            show_modal={smallShowModal}
            onHide={handleCloseModal}
          // onClose={() => setSmallShowModal(false)}
          />
        )}
      </div>

      {/* Modal de confirmación */}
      <ModalEliminar
        isOpen={modalEliminarOpen}
        onClose={handleCloseModalEliminar}
        url={URL}
        slug={slugEliminar}
        message={"¿Deseas eliminar esta Cartelera?"}
      />

    </>
  );
};

export default Carteleras;
