import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import Loading from "../components/loading";
import axiosInstance from "../../../../services/AxiosInstance";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate } from 'react-router-dom';
import CustomClearIndicator from "../components/MultiSelect";
import { handleErrors } from "../components/ErrorHandlingComponent";
import { notifySuccess } from "../components/CitoPlusToastr";


const formDocumentosSchema = Yup.object().shape({

})


export const FormDocumentos = () => {

  const initialValuesEmpty = {
    archivo: null,
    titulo: "",
    grupos: [],
  }

  var URL = "/api/v3/mensajeria/mensajeria_email/";
  const URL_GRUPOS = '/api/v3/usuarios/grupos_copropiedades/dropdown/';

  const { slug } = useParams();
  const navigate = useNavigate();
  const [documentos, setDocumentos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const [initialValues, setInitialValues] = useState(initialValuesEmpty);

  // DROPDOWN GRUPOS
  const [gruposOptions, setGruposOptions] = useState([]);

  useEffect(() => {
    const fetchGrupOptions = async () => {
      try {
        const response = await axiosInstance.get(URL_GRUPOS);
        const data = await response.data;
        setGruposOptions(data);
      } catch (error) {
        handleErrors(error);
      } finally {
        setLoading(false);
      }
    }

    fetchGrupOptions();

  }, [])

  // SUBMIT
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const formData = new FormData();
      formData.append('file', values.archivo);
      formData.append('titulo', values.titulo);
      formData.append(
        'grupos_slugs',
        JSON.stringify(values.grupos.map(grupo => ({ slug: grupo.slug, name: grupo.name })))
      );
      const response = await axiosInstance.post('/api/v3/usuarios/documentos/', formData);
      setSubmitting(false);
      notifySuccess("Documentos cargados correctamente");
      navigate('/documentos');
    } catch (error) {
      handleErrors(error, setSubmitting);
    } finally {
      setSubmitting(false);
    }
  }

  // Archivo
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log(selectedFile);
    setFile(selectedFile);
    // Aquí puedes hacer algo con el archivo seleccionado, como enviarlo al servidor
  };

  const handleRemoveFile = (setFieldValue) => {
    setFile(null);
    // eliminar el archivo tambien en el form
    setFieldValue('archivo', null);
  };


  if (loading) {
    return (
      <>
        <PageTitle activeMenu={"Agregar Documento"} motherMenu={"Gestion ADM"} />
        <div className="d-flex aling-items-center mb-4 flex-wrap">
          <h4 className="f-20 font-w600 me-auto">Agregar Documento</h4>
        </div>
        <div className="card">
          <div className="card-body">
            <Loading />
          </div>
        </div>

      </>
    );
  }

  return (
    <>
      <div className="d-flex aling-items-center mb-4 flex-wrap">
        <h4 className="f-20 font-w600 me-auto">Agregar Documento</h4>
      </div>
      <div className="card">
        <div className="card-body">

          <Formik
            initialValues={initialValues}
            validationSchema={formDocumentosSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              setFieldTouched
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="titulo">
                      <label>Título </label>
                      <span className="text-danger"> * </span>
                    </div>
                    <div className="col-lg-7">
                      <input
                        type="text"
                        name="titulo"
                        id="titulo"
                        className="form-control"
                        value={values.titulo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div
                      id="val-titulo-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.titulo}
                    </div>
                  </div>

                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="grupo">
                      <label>Grupo </label>
                    </div>
                    <div className="col-lg-7">
                      <CustomClearIndicator
                        id="grupos"
                        name="grupos"
                        options={gruposOptions}
                        isMulti={true}
                        onChange={(selectOptions) => {
                          setFieldValue("grupos", selectOptions.map((option) => {
                            let { value, label } = option;
                            return {
                              slug: value,
                              nombre: label
                            }
                          }));
                        }}
                        defaultsValues={values.grupos}
                      />
                    </div>
                    <div
                      id="val-grupos-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.grupos}
                    </div>
                  </div>

                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="archivo">
                      <label>Archivo </label>
                      <span className="text-danger"> * </span>
                    </div>
                    <div className="col-lg-7">
                      {file && (
                        <div>
                          <span>{file.name}</span>
                          <button
                            onClick={(event) => {
                              event.preventDefault();
                              setFile(null);
                              setFieldValue('archivo', null);
                              // Limpiar el valor del input de tipo file
                              const inputFile = document.getElementById('archivo-input');
                              if (inputFile) {
                                inputFile.value = '';
                              }
                            }}
                            className="btn"
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        </div>
                      )}
                      <input
                        id='archivo-input'
                        name="archivo"
                        type="file"
                        className="form-control"
                        onChange={(event) => {
                          const selectedFile = event.target.files[0];
                          setFile(selectedFile);
                          setFieldValue('archivo', selectedFile);
                        }}
                        onBlur={handleBlur}
                      />
                      <div
                        id="val-archivo-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.archivo}
                      </div>
                    </div>
                  </div>

                  {loadingBtn ? (
                    <Loading />
                  ) : (
                    <div className="">
                      <Link to='/envio_masivo_email' className="btn btn-danger">
                        Cancelar
                      </Link>
                      <button
                        type="submit"
                        className="btn btn-success"
                        disabled={isSubmitting}>
                        Agregar
                      </button>
                    </div>
                  )}

                </div>
              </form>
            )}
          </Formik>

        </div>
      </div>
    </>
  )

}

export default FormDocumentos;
