import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../services/AxiosInstance';
import { handleErrors } from '../CitoPlus/components/ErrorHandlingComponent';

// Images
import pic1 from './../../../images/registro_visitante_transparente.png';
import pic2 from './../../../images/vigilantes/ingreso_vehicular_transparente.png';
import pic3 from './../../../images/vigilantes/ingreso_mensajeria_5_transparente.png';
import pic4 from './../../../images/vigilantes/parqueaderos_residentes_transparente.png';
import pic5 from './../../../images/vigilantes/ingreso_minuta_transparente.png';

const CardsBoxed = [
  { image: pic1, title: 'Visitantes', url: '/visitantes-peatonales', key: 'visitantes' },
  { image: pic2, title: 'Parqueaderos', url: '/visitantes-vehiculares', key: 'parqueaderos' },
  { image: pic3, title: 'Correspondencia', url: '/correspondencia', key: 'correspondencias' },
  { image: pic4, title: 'Autorizaciones', url: '', key: 'autorizaciones' },
  { image: pic5, title: 'Minutas', url: '/minuta', key: 'minutas' },
];

const ListBoxed = [
  { image: pic1, title: 'Visitantes', url: '/visitantes-peatonales', textBotonVer: 'Ver Visitantes', textBotonEditar: 'Ingresar Visitantes', key: 'visitantes' },
  { image: pic2, title: 'Parqueaderos', url: '/visitantes-vehiculares', textBotonVer: 'Ver Parqueaderos', textBotonEditar: 'Ingresar Parqueaderos', key: 'parqueaderos' },
  { image: pic3, title: 'Correspondencia', url: '/correspondencia', textBotonVer: 'Ver Correspondencia', textBotonEditar: 'Ingresar Correspondencia', key: 'correspondencias' },
  { image: pic4, title: 'Autorizaciones', url: '', textBotonVer: 'Ver Autorizaciones', textBotonEditar: 'Ingresar Autorizaciones', key: 'autorizaciones' },
  { image: pic5, title: 'Minutas', url: '/minuta', textBotonVer: 'Ver Minutas', textBotonEditar: 'Ingresar Minutas', key: 'minutas' },
];

const VigilantesResumen = () => {
  const navigate = useNavigate();
  const URL = "/api/v3/vigilancia/dashboard/";

  // Estado para los contadores
  const [counters, setCounters] = useState({
    minutas: 0,
    correspondencias: 0,
    visitantes: 0,
    parqueaderos: 0,
    autorizaciones: 0
  });

  useEffect(() => {
    axiosInstance
      .get(URL)
      .then(res => {
        setCounters({
          minutas: res.data.total_minutas,
          correspondencias: res.data.total_correspondencias,
          visitantes: res.data.total_visitantes,
          parqueaderos: res.data.total_parking,
          autorizaciones: res.data.total_autorizaciones
        });
      })
      .catch(err => {
        handleErrors(err);
      });
  }, []);

  return (
    <Tab.Container defaultActiveKey="Boxed">
      <div className="mt-4 d-flex justify-content-between align-items-center flex-wrap">
        <div className="mb-4">
          <h5>Resumen</h5>
        </div>
        <div className="d-flex align-items-right mb-4">
          <div className="default-tab job-tabs">
            <Nav as="ul" className="nav nav-tabs" role="tablist">
              <Nav.Item as="li">
                <Nav.Link eventKey="Boxed">
                  <i className="fas fa-th-large"></i>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="List1">
                  <i className="fas fa-list"></i>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
      </div>
      <Tab.Content>
        <Tab.Pane eventKey="Boxed">
          <div className="row">
            {CardsBoxed.map((data, index) => (
              <div className="col-xl-2 col-xxl-4  col-md-4 col-sm-6" key={index}>
                <div className="card" onClick={() => navigate(data.url)} style={{ cursor: 'pointer' }}>
                  <div className="jobs2 card-body text-center">
                    <div className="text-center">
                      <div className='background-image-card-vigilante' style={{ backgroundImage: `url(${data.image})` }}>
                        <div className='overlay'>
                          <span className='number-overlay'>
                            {counters[data.key]}
                          </span>
                        </div>
                      </div>
                      <h4 className="mb-0"><Link to={"#"}>{data.title}</Link></h4>
                    </div>
                    <div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Tab.Pane>
        <Tab.Pane eventKey="List1">
          <div className="row">
            <div className="col-xl-12">
              {ListBoxed.map((item, index) => (
                <div className="d-flex flex-wrap search-row bg-white p-3 mb-3 rounded justify-content-between align-items-center" key={index}>
                  <div className="d-flex col-xl-3 col-xxl-4 col-lg-4 col-sm-6 align-items-center">
                    <img src={item.image} alt="" className="me-3" height={'55'} width={'55'} />
                    <div>
                      <h2 className="title"><Link to={"#"} className="text-black">{item.title}</Link></h2>
                      <span className="text-primary">{counters[item.key]}</span>
                    </div>
                  </div>
                  <div className="d-flex col-xl-7 col-xxl-6 col-lg-6 col-sm-6 align-items-center">
                    <div>
                      <h4 className="sub-title text-black">{counters[item.key]}</h4>
                      <span>Total</span>
                    </div>
                  </div>
                  <div className="col-xl-2 col-xxl-12 text-xl-right text-lg-left text-sm-right col-lg-12 col-sm-6 mt-2">
                    <Link to={item.url} className="btn btn-sm btn-outline-primary rounded me-2">{item.textBotonVer}</Link>
                    <Link to={item.url} className="btn btn-sm btn-outline-primary rounded me-2">{item.textBotonEditar}</Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  )
}

export default VigilantesResumen;
