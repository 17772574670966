import React, { useState } from 'react';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import axiosInstance from '../../../../services/AxiosInstance';


const PQRDropdownEstados = ({ estadoInicial, indice, url, className }) => {
  const [estado, setEstado] = useState(estadoInicial);

  const handleDropdownChange = async (id_esatdo, nuevoEstado) => {
    try {
      // Realizar una solicitud PATCH al endpoint correspondiente para actualizar el estado
      await axiosInstance.patch(url, {
        estado: id_esatdo,
      });

      // Si la solicitud PATCH es exitosa, actualizar el estado del Dropdown en la interfaz de usuario
      setEstado(nuevoEstado);
    } catch (error) {
      console.error('Error al actualizar el estado del elemento:', error);
    }
  };

  const getVariantByEstado = (estados) => {
    switch (estado) {
      case 'ABIERTO':
        return 'citoplus-warning';
      case 'CERRADO':
        return 'citoplus-success';
      case 'EN PROCESO':
        return 'citoplus-secondary';
      default:
        return 'citoplus-info';
    }
  };

  return (
    <>
      <DropdownButton
        as={ButtonGroup}
        title={estado}
        id='dropdown-basic'
        variant={getVariantByEstado()}
        size="xs">
        <h5 className="dropdown-header" style={{ fontWeight: 'bold' }}>Cambio de Estado</h5>
        <Dropdown.Item onClick={() => handleDropdownChange(6, 'ABIERTO')} >ABIERTO</Dropdown.Item>
        <Dropdown.Item onClick={() => handleDropdownChange(7, 'CERRADO')} >CERRADO</Dropdown.Item>
      </DropdownButton>

    </>
  );
};

export default PQRDropdownEstados;
