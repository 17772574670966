import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Loading from "../../components/loading";
import { BotonPrimaryText, BotonDangerText } from "../../components/BotonesCustom";
import { FieldBasic } from "../../components/FieldBasic";
import axiosInstance from "../../../../../services/AxiosInstance";
import { useVigilanteReloadContext } from "../../../../../context/VisitantesVigilantesContext";
import SignaturePad from 'react-signature-pad-wrapper';
import {  notifySuccess, notifyError } from "../../components/CitoPlusToastr";


const formSchema = Yup.object().shape({

  nota_salida: Yup.string()
    .nullable(true),

  recibido_nombre: Yup.string()
    .nullable(true),

  recibido_firma: Yup.string()
    .nullable(true),

});

const CorrespondenciaSalida = ({ uuidCorrespondencia, showModal, onClose }) => {
  const initialValuesEmpty = {
    nota_salida: null,
    recibido_nombre: null,
    recibido_firma: null,
    estado: "2"
  };

  const URL = `/api/v3/correspondencia/vigilantes/${uuidCorrespondencia}/`;

  const { setReloadData } = useVigilanteReloadContext();

  const [initialValues, setInitialValues] = useState(initialValuesEmpty);
  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const signaturePadRef = useRef(null);

  useEffect(() => {
    if (!showModal) {
      setInitialValues(initialValuesEmpty);
    }
  }, [showModal]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const postData = {
      ...values,
      recibido_firma: signaturePadRef.current.toDataURL() // Obtener la firma como Data URL
    };

    try {
      setLoadingSubmit(true);
      await axiosInstance.patch(URL, postData);
      setInitialValues(initialValuesEmpty);
      notifySuccess("✔️ La salida fue exitosa!");
      onClose();
      resetForm();
      setReloadData(prev => ({ ...prev, correspondencia: true }));
    } catch (error) {
      handleErrorResponse(error);
    } finally {
      setSubmitting(false);
      setLoadingSubmit(false);
    }
  };

  const handleClearForm = (resetForm) => {
    resetForm();
    setInitialValues(initialValuesEmpty);
    signaturePadRef.current.clear();
  };

  function handleErrorResponse(error) {
    let errorMessages = [];

    if (error.response && error.response.data) {
      const errorData = error.response.data;

      if (errorData.type === "validation_error" && Array.isArray(errorData.errors)) {
        errorData.errors.forEach(err => {
          const fieldMessage = `${err.attr}: ${err.detail}`;
          errorMessages.push(fieldMessage);
        });
      } else if (errorData.message) {
        errorMessages.push(errorData.message);
      } else {
        errorMessages.push("An unexpected error occurred.");
      }
    } else {
      errorMessages.push(error.message || "An unexpected error occurred.");
    }

    const errorMessage = errorMessages.join("\n");

    notifyError(`Error: ${errorMessage}`);
  }

  return (
    <Modal show={showModal} size="md" onHide={onClose}>
      {loadingForm ? (
        <Loading />
      ) : (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            resetForm
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h5 className="modal-title">Salida Correspondencia</h5>
                <button type="button" className="btn-close" onClick={onClose}></button>
              </div>
              <div className="modal-body">
                <div className="row justify-content-center">
                  <div className="col-md">
                    <div className="form-group pb-2">
                      <FieldBasic
                        name="Nombre"
                        required
                        errors={errors.recibido_nombre}
                      >
                        <Field
                          type="text"
                          name="recibido_nombre"
                          id="recibido_nombre"
                          autoComplete="off"
                          className="form-control"
                          placeholder="Nombre"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.recibido_nombre}
                        />
                      </FieldBasic>
                    </div>
                    <div className="form-group pb-2">
                      <FieldBasic
                        name="nota_salida"
                        required
                        errors={errors.nota_salida}
                      >
                        <Field
                          type="text"
                          name="nota_salida"
                          id="nota_salida"
                          autoComplete="off"
                          className="form-control"
                          placeholder="Nota de Salida"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.nota_salida}
                        />
                      </FieldBasic>
                    </div>
                    <div className="form-group pb-2" style={{ textAlign: 'center' }}>
                      <label htmlFor="signature" style={{ textAlign: 'center', padding: '10px' }}>
                        Firma
                      </label>
                      <div style={{ textAlign: 'center', border: '1px solid black', padding: '10px' }}>
                        <SignaturePad
                          ref={signaturePadRef}
                          options={{ minWidth: 1, maxWidth: 1, penColor: "black" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {loadingSubmit ? (
                  <div className="text-center">
                    <Loading />
                  </div>
                ) : (
                  <>
                    <BotonDangerText
                      type="button"
                      onClick={onClose}
                    >
                      Cancelar
                    </BotonDangerText>
                    <BotonDangerText
                      type="button"
                      onClick={() => handleClearForm(resetForm)}
                      disabled={isSubmitting}
                    >
                      Limpiar
                    </BotonDangerText>
                    <BotonPrimaryText
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Guardar
                    </BotonPrimaryText>
                  </>
                )}
              </div>
            </form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default CorrespondenciaSalida;
