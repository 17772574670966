import React, { useState, useContext } from "react";
import { Nav, Tab } from 'react-bootstrap';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import Select from "react-select";
import { ThemeContext } from "../../../../../context/ThemeContext";

import registro_visitas from '../../../../../images/registro_visitante_transparente.png';
import ingreso_vehicular from '../../../../../images/vigilantes/ingreso_vehicular_transparente.png';
import ingreso_mensajeria from '../../../../../images/vigilantes/ingreso_mensajeria_5_transparente.png';
import imagen_parqueaderos from '../../../../../images/vigilantes/parqueaderos_residentes_transparente.png';
import imagen_minuta from '../../../../../images/vigilantes/ingreso_minuta_transparente.png';

import IngresoVisitantes from './IngresoVisitantes';
import IngresoVehicular from './IngresoVehicular';
import CorrespondenciaForm from './CorrespondenciaIngreso';
import MinutaForm from './MinutaForm';

const SidbarForms = () => {
  const [sideBarForms, setSideBarforms] = useState(false);

  const [showModalIngresoVisitantes, setShowModalIngresoVisitantes] = useState(false);
  const handleShowIngresoVisitantes = function () {
    setShowModalIngresoVisitantes(true);
    setSideBarforms(!sideBarForms);
  }

  const handleCloseIngresoVisitantes = () => setShowModalIngresoVisitantes(false);

  // Ingreso Vehicular Modal
  const [showModalIngresoVehicular, setShowModalIngresoVehicular] = useState(false);
  const handleShowIngresoVehicular = function () {
    setShowModalIngresoVehicular(true);
    setSideBarforms(!sideBarForms);
  }
  const handleCloseIngresoVehicular = () => setShowModalIngresoVehicular(false);

  // Correspondencia Modal
  const [showModalCorrespondencia, setShowModalCorrespondencia] = useState(false);
  const handleShowCorrespondencia = function () {
    setShowModalCorrespondencia(true);
    setSideBarforms(!sideBarForms);
  }
  const handleCloseCorrespondencia = () => setShowModalCorrespondencia(false);

  // Minuta Modal
  const [showModalMinuta, setShowModalMinuta] = useState(false);
  const handleShowMinuta = function () {
    setShowModalMinuta(true);
    setSideBarforms(!sideBarForms);
  }
  const handleCloseMinuta = () => setShowModalMinuta(false);

  return (
    <>
      <div className={`dlab-demo-panel ${sideBarForms ? "show" : ""}`}>
        <div className="bg-overlay" onClick={() => setSideBarforms(!sideBarForms)}></div>
        <div className="bg-close" onClick={() => setSideBarforms(!sideBarForms)} ></div>
        <Link to="#" className="dlab-demo-trigger" onClick={() => setSideBarforms(!sideBarForms)}>
          <span>
            <i className="las la-sliders-h la-1x"></i>
          </span>
        </Link>
        <div className="dlab-demo-inner">
          <div className="dlab-demo-header">
            <h4>¿Qué le gustaría hacer?</h4>
            <Link to={"#"} className="dlab-demo-close" onClick={() => setSideBarforms(!sideBarForms)}>
              <span><i className="las la-times"></i></span>
            </Link>
          </div>
          <PerfectScrollbar className="dlab-demo-content ps ps--active-y">
            <div className="container"><div className="row">
              <div className="dlab-wrapper col" onClick={handleShowIngresoVisitantes} style={{ cursor: 'pointer' }}>
                <div className="">
                  <div className="">
                    <img src={registro_visitas} alt="" className="w-100" />
                  </div>
                </div>
                <h7 className="text-black">Ingreso Peatonal</h7>
              </div>
              <div className="dlab-wrapper col" onClick={handleShowIngresoVehicular} style={{ cursor: 'pointer' }}>
                <div className="">
                  <div className="">
                    <img src={ingreso_vehicular} alt="" className="w-100" />
                  </div>
                </div>
                <h7 className="text-black">Ingreso Vehicular</h7>

              </div>
            </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="dlab-wrapper col" onClick={handleShowCorrespondencia} style={{ cursor: 'pointer' }}>
                  <div className="">
                    <div className="">
                      <img src={ingreso_mensajeria} alt="" className="w-100" />
                    </div>
                  </div>
                  <h7 className="text-black">Correspondencia</h7>
                  <hr />
                </div>
                <div className="dlab-wrapper col" onClick={handleShowMinuta} style={{ cursor: 'pointer' }}>
                  <div className="">
                    <div className="">
                      <img src={imagen_minuta} alt="" className="w-75" />
                    </div>
                  </div>
                  <h7 className="text-black">Ingresar Minuta</h7>
                  <hr />

                </div>
              </div>
            </div>
            {/*
            <div className="container"><div className="row">
              <div className="dlab-wrapper col" onClick="" style={{ cursor: 'pointer' }}>
                <div className="">
                  <div className=""><img src={imagen_parqueaderos} alt="" className="w-100" /></div>
                </div>
                <h7 className="text-black">Parqueaderos Residentes</h7>
                <hr />
              </div>
              <div className="dlab-wrapper col"></div>
            </div>
          </div>
          */}
          </PerfectScrollbar>
          <div className="fs-12 pt-3">
            <span className="text-danger">*Nota :</span> Seleccione la accion a tomar
          </div>
        </div>
      </div>

      <IngresoVisitantes
        showModal={showModalIngresoVisitantes}
        onClose={handleCloseIngresoVisitantes}
      />

      <IngresoVehicular
        showModal={showModalIngresoVehicular}
        onClose={handleCloseIngresoVehicular}
      />

      <CorrespondenciaForm
        showModal={showModalCorrespondencia}
        onClose={handleCloseCorrespondencia}
      />

      <MinutaForm
        showModal={showModalMinuta}
        onClose={handleCloseMinuta}
      />

    </>
  );
};

export default SidbarForms;


