import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import axiosInstance from '../../../services/AxiosInstance';
import { useNavigate } from 'react-router-dom';

import noImagen from '../../../images/no-image.jpg';

const VigilanteCierreCaja = () =>{

  const URL = "/api/v3/vigilancia/cajas/open/";
  const CERRAR_CAJA = "/api/v3/vigilancia/cajas/cerrar/"

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [caja, setCaja] = useState({
    "nombre_vigilante": "",
    "foto": "",
    "total_actual": 0,
    "open_at": "",
  });

  useEffect(() => {
    fetchCajas();
  }, []);

  const fetchCajas = async () => {
    try {
      const response = await axiosInstance.get(URL);
      setCaja(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const cerrarCaja = async () => {
    try {
      const response = await axiosInstance.get(CERRAR_CAJA);
      setCaja(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCerrarCaja = async () => {
    setLoading(true);
    try {
      const response = await cerrarCaja();
      setLoading(false);
      navigate("/cajas")
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

	return(
		<>
			<div className="card">
				<div className="card-header bg-blue">
				</div>
				<div className="card-body">
          <span className="text-center d-block block">
            <img src={caja.foto || noImagen} alt=""  width="141" height="141"/>
					</span>
					<h4 className="fs-20 mb-0 text-center">{caja.nombre_vigilante || '-'}</h4>
					<span className="text-primary mb-3 d-block text-center">Caja Vehicular</span>

					<div>
						<span className="fs-36 mb-3 d-flex justify-content-center">$ {caja.total_actual || 0} </span>
					</div>
				</div>
				<div className="card-footer border-0 pt-0">
					<div className="d-flex justify-content-between align-items-center">
            <Link
              to={"/cajas"}
              className="btn btn-primary btn-rounded"
            >
              VER
            </Link>
						<Link
              to={"#"}
              className="btn btn-primary btn-rounded"
              onClick={handleCerrarCaja}
            >
              CERRAR CAJA
            </Link>
					</div>
				</div>
			</div>
		</>
	)
}
export default VigilanteCierreCaja;
