import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";

import logo from "../../../images/logos_citoplus_only_logo.png";
import logoOnlyText from "../../../images/logos_citoplus_only_text.png";

import logoBlanco from "../../../images/logos_citoplus_only_logo_blanco.png";
import logoBlancoOnlyText from "../../../images/logos_citoplus_only_text_blanco.png";

export function NavMenuToggle() {
  setTimeout(() => {
    let mainwrapper = document.querySelector("#main-wrapper");
    if (mainwrapper.classList.contains('menu-toggle')) {
      mainwrapper.classList.remove("menu-toggle");
    } else {
      mainwrapper.classList.add("menu-toggle");
    }
  }, 200);
}

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } = useContext(
    ThemeContext
  );
  return (
    <div className="nav-header">
      <Link to="/dashboard" className="brand-logo">
        {background.value === "dark" || navigationHader !== "color_1" ? (
          <Fragment>
            <img src={logoBlanco} alt="CitoPlus Admin" width={"90%"} className="logo-abbr" />
            <img src={logoBlancoOnlyText} alt="" width={"90%"} className="brand-title" />
          </Fragment>
        ) : (
          <Fragment>
            <img src={logo} alt="CitoPlus Admin" width={"90%"} className="logo-abbr"/>
            <img src={logoOnlyText} alt="" width={"90%"} className="brand-title"/>
          </Fragment>
        )}
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          NavMenuToggle()
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
