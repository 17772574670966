import React, { useMemo, useEffect, useState, useCallback } from 'react';
import PageTitle from "../../../layouts/PageTitle";
import CitoPlusSmallModal from '../components/small_modal';
import { useTable, useFilters, usePagination } from 'react-table';
import '../../table/FilteringTable/filtering.css';
import axiosInstance from '../../../../services/AxiosInstance';
import { getDefaultParams } from '../../../../services/DefaultParamets';
import Tables from '../components/tables';
import SearchTextWithDateRange from '../components/SearchWithDateRange';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import { notifySuccess, notifyError } from '../components/CitoPlusToastr';
import { CitoPlusLinkIconDanger, CitoPlusLinkIconPrimary, CitoPlusLinkIconSecondary, LinkPrimary } from '../components/CitoPlusLink';

import { ButtonGroup } from 'react-bootstrap';
import swal from 'sweetalert';


export const EnvioMasivoCorreo = () => {

  const [smallModalTitle, setSmallModalTitle] = useState('');
  const [smallModalBody, setSmallModalBody] = useState('');
  const [smallShowModal, setSmallShowModal] = useState(false);

  const [loading, setLoading] = useState(true);
  const [textLoading, setTextLoading] = useState('Cargando...');

  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // para cambiar
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');

  const [nextUrl, setNextUrl] = useState([]);
  const [previousUrl, setPreviousUrl] = useState([]);

  // Filtro por fecha
  const [dateRangeStart, setDateRangeStart] = useState([null, null]);
  const [startDateStart, endDateStart] = dateRangeStart;

  const URL = '/api/v3/mensajeria/mensajeria_email/';
  const titleCard = "Correos"

  const [defaultParams, setDefaultParams] = useState(getDefaultParams);

  const columns = useMemo(() => [
    {
      id: "created_at",
      Header: "Creado",
      disableFilters: true,
      disableSortable: false,
      accessor: (row) => {
        return <div>
          <p>{row.created_at}</p>
          {row.programacion_envio && <p>Programada: {row.programacion_envio}</p>}
          <p></p>
        </div>
      },
    },
    {
      id: 'titulo',
      Header: 'Asunto  ',
      accessor: (row) => {
        return <div>
          <p>{row.titulo}</p>
          {row.citofonos.length > 0 ? <p>Citofonos: {row.citofonos.length}</p> : null}
          <p></p>
        </div>
      },
      disableFilters: true,
      disableSortable: false
    },
    {
      id: 'todos',
      Header: 'Todos',
      disableFilters: true,
      disableSortable: false,
      accessor: (row) => {
        return <div>
          {row.todos}
          <div className="left-align-cell">
            Residentes: {row.todos ? <i className="fa fa-user-check" style={{ color: 'green' }} /> : <i className="fa fa-user-xmark" style={{ color: 'red' }} />}
          </div>
          <div className="left-align-cell">
          Propietarios: {row.todos_propietarios ? <i className="fa fa-user-check" style={{ color: 'green' }} /> : <i className="fa fa-user-xmark" style={{ color: 'red' }} />}
          </div>
        </div>
      },
    },
    {
      Header: 'Detalles',
      accessor: 'id',
      Cell: ({ row }) => (
        <div className='left-align-cell'>
          <p className="paragraph">Fallo: {row.original.fallo}</p>
          <p className="paragraph">Envío: {row.original.envio}</p>
          <p className="paragraph">Abiertos: {row.original.abiertos}</p>
          <p className="paragraph" style={{ paddingTop: '10px' }}>
            <Badge bg="primary">{row.original.estado}</Badge>
          </p>
        </div>
      ),
      disableFilters: true,
      disableSortable: true
    },
    {
      id: 'grupo',
      Header: 'Grupos',
      accessor: (row) => {
        // Verifica si 'grupo' es un array y tiene al menos un elemento
        if (Array.isArray(row.grupo) && row.grupo.length > 0) {
          // Mapea los nombres de los grupos y devuelve una lista
          const groupNames = row.grupo.map((group) => group.nombre);
          return <ul>{groupNames.map((name, index) => <li key={index}>{index + 1}: {name}</li>)}</ul>;
        } else {
          return '';
        }
      },
      disableFilters: true,
      disableSortable: false
    },
    {
      id: 'acciones',
      Header: 'Acciones',
      disableFilters: true,
      disableSortable: true,
      accessor: (row) => {
        return <div>
          <ButtonGroup aria-label="Botones de acciones email">
            {row.estado !== 'TERMINADA' && (
                <CitoPlusLinkIconPrimary
                  to={`/create_email_masivo/${row.slug}`}
                ><i className="fas fa-pencil-alt"></i></CitoPlusLinkIconPrimary>
              )
            }
            <CitoPlusLinkIconPrimary
              to={`/email-detail/${row.slug}`}
            >
              <i className="fas fa-eye"></i>
            </CitoPlusLinkIconPrimary>
            <CitoPlusLinkIconSecondary
              to={`/create_email_masivo/${row.slug}/reenviar`}
              title="Reenviar"
              // onClick={() => handleCloneEmail(row.slug)}
            >
              <i className="fas fa-clone"></i>
            </CitoPlusLinkIconSecondary>
            {row.estado !== 'TERMINADA' && (
              <CitoPlusLinkIconDanger
                to="#"
                title="Borrar"
                onClick={() => handleDeleteEmail(row.slug)}
              >
                <i className="fas fa-trash"></i>
              </CitoPlusLinkIconDanger>
            )}
          </ButtonGroup>
        </div>
      },
    }
  ], []);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedfetchUrl = useCallback(
    debounce((pageIndex, filterParams) => {
      fetchUrl(pageIndex, filterParams);
    }, 500), // El valor 500 es el tiempo de espera en milisegundos
    []
  );

  useEffect(() => {
    fetchUrl(1);
  }, []);


  const fetchUrl = async (pageIndex = 1, filterParams, ordering = []) => {
    defaultParams.page = pageIndex;
    setLoading(true);

    try {
      if (pageIndex < 1 || pageIndex > totalPages) {
        notifyError('Página no encontrada');
        return;
      }
      const response = await axiosInstance.get(
        URL,
        {
          params: {
            ...defaultParams,
            ...filterParams,
            page: pageIndex,
            ordering: ordering
          }
        }
      );
      setNextUrl(response.data.next);
      setPreviousUrl(response.data.previous);
      setTotalPages(response.data.total_pages);
      setTotalRecords(response.data.count);
      setCurrentPage(response.data.current_page);
      setData(response.data.results);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 404) {
        setSmallModalTitle('Título del Modal');
        setSmallModalBody('Contenido del Modal');
        setSmallShowModal(true);
        console.log("Página no encontrada");
      } else {
        console.error('Error fetching data:', error);
      };
    } finally {
      setLoading(false);
    }
  };

  const handleCloneEmail = async (slug) => {
    setTextLoading('Clonando...');
    setLoading(true);
    try {
      const response = await axiosInstance.post(`${URL}${slug}/clonar/`);
      if (response.status === 201 || response.status === 200) {
        notifySuccess('Email clonado correctamente');
        fetchUrl(1);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        notifyError('Página no encontrada');
      } else {
        notifyError('Error al clonar email');
      };
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteEmail = async (slug) => {

    swal({
      title: '¿Estas seguro de borrar este email?',
      text: 'No podras recuperar este email',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        setTextLoading('Eliminando...');
        setLoading(true);
        try {
          const response = await axiosInstance.delete(`${URL}${slug}/`);
          if (response.status === 204) {
            notifySuccess('Email eliminado correctamente');
            fetchUrl(1);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
          if (error.response && error.response.status === 404) {
            notifyError('Página no encontrada');
          } else {
            notifyError('Error al eliminar email');
          };
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const handleSearchChange = (value) => {
    setLoading(true);
    debouncedfetchUrl(1, value);
  };

  const handleEnterPress = () => {
    // Lógica para manejar la tecla Enter, si es necesario
    console.log('Presionó Enter');
  };

  const handleClearClick = () => {
    handleSearchChange('');
  };

  const handleSortingChange = (column) => {
    const { id } = column;

    const newOrder = sortField === id ? (order === 'asc' ? 'desc' : 'asc') : 'asc';

    setSortField(id);
    setOrder(newOrder);
    setLoading(true);
    fetchUrl(1, `${newOrder === 'desc' ? '-' : ''}${id}`);
  };

  const handleOpenModal = () => {
    setSmallShowModal(true);
  };

  const handleCloseModal = () => {
    setSmallShowModal(false);
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
    },
    useFilters,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page
  } = tableInstance;

  return (
    <>
      <div className='d-flex aling-items-center mb-4 flex-wrap'>
        <h4 className="fs-20 font-w600 me-auto">{titleCard}</h4>
        <LinkPrimary
          to={'/create_email_masivo/'}
        >
          <i className='fas fa-plus me-2'></i>
          Crear Correo
        </LinkPrimary>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="card-tools">
            <SearchTextWithDateRange
              onSearchChange={handleSearchChange}
              onEnterPress={handleEnterPress}
              onClearClick={handleClearClick}
              showDatePicker={true}
              placeholderText="Fecha..."
            />
          </div>
        </div>
        <div className="card-body">
          {loading ? (
            <p>{textLoading}</p>
          ) : data.length === 0 ? (
            <p>No hay datos disponibles.</p>
          ) : (
            <Tables
              columns={columns}
              data={data}
              loading={loading}
              totalPages={totalPages}
              currentPage={currentPage}
              fetchUrl={fetchUrl}
              handleSortingChange={handleSortingChange}
              nextUrl={nextUrl}
              previousUrl={previousUrl}
              sortField={sortField}
              order={order}
              page={page}
              totalRecords={totalRecords}
            />
          )}
        </div>
      </div>

      <div>
        {/* Resto del contenido del componente */}

        {smallShowModal && (
          <CitoPlusSmallModal
            title={smallModalTitle}
            body={smallModalBody}
            show_modal={smallShowModal}
            onHide={handleCloseModal}
          // onClose={() => setSmallShowModal(false)}
          />
        )}
      </div>

    </>
  );
};

export default EnvioMasivoCorreo;
