// VisitantesVigilantesContext.js

import React, { createContext, useContext, useState } from 'react';

const VigilanteReloadContext = createContext();

export const VigilanteReloadProvider = ({ children }) => {
  const [reloadData, setReloadData] = useState({
    visitantes: false,
    correspondencia: false,
    minuta: false,
    formulario3: false,
    formulario4: false,
    formulario5: false,
  });

  return (
    <VigilanteReloadContext.Provider value={{ reloadData, setReloadData }}>
      {children}
    </VigilanteReloadContext.Provider>
  );
};

export const useVigilanteReloadContext = () => useContext(VigilanteReloadContext);
