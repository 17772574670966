import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../../../../services/AxiosInstance';
import { notifyError } from '../components/CitoPlusToastr';
import { handleErrors } from '../components/ErrorHandlingComponent';
import _ from 'lodash';
import { LinkPrimary } from '../components/CitoPlusLink';
import Comentarios from '../components/sidebar_comments';
import { useVigilanteReloadContext } from "../../../../context/VisitantesVigilantesContext";
import noImagen from '../../../../images/no-image.jpg';

import { formatDate } from '../utils';
import DatePickerRanger from '../components/DatePickerRanger';

const Minutas = () => {
  const URL = "/api/v3/vigilancia/minuta/";
  const URL_NOTAS = '/api/v3/vigilancia/minuta-comentarios/';
  const [loading, setLoading] = useState(false);
  const { reloadData, setReloadData } = useVigilanteReloadContext();
  const [minutaData, setMinutaData] = useState([]);
  const [page, setPage] = useState(1);
  const [filterText, setFilterText] = useState('');
  const [filterDate, setFilterDate] = useState({
    date_after: '',
    date_before: ''
  });
  const [showSidebarComments, setShowSidebarComments] = useState(false);
  const [urlComentarios, setUrlComentarios] = useState(URL_NOTAS);
  const [urlComentariosParams, setUrlComentariosParams] = useState({});
  const [selectedPrePostData, setSelectedPrePostData] = useState({});

  // Dates and filters
  const [dateRangeStart, setDateRangeStart] = useState([null, null]);
  const [startDateStart, endDateStart] = dateRangeStart;

  const [dateRangeEnd, setDateRangeEnd] = useState([null, null]);
  const [startDateEnd, endDateEnd] = dateRangeEnd;

  useEffect(() => {
    fetchMinutas();
  }, []);

  useEffect(() => {
    if (reloadData.minuta) {
      fetchMinutas();
      setReloadData(prev => ({ ...prev, minuta: false }));
    }
  }, [reloadData.minuta]);

  const fetchMinutas = async (loadMore = false) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(URL, {
        params: {
          page: loadMore ? page + 1 : 1,
          search: filterText,
          ...filterDate
        }
      });
      setMinutaData(prevData => loadMore ? [...prevData, ...response.data.results] : response.data.results);
      if (loadMore) setPage(page + 1);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    fetchMinutas();
  };

  const handleLoadMore = () => {
    fetchMinutas(true);
  };

  const handleComentariosClick = (value, slug) => {
    setSelectedPrePostData({
      minuta: slug,
      nota: '',
      foto: null
    });
    setUrlComentariosParams({
      minuta_uuid: slug,
      page_size: 100
    });
    setShowSidebarComments(true);
  };

  const handleNewComment = () => {
    fetchMinutas();
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600 me-auto">Minuta</h4>
      </div>
      <div className="row mb-4">
        <div className="col-xl-12">
          <form className="row" onSubmit={handleSearch}>
            <div className="col-md-4">
              <input
                type="text"
                className="form-control"
                placeholder="Buscar por texto"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
              />
            </div>
            <div className="col-md-4">
              <DatePickerRanger
                startDate={startDateStart}
                endDate={endDateStart}
                onChange={(update) => {
                  setDateRangeStart(update);
                  setFilterDate({
                    date_after: update[0] ? formatDate(update[0]) : '',
                    date_before: update[1] ? formatDate(update[1]) : '',
                  });
                }}
                withPortal
              />
            </div>
            <div className="col-md-4">
              <button type="submit" className="btn btn-primary w-100">Buscar</button>
            </div>
          </form>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            {minutaData.map((item, index) => (
              <div className="col-xl-6" key={index}>
                <div className="card">
                  <div className="card-body">
                    <h4 className="fs-20">{_.startCase(_.toLower(item.asunto))}</h4>
                    <div className="listline-wrapper mb-4">
                      <span className="item">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" version="1.1" className="svg-main-icon">
                          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <rect x="0" y="0" width="24" height="24" />
                            <path d="M13.5,21 L13.5,18 C13.5,17.4477153 13.0522847,17 12.5,17 L11.5,17 C10.9477153,17 10.5,17.4477153 10.5,18 L10.5,21 L5,21 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,21 L13.5,21 Z M9,4 C8.44771525,4 8,4.44771525 8,5 L8,6 C8,6.55228475 8.44771525,7 9,7 L10,7 C10.5522847,7 11,6.55228475 11,6 L11,5 C11,4.44771525 10.5522847,4 10,4 L9,4 Z M14,4 C13.4477153,4 13,4.44771525 13,5 L13,6 C13,6.55228475 13.4477153,7 14,7 L15,7 C15.5522847,7 16,6.55228475 16,6 L16,5 C16,4.44771525 15.5522847,4 15,4 L14,4 Z M9,8 C8.44771525,8 8,8.44771525 8,9 L8,10 C8,10.5522847 8.44771525,11 9,11 L10,11 C10.5522847,11 11,10.5522847 11,10 L11,9 C11,8.44771525 10.5522847,8 10,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 L8,14 C8,14.5522847 8.44771525,15 9,15 L10,15 C10.5522847,15 11,14.5522847 11,14 L11,13 C11,12.4477153 10.5522847,12 10,12 L9,12 Z M14,12 C13.4477153,12 13,12.4477153 13,13 L13,14 C13,14.5522847 13.4477153,15 14,15 L15,15 C15.5522847,15 16,14.5522847 16,14 L16,13 C16,12.4477153 15.5522847,12 15,12 L14,12 Z" fill="#000000" />
                            <rect fill="#FFFFFF" x="13" y="8" width="3" height="3" rx="1" />
                            <path d="M4,21 L20,21 C20.5522847,21 21,21.4477153 21,22 L21,22.4 C21,22.7313708 20.7313708,23 20.4,23 L3.6,23 C3.26862915,23 3,22.7313708 3,22.4 L3,22 C3,21.4477153 3.44771525,21 4,21 Z" fill="#000000" opacity="0.3" />
                          </g>
                        </svg>
                        {_.startCase(_.toLower(item.vigilante_nombre))}
                      </span>
                    </div>
                    <p className="user d-sm-flex d-block pe-md-5 pe-0">
                      {item.foto ? (
                        <a href={item.foto} data-lightbox="image-1">
                          <img
                            src={item.foto}
                            alt="firma"
                            style={{ objectFit: 'cover' }}
                            onError={e => {
                              e.target.onerror = null;
                              e.target.src = noImagen;
                            }}
                          />
                        </a>
                      ) : (
                        <></>
                      )}
                      <div className='ms-sm-3 ms-0 me-md-5 md-0'>
                        {_.startCase(item.nota)}
                      </div>
                    </p>
                  </div>
                  <div
                    className="card-footer d-flex justify-content-between align-items-center flex-wrap"
                    style={{ backgroundColor: _.replace(item.prioridad, /-/g, '') }}
                  >
                    <div className="mb-2">
                      <span><i className="fas fa-edit me-2"></i>Creado</span>
                      <p className="mb-0 text-black font-w500 mt-1">{item.created_at}</p>
                    </div>
                    <div className="mb-2">
                      <LinkPrimary
                        href="#"
                        className="position-relative"
                        onClick={() => {
                          handleComentariosClick(item.comentarios, item.slug);
                        }}
                      >
                        <i className="fas fa-comments me-2"></i>
                        Comentarios ( {item.total_comentarios} )
                      </LinkPrimary>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {loading && <p>Cargando...</p>}
          {!loading && (
            <button className="btn load-more-btn w-100 mt-3" onClick={handleLoadMore}>
              <i className="fas fa-arrow-down me-2"></i> Cargar más...
            </button>
          )}
        </div>
      </div>
      <Comentarios
        showSidebarComments={showSidebarComments}
        setShowSidebarComments={setShowSidebarComments}
        urlComentarios={urlComentarios}
        urlComentariosParams={urlComentariosParams}
        urlPost={urlComentarios}
        selectedPrePostData={selectedPrePostData}
        handleNewComment={handleNewComment}
      />
    </>
  );
};

export default Minutas;
