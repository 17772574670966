import React, { useState, useEffect } from "react";
import PageTitle from "../../../layouts/PageTitle";
import { Formik, ErrorMessage, Field, FieldArray } from "formik";
import Loading from "../components/loading";
import * as Yup from "yup";
import CustomClearIndicator from "../components/MultiSelect";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../../services/AxiosInstance";
import swal from "sweetalert";
import DatePicker from 'react-datepicker'; // Asegúrate de importar correctamente
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns'
import { LinkDanger, LinkPrimary } from "../components/CitoPlusLink";
import { BotonPrimaryText } from "../components/BotonesCustom";
import { handleErrors } from "../components/ErrorHandlingComponent";


const formSMSschema = Yup.object().shape({
  mensaje: Yup.string()
    .required("Por favor, ingresa un mensaje.")
    .nullable(true)
    .max(152, "El mensaje debe tener más de 152 caracteres."),
  viviendas: Yup.array()
    .test(
      'validacion-todos-grupos-viviendas-numeros',
      'Debe seleccionar al menos un grupo, una vivienda, digitar números o seleccionar todos',
      function (item) {
        const { todos, grupos, viviendas, numeros } = this.parent;
        if (todos === false && grupos.length === 0 && viviendas.length === 0 && numeros === undefined) {
          return false;
        } else {
          return true;
        }
      }
    ),
  todos: Yup.bool()
    .test(
      'validacion-todos-grupos-viviendas-numeros',
      'Debe seleccionar al menos un grupo, una vivienda, digitar números o seleccionar todos',
      function(item) {
        const { todos, grupos, viviendas, numeros } = this.parent;
        if (todos === false && grupos.length === 0 && viviendas.length === 0 && numeros === undefined) {
          return false;
        } else {
          return true;
        }
      }
    ),
  grupos: Yup.array()
    .test(
      'validacion-todos-grupos-viviendas-numeros',
      'Debe seleccionar al menos un grupo, una vivienda, digitar números o seleccionar todos',
      function (item) {
        const { todos, grupos, viviendas, numeros } = this.parent;
        if (todos === false && grupos.length === 0 && viviendas.length === 0 && numeros === undefined) {
          return false;
        } else {
          return true;
        }
      }
    ),
  numeros: Yup.string()
    .test(
      'validacion-todos-grupos-viviendas-numeros',
      'Debe seleccionar al menos un grupo, una vivienda, digitar números o seleccionar todos',
      function (item) {
        const { todos, grupos, viviendas, numeros } = this.parent;
        if (todos === false && grupos.length === 0 && viviendas.length === 0 && numeros === undefined) {
          return false;
        } else {
          return true;
        }
      }
    )
    .test(
      'validacion-numero',
      'El número debe tener 12 dígitos y comenzar con 57',
      value => {
        if (value) {
          const numerosArray = value.split(',').map(numero => numero.trim());
          return numerosArray.every(numero => /^\d{12}$/.test(numero) && numero.startsWith('57'));
        } else {
          return true;
        }
      }
    )
});

export const FormSMS = (props) => {

  const initialValuesEmpty = {
    nombre: "no-nombre",
    todos: false,
    mensaje: "",
    programacion_envio: Date.now(),
    enviar_primer_numero_registrado: true,
    viviendas: [],
    numeros: "",
    grupos: [],
  }

  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(true);
  const [gruposOptions, setGruposOptions] = useState([]);
  const [viviendasOptions, setViviendasOptions] = useState([]);

  // Contador de Caracteres
  const [mensaje, setMensajeen] = useState('');
  const [charCount, setCharCount] = useState(152);
  const maxLenth = 152;

  const URL = '/api/v3/mensajeria/mensajeria_sms/';
  const URL_GRUPOS = '/api/v3/usuarios/grupos_copropiedades/dropdown/';
  const URL_VIVIENDAS = '/api/v3/usuarios/citofonos/dropdown/';

  const slug = useParams();

  const navigate = useNavigate();

  const formatProgramacionEnvio = (date) => {

    const parseDate = new Date(date);
    let formattedDate;

    if (isNaN(parseDate.getTime())) {
      formattedDate = format(date, "yyyy-MM-dd HH:mm:ss");
    } else {
      formattedDate = format(parseDate, "yyyy-MM-dd HH:mm:ss");
    }
    return formattedDate;
  };

  useEffect(() => {
    const fetchGrupOptions = async () => {
      try {
        const response = await axiosInstance.get(URL_GRUPOS);
        const data = await response.data;
        setGruposOptions(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        //setLoading(false);
      }
    }

    fetchGrupOptions();

  }, []);

  useEffect(() => {
    const fetchViviendaOptions = async () => {
      try {
        const response = await axiosInstance.get(URL_VIVIENDAS);
        const data = await response.data;
        setViviendasOptions(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        //setLoading(false);
      }
    }

    fetchViviendaOptions();
  }, [])

  useEffect(() => {
    if (slug && slug.slug != "" && slug.slug != undefined) {

    } else {
      setLoading(false);
      setLoadingBtn(false);
    }
  }, [slug]);


  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setLoadingBtn(true);
    try {
      // cambiar el formato de la fecha de la variable programacion_envio
      const postData = {
        ...values,
        programacion_envio: formatProgramacionEnvio(values.programacion_envio),
        grupos_slugs: values.grupos,
      }
      const response = await axiosInstance.post(URL, postData);
      const data = await response.data;
      resetForm(initialValuesEmpty);
      setLoadingBtn(false);
      navigate('/envio_masivo_sms');
    } catch (error) {
      handleErrors(error);
    } finally {
      setSubmitting(false);
      setLoadingBtn(true);
    }
  }

  if (loading) {
    return (
      <>
        <PageTitle motherMenu="Mensajeria" activeMenu="Envio SMS" />
        <div className="d-flex aling-items-center mb-4 flex-wrap">
          <h4 className="f-20 font-weight-600 me-auto">Envio de SMS</h4>
        </div>
        <div className="card">
          <div className="card-body">
            <Loading />
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="d-flex aling-items-center mb-4 flex-wrap">
        <h4 className="f-20 font-weight-600 me-auto">Envio de SMS</h4>
      </div>
      <div className="card">
        <div className="card-body">
          <Formik
            initialValues={initialValuesEmpty}
            validationSchema={formSMSschema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,

            }) => (
              <form onSubmit={handleSubmit}>

                {/*
                  Fecha de programacion de envio se oculta por que lo pidieron,
                  2024-07-29
                */}
                <div className="form-group mb-3 row" hidden>
                  <div className="col-lg-4 col-form-label" htmlFor="programacion_envio">
                    <label htmlFor="programacion_envio">Programación de envío</label>
                  </div>
                  <div className="col-lg-7">
                    <DatePicker
                      id="progarmacion_envio"
                      name="progarmacion_envio"
                      selected={values.programacion_envio ? new Date(values.programacion_envio) : null}
                      onChange={(date) => setFieldValue('programacion_envio', date)}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      timeCaption="Hora"
                      className="form-control"
                      dateFormat="yyyy-MM-dd HH:mm:ss"
                    />
                    <div
                      id="val-programacion_envio-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.programacion_envio}
                    </div>
                  </div>
                </div>

                <div className="form-group mb-3 row">
                  <div className="col-lg-4 col-form-label" htmlFor="">
                    <label className="form-label">Mensaje</label>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <Field
                        id="mensaje"
                        name="mensaje"
                        type="text"
                        className="form-control"
                        onChange={(e) => {
                          setFieldValue("mensaje", e.target.value);
                          setCharCount(maxLenth - e.target.value.length);
                        }}
                        maxlenth={maxLenth}
                      />
                      <small id="emailHelp" className="form-text text-muted">
                        <span className="text-danger">{charCount}/{maxLenth}</span>
                        <p>En los sms no se permiten caracteres especiales como ñ á é í ó ú</p>
                      </small>
                      <div
                        id="val-mensaje-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.mensaje}
                      </div>
                    </div>
                  </div>
                </div>


                <fieldset className="fieldset border border-1 rounded p-4">

                  <legend className="text-uppercase">Destinatario</legend>

                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="">
                      <label className="form-label">Todos los Propietarios</label>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <Field
                          id="todos"
                          name="todos"
                          type="checkbox"
                          className="form-check-input"
                          checked={values.todos || false}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.todos}
                        />
                        <div
                          id="val-todos-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.todos}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="">
                      <label className="form-label">Enviar a solo el primer número de cada inmueble.</label>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <Field
                          id="enviar_primer_numero_registrado"
                          name="enviar_primer_numero_registrado"
                          type="checkbox"
                          className="form-check-input"
                          checked={values.enviar_primer_numero_registrado || false}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.enviar_primer_numero_registrado}
                        />
                        <div
                          id="val-enviar_primer_numero_registrado-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.enviar_primer_numero_registrado}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="">
                      <label className="form-label">Inmuebles</label>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <CustomClearIndicator
                          id="viviendas"
                          name="viviendas"
                          options={viviendasOptions}
                          isMulti={true}
                          onChange={(selectOptions) => {
                            setFieldValue("viviendas", selectOptions.map((option) => {
                              let { value, label } = option;
                              return {
                                slug: value,
                                nombre: label
                              }
                            }));
                          }}
                          defaultsValues={values.viviendas}
                        />
                        <div
                          id="val-viviendas-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.viviendas}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="">
                      <label className="form-label">Grupos</label>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <CustomClearIndicator
                          id="grupos"
                          name="grupos"
                          options={gruposOptions}
                          isMulti={true}
                          onChange={(selectOptions) => {
                            setFieldValue("grupos", selectOptions.map((option) => {
                              let { value, label } = option;
                              return {
                                slug: value,
                                nombre: label
                              }
                            }));
                          }}
                          defaultsValues={values.grupos}
                        />
                        <div
                          id="val-grupos-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.grupos}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="">
                      <label className="form-label">Enviar a números individuales</label>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <Field
                          id="numeros"
                          name="numeros"
                          type="text"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.numeros}
                        />
                        <small id="emailHelp" className="form-text text-muted">
                          Números separados por comas
                        </small>
                        <div
                          id="val-numeros-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.numeros}
                        </div>
                      </div>
                    </div>
                  </div>

                </fieldset>


                {loadingBtn ? (
                  <Loading />
                ) : (
                  <div className="d-flex mt-3 modal-footer">
                    <LinkDanger to='/envio_masivo_email' className="btn btn-danger">
                      <i className="fa fa-times-circle me-2"></i>
                      Cancelar
                    </LinkDanger>
                    <BotonPrimaryText
                      type="submit"
                      disabled={isSubmitting}>
                        <i className="fa fa-save me-2"></i>
                      Enviar
                    </BotonPrimaryText>
                  </div>
                )}

              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )

}

export default FormSMS;
