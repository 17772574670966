import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../../../../services/AxiosInstance";
import swal from "sweetalert";
import Loading from "../../components/loading";
import { BotonPrimaryText, BotonDangerText } from "../../components/BotonesCustom";
import { FieldBasic, MultiSelectSelectVivienda } from "../../components/FieldBasic";
import { toast, ToastContainer } from "react-toastify";


const formGruposSChema = Yup.object().shape({
  nombre: Yup.string()
    .nullable(true)
    .required("Por favor, ingresa el nombre del grupo."),
  descripcion: Yup.string()
    .required("Por favor, ingresa una descripción.")
    .nullable(true),

})

const AddEditGruposModal = ({ showModal, onClose, updateGruposList, editMode, selectedGrupoCopropiedadId }) => {

  const initialValuesEmpty = {
    nombre: null,
    descripcion: null,
    visible_porteria: false,
    estado: true,
    viviendas: [],
  }

  const URL = "/api/v3/usuarios/grupos_copropiedades/";

  const [loadingInitialValues, setLoadingInitialValues] = useState(true);
  const [initialValues, setInitialValues] = useState(initialValuesEmpty);

  useEffect(() => {
    const loadInitialValues = async () => {
      setLoadingInitialValues(true);
      try {
        if (editMode && selectedGrupoCopropiedadId) {
          const data = await fetchDetailsGrupos();
          const initialValues = {
            nombre: data.nombre,
            descripcion: data.desc,
            visible_porteria: data.visible_porteria,
            estado: data.estado,
            viviendas: data.viviendas
          };
          setInitialValues(initialValues);
        } else {
          const initialValues = initialValuesEmpty;
          setInitialValues(initialValues);
        }
      } catch (error) {
        console.error("Error fetching initial values:", error);
      } finally {
        setLoadingInitialValues(false);
      }
    };
    loadInitialValues();

  }, [editMode, selectedGrupoCopropiedadId]);

  const fetchDetailsGrupos = async () => {
    try {
      const response = await axiosInstance.get(`${URL}${selectedGrupoCopropiedadId}/`);
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleSubmit = async (values, { setSubmitting, resetForm, setFieldError }) => {
    const postData = {
      nombre: values.nombre,
      desc: values.descripcion,
      visible_porteria: values.visible_porteria,
      estado: values.estado,
      viviendas: values.viviendas
    }
    try {
      let response = null;
      if (editMode && selectedGrupoCopropiedadId) {
        response = await axiosInstance.put(`${URL}${selectedGrupoCopropiedadId}/`, postData);
      } else {
        response = await axiosInstance.post(URL, postData);
      }
      const data = await response.data;
      updateGruposList();
      setInitialValues(initialValuesEmpty);
      onClose();
      const textSwal = editMode ? 'Grupo Editado' : 'Grupo Creado'
      swal(textSwal, values.interior, "success");
    } catch (error) {
      if (error.response && error.response.data) {
        const error_type = error.response.data.type
        const errors = error.response.data.errors;
        errors.forEach(({ attr, detail }) => {
          console.log(attr, detail);
          setFieldError(attr, detail);
          toast.error("❌ " + attr + ": " + detail, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
      }
    } finally {
      setSubmitting(false);
    }
  }

  const handleCloseModal = () => {
    onClose();
    setLoadingInitialValues(false);
    setInitialValues({ initialValuesEmpty });
  }

  return (
    // add modal large
    <Modal show={showModal} onHide={onClose} size="lg">
      {loadingInitialValues ? (
        <Loading />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={formGruposSChema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue
          }) => (
            <div role='document'>
              <form onSubmit={handleSubmit}>
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    {editMode ? "Editar Grupo" : "Crear Grupo"}
                  </h5>
                </div>
                <div className="modal-body">


                  <div className="form-group mb-3 row">

                    <div className="form-group mb-3 row">
                      <div className='col-lg-4 col-form-label' htmlFor="interior">
                        Grupo
                        <span className="text-danger">*</span>
                      </div>
                      <div className="col-lg-7">
                        <input
                          type="text"
                          className="form-control"
                          id="nombre"
                          name="nombre"
                          value={values.nombre || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div
                          id="val-username1-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.nombre}
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb-3 row">
                      <div className='col-lg-4 col-form-label' htmlFor="interior">
                        Descripción
                        <span className="text-danger">*</span>
                      </div>
                      <div className="col-lg-7">
                        <input
                          type="text"
                          className="form-control"
                          id="descripcion"
                          name="descripcion"
                          value={values.descripcion || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div
                          id="val-username1-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.descripcion}
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb-3 row">
                      <div className='col-lg-4 col-form-label' htmlFor="interior">
                        Visible en Portería
                      </div>
                      <div className="col-lg-7">
                        <input
                          type="checkbox"
                          checked={values.visible_porteria || false}
                          className="form-check-input"
                          id="visible_porteria"
                          name="visible_porteria"
                          value={values.visible_porteria || false}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div
                          id="val-username1-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.visible_porteria}
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb-3 row">
                      <div className='col-lg-4 col-form-label' htmlFor="interior">
                        Estado
                      </div>
                      <div className="col-lg-7">
                        <input
                          type="checkbox"
                          checked={values.estado || false}
                          className="form-check-input"
                          id="estado"
                          name="estado"
                          value={values.estado || false}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div
                          id="val-username1-error"
                          className="invalid-feedback animated fadeInUp"
                          style={{ display: "block" }}
                        >
                          {errors.estado}

                        </div>
                      </div>
                    </div>
                    <MultiSelectSelectVivienda
                      id="viviendas"
                      name="viviendas"
                      onChange={(selectedOptions) => {
                        setFieldValue('viviendas', selectedOptions.map(option => {
                          let { value, label } = option;
                          return {
                            slug: value,
                            label: label
                          }
                        }));
                      }}
                      defaultsValues={values.viviendas}
                      isMulti={true}
                      errors={errors.viviendas}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <BotonDangerText
                    type="button"
                    onClick={onClose}
                  >
                    Cancelar
                  </BotonDangerText>
                  <BotonPrimaryText
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Guardar
                  </BotonPrimaryText>
                </div>

              </form>
            </div>
          )}
        </Formik>
      )}

    </Modal>
  );
};

export default AddEditGruposModal;
