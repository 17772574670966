import React, { useContext } from "react";

/// React router dom
import { Routes, Route, Outlet } from 'react-router-dom'

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";
import "../css/style_citoplus.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from './pages/ScrollToTop';

// Dashboard
import Home from "./components/CitoPlus/vigilantes/Dashboard/Home";
import VigilanteDashboard from "./components/Dashboard/VigilantesDashboard";

// Visitantes
import VisitantesPeatonales from "./components/CitoPlus/vigilantes/VisitantesPeatonales";
import VisitantesVehiculares from "./components/CitoPlus/vigilantes/VisitantesVehiculares";

// Minutas
import Minutas from "./components/CitoPlus/vigilantes/Minutas";

// Autorizaciones
import Autorizaciones from "./components/CitoPlus/vigilantes/Autorizaciones";

// Correspondencia
import Correspondencia from "./components/CitoPlus/vigilantes/Correspondencia";

// Cajas
import Cajas from "./components/CitoPlus/vigilantes/Cajas";

import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import SidbarForms from "./components/CitoPlus/vigilantes/FormVigilante/SidebarForms";
import { ThemeContext } from "../context/ThemeContext";

const RouterVigilantes = () => {
  // const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  const allroutes = [
    /// allroutes
    { url: "", component: <VigilanteDashboard /> },
    { url: "dashboard", component: <VigilanteDashboard /> },
    { url: "visitantes-peatonales", component: <VisitantesPeatonales /> },
    { url: "visitantes-vehiculares", component: <VisitantesVehiculares /> },
    { url: "correspondencia", component: <Correspondencia /> },
    { url: "minuta", component: <Minutas /> },
    { url: "autorizaciones", component: <Autorizaciones /> },
    { url: "cajas", component: <Cajas /> },
  ];
  //let path = window.location.pathname;2
  // path = path.split("/");
  // path = path[path.length - 1];

  // let pagePath = path.split("-").includes("page");
  return (
    <>
      <Routes>
        <Route path='page-lock-screen' element={<LockScreen />} />
        <Route path='page-error-400' element={<Error400 />} />
        <Route path='page-error-403' element={<Error403 />} />
        <Route path='page-error-404' element={<Error404 />} />
        <Route path='page-error-500' element={<Error500 />} />
        <Route path='page-error-503' element={<Error503 />} />
        <Route element={<MainLayout />} >
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
              title={data.title}
            />
          ))}
        <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  return (
    <>
      <div id="main-wrapper" className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${menuToggle ? "menu-toggle" : ""}`}>
        <Nav />
        <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
          <div className="container-fluid">
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
      <SidbarForms />
    </>
  )
};

export default RouterVigilantes
