import React, { useState, useEffect } from "react";
import Loading from "../components/loading";
import { Link } from "react-router-dom";
import axiosInstance from "../../../../services/AxiosInstance";
import { useParams } from "react-router-dom";

import 'lightbox2/dist/css/lightbox.min.css';

import noImagen from '../../../../images/no-image.jpg';


export const VisitanteDetalle = () => {

  const URL = '/api/v3/parking/recaudo/';

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const { slug } = useParams();

  useEffect(() => {

    const fetchVisitanteDetalle = async () => {

      setLoading(true);

      try {
        const response = await axiosInstance.get(`${URL}${slug}/`);
        const data = await response.data;
        setData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchVisitanteDetalle();
  }, []);

  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header border-0 flex-wrap align-items-start">
              <div className="col-md-8">
                <div className="user d-sm-flex d-block pe-md-5 pe-0">
                  {data.foto === null ? (
                    <img
                      src={noImagen}
                      alt=""
                      className="me-3 rounded"
                      width={75}
                    />
                  ) : (
                    <a href={data.foto} data-lightbox="image">
                      <img
                        src={data.foto}
                        alt=""
                        className="me-3 rounded"
                        width={75}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = noImagen;
                        }}
                      />
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              <h4 className="fs-20">Descripción</h4>
              <div className="row">
                <div className="col-xl-6 col-md-6">
                  <p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Parqueadero : </span><span className="font-w400">{data.park}</span></p>
                  <p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Consecutivo : </span><span className="font-w400">{data.consecutivo}</span></p>
                  <p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Placa : </span><span className="font-w400">{data.vehiculo}</span></p>
                  <p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Vigilante : </span><span className="font-w400">{data.vigilante}</span></p>
                  <p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Vigilante Entrada : </span><span className="font-w400">{data.nombre_guarda_entrada}</span></p>
                  <p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Vigilante Salida :</span> <span className="font-w400">{data.nombre_guarda_salida}</span></p>
                  <p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Tiempo de Gracia :</span> <span className="font-w400">{data.total_gracia}</span></p>
                  <p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Total :</span> <span className="font-w400">{data.total}</span></p>
                  <p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Impuesto : </span><span className="font-w400">{data.impuesto}</span></p>
                  <p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Duración en parqueadero : </span><span className="font-w400">{data.duracion?.duracion} {data.duracion?.tipo}</span></p>
                  <p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Tarifa : </span><span className="font-w400">{data.park_bill_data?.tipo || ''}</span></p>
                </div>
                <div className="col-xl-6 col-md-6">
                  <p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Nombre Visitante : </span><span className="font-w400">{data.nombre} {data.apellidos}</span></p>
                  <p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Identificación : </span><span className="font-w400">{data.identificacion}</span></p>
                  <p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Unidad que visita : </span><span className="font-w400">{data.unidad_visita}</span></p>
                  <p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Autorizado : </span><span className="font-w400">{data.autorizado}</span></p>
                  <p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Ingreso :</span> <span className="font-w400">{data.h_ingreso}</span></p>
                  <p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Salida : </span><span className="font-w400">{data.h_salida}</span></p>
                </div>
              </div>
            </div>
            <div className="card-footer d-flex flex-wrap justify-content-between">
              <div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VisitanteDetalle;
