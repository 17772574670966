import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Field, Formik } from "formik";
import * as Yup from 'yup';
import axiosInstance from "../../../../services/AxiosInstance";
import Swal from "sweetalert2";
import { notifySuccess, notifyError } from "../components/CitoPlusToastr";
import Loading from "../components/loading";
import { FieldBasic } from "../components/FieldBasic";
import { BotonInfoText, BotonDangerText, BotonPrimary, BotonPrimaryText } from "../components/BotonesCustom";
import { handleErrors } from "../components/ErrorHandlingComponent";
import InputFileBase64 from "../components/InputFileBase64";

const VigilantesFormModal = (props) => {

  const URL = '/api/v3/vigilancia/vigilantes/';

  const initialValuesEmpty = {
    slug: null,
    user: {
      first_name: null,
      last_name: null,
      username: null,
      password: null,
      password1: null
    },
    activo: true,
    es_guarda: true,
    es_supervisor: false,
    identificacion: null,
    direccion: null,
    celular: null,
    foto: null,
    cc: null
  }

  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [initialValues, setInitialValues] = useState(initialValuesEmpty);
  const [changeFoto, setChangeFoto] = useState(false);

  const passwordValidation = (editMode, slug) => {
    if (!editMode && slug === null) {
      return Yup.string()
        .required("Por favor, ingresa una contraseña.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          'Su contraseña no cumple con los requisitos de seguridad.'
        )
        .test('notSimilar', 'Su contraseña no puede asemejarse tanto a su otra información personal.', function (value) {
          // Lógica de validación personalizada
          return true;
        })
        .test('notCommon', 'Su contraseña no puede ser una clave utilizada comúnmente.', function (value) {
          // Lógica de validación personalizada
          return true;
        })
        .test('notNumeric', 'Su contraseña no puede ser completamente numérica.', function (value) {
          // Lógica de validación personalizada
          return true;
        })
        .nullable(true);
    } else {
      return Yup.string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          'Su contraseña no cumple con los requisitos de seguridad.'
        )
        .test('notSimilar', 'Su contraseña no puede asemejarse tanto a su otra información personal.', function (value) {
          // Lógica de validación personalizada
          return true;
        })
        .test('notCommon', 'Su contraseña no puede ser una clave utilizada comúnmente.', function (value) {
          // Lógica de validación personalizada
          return true;
        })
        .test('notNumeric', 'Su contraseña no puede ser completamente numérica.', function (value) {
          // Lógica de validación personalizada
          return true;
        })
        .nullable(true);
    }
  };

  const password1Validation = (editMode, slug) => {
    if (!editMode && slug === null) {
      return Yup.string()
        .required("Por favor, ingresa una contraseña.")
        .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir.')
        .nullable(true);
    } else {
      return Yup.string()
        .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir.')
        .nullable(true);
    }
  };

  const formVigilantesSchema = Yup.object().shape({
    celular: Yup.string()
      .min(10, "El celular debe tener 10 dígitos.")
      .max(12, "El celular debe tener 10 dígitos.")
      .matches(/^[0-9]+$/, 'El celular debe ser numérico.')
      .nullable(true),
    user: Yup.object().shape({
      first_name: Yup.string()
        .required("Por favor, ingresa un nombre.")
        .nullable(true),
      last_name: Yup.string()
        .required("Por favor, ingresa un apellido.")
        .nullable(true),
      username: Yup.string()
        .when('editMode', {
          is: false,
          then: Yup.string()
            .required("Por favor, ingresa un nombre de usuario.")
            .nullable(true),
          otherwise: Yup.string()
            .nullable(true),
        })
        .required("Por favor, ingresa un nombre de usuario.")
        .nullable(true),
      password: passwordValidation(props.editMode, initialValues.slug),
      password1: password1Validation(props.editMode, initialValues.slug)
    })
  });

  // Loading User editMode true
  useEffect(() => {
    if (props.editMode) {
      setLoading(true);
      const fetchUser = async () => {
        try {
          const response = await axiosInstance.get(`${URL}${props.selectedVigilanteUUID}/`);
          const data = await response.data;
          setInitialValues({
            slug: data.slug,
            user: {
              first_name: data.user.first_name,
              last_name: data.user.last_name,
              username: data.user.username,
              password: null,
              password1: null
            },
            activo: data.activo,
            es_guarda: data.es_guarda,
            es_supervisor: data.es_supervisor,
            identificacion: data.identificacion,
            direccion: data.direccion,
            celular: data.celular,
            foto: data.foto,
            identificacion: data.cc
          });
        } catch (error) {
          handleErrors(error);
        } finally {
          setLoading(false);
        }
      }
      fetchUser();
    }

  }, [props.editMode, props.selectedVigilanteUUID]);
  // Fin loadin User editMode


  const handleSubmit = async (values, { setSubmitting, resetForm, setErrors }) => {
    try {
      let postData = {
        ...values,
        cc: values.identificacion,
      }

      if (changeFoto) {
        postData.foto = values.foto
      } else {
        delete postData.foto
      }

      console.log(postData);

      if (props.editMode && !values.user.password) {
        delete postData.user.password;
        delete postData.user.password1;
      }

      if (props.editMode) {
        delete postData.user.username
      }

      if (props.editMode) {
        const finalUrl = `${URL}${props.selectedVigilanteUUID}/`;
        const response = await axiosInstance.patch(finalUrl, postData);
        const data = await response.data;
        resetForm(initialValuesEmpty);
        setInitialValues(initialValuesEmpty);
        props.updateList();
        props.onHide();
        notifySuccess("Vigilante editado");
      } else {
        const response = await axiosInstance.post(URL, postData);
        const data = await response.data;
        resetForm(initialValuesEmpty);
        setInitialValues(initialValuesEmpty);
        props.updateList();
        props.onHide();
        notifySuccess("Vigilante agregado");
      }
    } catch (error) {
      handleErrors(error, setErrors);
    } finally {
      setSubmitting(false);
      setLoadingBtn(false);
    }
  }

  const handleClose = (resetForm) => {
    props.onHide();
    setInitialValues(initialValuesEmpty);
    setChangeFoto(false);
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Modal show={props.showModal} centered>
        <Modal.Header closeButton onHide={props.onHide}>
          <Modal.Title>{props.editMode ? 'Editar Vigilante' : ' Nuevo Vigilante '} </Modal.Title>
        </Modal.Header>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={formVigilantesSchema}
          onSubmit={handleSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            isSubmitting,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Modal.Body>
                <FieldBasic
                  name="es_supervisor"
                  label="Es Supervisor"
                  errors={errors.es_supervisor}
                >
                  <Field
                    type="checkbox"
                    name="es_supervisor"
                    id="es_supervisor"
                    className="form-check-input"
                    checked={values.es_supervisor}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.es_supervisor}
                  />
                  <small className="text-muted">
                    <div>Supevisor tiene permiso de ver, crear, editar y borrar a otros vigilantes</div>
                  </small>
                </FieldBasic>

                <FieldBasic
                  label="Activo"
                  errors={errors.activo}
                >
                  <Field
                    type="checkbox"
                    name="activo"
                    id="activo"
                    className="form-check-input"
                    checked={values.activo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.activo}
                  />
                </FieldBasic>

                <FieldBasic
                  label="Nombres"
                  required
                  errors={errors.user && errors.user.first_name}
                >
                  <Field
                    type="text"
                    name="user.first_name"
                    id="user.first_name"
                    className="form-control"
                    placeholder="Nombres"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.user.first_name}
                  />
                </FieldBasic>

                <FieldBasic
                  label="Apellidos"
                  required
                  errors={errors.user && errors.user.last_name}
                >
                  <Field
                    type="text"
                    name="user.last_name"
                    id="user.last_name"
                    placeholder="Primer Apellido"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.user.last_name}
                  />
                </FieldBasic>

                <FieldBasic
                  name="Identificación"
                  label="Identificación"
                  errors={errors.identificacion}
                >
                  <Field
                    type="Identificación"
                    name="identificacion"
                    id="identificacion"
                    placeholder="Identificación"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.identificacion}
                  />
                </FieldBasic>

                <FieldBasic
                  name="Dirección"
                  label="Dirección"
                  errors={errors.direccion}
                >
                  <Field
                    type="text"
                    name="direccion"
                    id="direccion"
                    placeholder="Dirección"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.direccion}
                  />
                </FieldBasic>

                <FieldBasic
                  name="Celular"
                  label="Celular"
                  errors={errors.celular}
                >
                  <Field
                    type="text"
                    name="celular"
                    id="celular"
                    placeholder="Celular"
                    className="form-control"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.celular}
                  />
                </FieldBasic>

                <FieldBasic
                  label="Usuario vigilante"
                  required
                  errors={errors.user && errors.user.username}
                >
                  <Field
                    type="text"
                    name="user.username"
                    id="user.username"
                    placeholder="Usuario vigilante"
                    className="form-control"
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.user.username}
                    disabled={props.editMode}
                  />
                </FieldBasic>

                <FieldBasic
                  name="Password"
                  label="Contraseña vigilante"
                  required
                  errors={errors.user && errors.user.password}
                >
                  <Field
                    name="user.password"
                    id="user.password"
                    placeholder="Contraseña vigilante"
                    className="form-control"
                    type="password"
                    autoComplete="new-password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.user.password}
                  />
                  <small className="text-muted">
                    <div> Debe tener al menos 8 caracteres, una mayúscula, una minúscula, un número y un carácter especial. No debe ser la misma que el nombre de usuario.</div>
                    <div>No debe ser la misma que el nombre de usuario.</div>
                  </small>
                </FieldBasic>

                <FieldBasic
                  name="Password1"
                  label="Confirmar Contraseña"
                  required
                  errors={errors.user && errors.user.password1}
                >
                  <Field
                    name="user.password1"
                    id="user.password1"
                    placeholder="Confirmar Contraseña"
                    className="form-control"
                    type="password"
                    autoComplete="new-password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.user.password1}
                  />
                </FieldBasic>

                <InputFileBase64
                  label="Foto"
                  errors={errors.foto}
                  value={values.foto}
                  accept=".jpg, .png, .jpeg"
                  onChange={(base64String) => {
                    setChangeFoto(true);
                    setFieldValue('foto', base64String);
                  }}
                />

              </Modal.Body>
              <Modal.Footer>
                {loadingBtn == true ? (
                  <Loading size={20} color="white" />
                ) : (
                  <div>
                    <BotonDangerText type="button" onClick={handleClose}>
                      <i className="fas fa-times me-2"></i>
                      Cerrar
                    </BotonDangerText>
                    <BotonPrimaryText type="submit" disabled={isSubmitting}>
                      <i className="fas fa-save me-2"></i>
                      Guardar
                    </BotonPrimaryText>
                  </div>
                )}
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default VigilantesFormModal
