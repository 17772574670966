import { configureStore } from '@reduxjs/toolkit';
import { applyMiddleware, combineReducers, compose } from 'redux';
import PostsReducer from './reducers/PostsReducer';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
import profileReducer from './reducers/ProfileReducer';
const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    posts: PostsReducer,
    auth: AuthReducer,
    profile: profileReducer
});

//const store = createStore(rootReducers);

export const store = configureStore({reducer: reducers},  composeEnhancers(middleware));
